import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import UsersService from '../services/UsersService'
import EntitiesService from '../services/EntitiesService'

import { DEFAULT_ROLES } from '../data/roles'

import { AlertMsg, AlertError, AlertLoading } from 'helpers/AlertHelper'

function UsersForm(props) {

    const [elementId, setElementId] = useState(props.elementId)
    const [action, setAction] = useState(props.action)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [entityId, setEntityId] = useState('')
    const [entities, setEntities] = useState([])
    const [roles, setRoles] = useState([])
    const [buttonTitle, setButtonTitle] = useState('')
    const [inputDisabled, setInputDisabled] = useState(false)
    const [formValid, setFormValid] = useState(false)

    const [showMsg, setShowMsg] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)

    const getEntities = async () => {
        await EntitiesService.getEntities(1, '', 100).then(
            response => {
                setEntities(response.data.entities)
            },
            error => {
                setError(error)
            })
    }

    const getData = async () => {
        await UsersService.getUser(elementId).then(
            response => {
                setName(response.data.name || '')
                setPhone(response.data.phone || '')
                setEmail(response.data.email || '')
                setEntityId(response.data.entityId || '')
                setRoles(response.data.roles || [])
                setIsLoaded(true)
            },
            error => {
                setError(error)
            })
    }

    useEffect(() => {

        getEntities()

        if (elementId) {
            getData()
        }

        switch (action) {
            case 'edit':
                setButtonTitle(<FormattedMessage id="textSave" defaultMessage="Save" />)
                break
            case 'delete':
                setButtonTitle(<FormattedMessage id="textDelete" defaultMessage="Delete" />)
                setInputDisabled(true)
                break
            default:
                setButtonTitle(<FormattedMessage id="textAdd" defaultMessage="Add" />)
        }

    }, [])

    const onSubmit = () => {
        props.onSubmit()
    }

    const handleRolesChange = (e) => {
        const role = e.target.value
        const isChecked = e.target.checked

        if (isChecked) {
            setRoles(prevRoles => [...prevRoles, role])
        } else {
            setRoles(prevRoles => prevRoles.filter(prevRole => prevRole !== role))
        }
    }

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFieldValue(name, value)
    }

    const setFieldValue = (fieldName, value) => {
        switch (fieldName) {
            case 'name':
                setName(value)
                break
            case 'phone':
                setPhone(value)
                break
            case 'email':
                setEmail(value)
                break
            case 'password':
                setPassword(value)
                break
            case 'entityId':
                setEntityId(value)
                break
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget
        if (!form.checkValidity()) {
            setFormValid(true)
            return
        }

        const user = {
            name,
            phone,
            email,
            entityId,
        }

        const rolesObj = {
            roles,
        }

        try {
            if (elementId && action === "edit") {
                if (password.length !== 0) {
                    user.password = password
                }
                await UsersService.updateUser(user, elementId)
                await UsersService.updateUserRoles(rolesObj, elementId)
            } else if (action === "add") {
                user.password = password
                const addUserResponse = await UsersService.addUser(user)
                await UsersService.updateUserRoles(rolesObj, addUserResponse.data._id)
            } else if (action === "delete") {
                await UsersService.deleteUser(elementId)
            }

            setFormValid(false)
            onSubmit()
        } catch (error) {
            setShowMsg({ text: error || "An error occurred", style: 'danger' })
            setShowAlert(true)
        } finally {
            setFormValid(true)
        }
    }

    if (error) {
        return (
            <AlertError error={error} />
        )
    } else if (elementId && !isLoaded) {
        return (
            <AlertLoading isLoaded={isLoaded} />
        )
    }

    return (
        <div>
            <Form noValidate validated={formValid} onSubmit={handleSubmit}>
                {elementId && (
                    <Form.Group className="mb-3" controlId="id">
                        <Form.Label>Id:</Form.Label>
                        <Form.Control
                            type="text"
                            name="id"
                            value={elementId}
                            disabled={"disabled"}
                        />
                    </Form.Group>
                )}

                <Form.Group className="mb-3" controlId="name">
                    <Form.Label><FormattedMessage id="usersName" defaultMessage="Name" />:</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={name}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="usersNameInvalid" defaultMessage="Provide an user name" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="roles">
                    <Form.Label><FormattedMessage id="usersRoles" defaultMessage="Roles" />:</Form.Label>
                    {DEFAULT_ROLES.map(role =>
                        <Form.Check
                            key={role}
                            inline
                            label={role.slice(5)}
                            name={role}
                            id={"checkb_" + role}
                            value={role}
                            type="checkbox"
                            onChange={handleRolesChange}
                            checked={roles.indexOf(role) >= 0 ? true : false}
                            disabled={inputDisabled && ("disabled")}
                        />
                    )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="phone">
                    <Form.Label><FormattedMessage id="usersPhoneNumber" defaultMessage="Phone number" />:</Form.Label>
                    <Form.Control
                        type="text"
                        name="phone"
                        value={phone}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="usersPhoneNumberInvalid" defaultMessage="Provide a phone number" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="email">
                    <Form.Label><FormattedMessage id="usersEmail" defaultMessage="E-mail" />:</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                        pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="usersEmailInvalid" defaultMessage="Provide an Email address" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="entityId">
                    <Form.Label><FormattedMessage id="usersEntityName" defaultMessage="Entity name" />:</Form.Label>
                    <Form.Select
                        name="entityId"
                        value={entityId}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    >
                        <option value=""><FormattedMessage id="usersEntityNameSelect" defaultMessage="Select user's entity" /></option>
                        {entities.map(entity =>
                            <option value={entity._id} key={entity._id}>{entity.name}</option>
                        )}
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="usersEntityNameInvalid" defaultMessage="Select an entity" />
                    </Form.Control.Feedback>
                </Form.Group>

                {action === "add" && (
                    <Form.Group className="mb-3" controlId="password">
                        <Form.Label><FormattedMessage id="usersPassword" defaultMessage="Password" />:</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={password}
                            onChange={handleInputChange}
                            disabled={inputDisabled && ("disabled")}
                            required="required"
                        />
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="usersPasswordInvalid" defaultMessage="Provide an user password" />
                        </Form.Control.Feedback>
                    </Form.Group>
                )}

                {action === "edit" && (
                    <Form.Group className="mb-3" controlId="password">
                        <Form.Label><FormattedMessage id="usersPassword" defaultMessage="Passworde" />:</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={password}
                            onChange={handleInputChange}
                            disabled={inputDisabled && ("disabled")}
                        />
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="usersPasswordInvalid" defaultMessage="Provide an user password" />
                        </Form.Control.Feedback>
                    </Form.Group>
                )}

                <AlertMsg message={showMsg.text} variant={showMsg.style} dismissible onCloseHandler={() => setShowAlert(false)} show={showAlert} />

                <div className="d-grid gap-2">
                    {action === "add" && (
                        <Button
                            className="btn btn-dark"
                            type="submit"
                        >
                            <i className="bi bi-plus-circle"></i> {buttonTitle}
                        </Button>
                    )}

                    {action === "edit" && (
                        <Button
                            className="btn btn-primary"
                            type="submit"
                        >
                            <i className="bi bi-save2"></i> {buttonTitle}
                        </Button>
                    )}

                    {action === "delete" && (
                        <Button
                            className="btn btn-danger"
                            type="submit"
                        >
                            <i className="bi bi-trash"></i> {buttonTitle}
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    )

}

export default UsersForm