import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export const FormDataModal = ({ title, icon, BodyContent, groupId, elementId, action, callBack, trigger = true }) => {

    const [show, setShow] = useState(false)
    const handleClose = () => {
        setShow(false)
        callBack(true)
    }
    const handleShow = () => setShow(true)

    useEffect(() => {
        if (trigger) {
            handleShow()
        }
    }, [trigger])

    return (
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title><i className={"bi " + icon}></i> {title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <BodyContent callBack={callBack} groupId={groupId} elementId={elementId} actionType={action} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    <i className="bi bi-x-square"></i> <FormattedMessage id="textClose" defaultMessage="Close" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default FormDataModal