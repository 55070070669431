import axios from "axios"
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class AuthService {

  // Get GDPR (General Data Protection Regulation) config params
  getGdpr = async () => {
    return await axios.get(API_URL + '/gdpr')
  }

  login = async (username, password) => {
    return await axios
      .post(API_URL + "/auth/login", {
        email: username,
        password: password
      })
      
  }

  logout = async () => {
    const headers = authHeader()
    return await axios.delete(API_URL + '/auth/logout', { headers: headers })     
  }

  signup = async (newUser) => {
    return await axios.post(API_URL + "/auth/signup", newUser)
  }

  getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'))
  }

}

export default new AuthService()