import React, { useEffect, useRef, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import moment from 'moment'

import Spinner from "components/spinner/Spinner"

import ChatRoomForm from 'components/forms/ChatRoomForm'
import FormDataModal from 'components/modals/FormDataModal'
import MessagesCard from 'components/card/MessagesCard'

import ChatRoomServices from 'socket/ChatRoomServices.socket'


const ChatGroup = ({ groupId, authUserId, socket, socketData, handleToasts, handleChatMessagesNum, sendButtonDisabled, activeKey }) => {
    const intl = useIntl()

    const chatRoomServices = new ChatRoomServices({ authUserId: authUserId, groupId: groupId, socketService: socket })

    const messagesContainerRef = useRef(null)
    const sendButtonRef = useRef(null)

    const [newMessageContent, setNewMessageContent] = useState('')

    const [chatRooms, setChatRooms] = useState({
        list: null,
        allUsers: null,
        selected: {
            chatRoom: null,
            messages: null,
            unreaded: 0
        }
    })

    const chatRoomsRef = useRef(chatRooms)

    /* Chatrooms */
    useEffect(() => {
        if (!chatRoomServices) return
        chatRoomServices.getChatRooms()
    }, [groupId])

    useEffect(() => {
        if (!socketData) return

        const sData = socketData
        switch (sData.request?.event) {
            case 'joinChatRoom':
                // Do nothing
                break

            case 'addChatRoom':
                chatRoomServices.joinChatRoom({ chatRoomId: sData.data.chatRoom._id })
                chatRoomServices.getChatRooms()
                break

            case 'updateChatRoom':
                chatRoomServices.getChatRooms()
                setChatRooms({
                    ...chatRooms,
                    selected: {
                        chatRoom: null,
                        messages: null,
                        unreaded: 0
                    }
                })
                break

            case 'deleteChatRoom':
                chatRoomServices.getChatRooms()
                setChatRooms({
                    ...chatRooms,
                    selected: {
                        chatRoom: null,
                        messages: null,
                        unreaded: 0
                    }
                })
                break

            case 'getChatRooms':
                setChatRooms((prevChatRooms) => ({
                    ...prevChatRooms,
                    list: sData.data,
                    allUsers: getAllUsersFromGroups(sData.data),
                }))
                break

            case 'getChatRoomMessages':
                chatRoomServices.getChatRooms()
                setChatRooms((prevChatRooms) => ({
                    ...prevChatRooms,
                    selected: {
                        ...prevChatRooms.selected,
                        chatRoom: getChatRoomFromChatRoomsList(sData.request.chatRoomId),
                        messages: sData.data
                    }
                }))
                //console.log('Chatroom mensajes recibidos:', sData.data)
                break

            case 'addChatRoomMessage':
                chatRoomServices.getChatRooms()
                if (sData.request.chatRoomId === chatRooms.selected.chatRoom?._id && activeKey === 'chat') {
                    setChatRooms((prevChatRooms) => ({
                        ...prevChatRooms,
                        selected: {
                            ...prevChatRooms.selected,
                            messages: [...prevChatRooms.selected.messages, sData.data.message]
                        }
                    }))
                    //console.log('Chatroom mensaje recibido:', sData.data)
                } else {
                    handleToasts({
                        id: sData.data.message._id,
                        group: sData.data.chatRoom.group,
                        chatRoom: sData.data.chatRoom,
                        sender: sData.data.sender,
                        createdAt: sData.data.message.createdAt,
                        moment: moment(sData.data.message.createdAt).fromNow(),
                        content: sData.data.message.content
                    })
                }
                break

            default:
                break
        }
    }, [socketData])

    /* Modal */
    const [modal, setModal] = useState({
        trigger: false,
        title: false,
        action: false,
        icon: false,
        elementId: { authUserId: authUserId, element: false },
        groupId: groupId
    })

    const updateModalState = () => {
        setModal({
            trigger: false,
            title: false,
            action: false,
            icon: false,
            elementId: { authUserId: authUserId, element: false },
            groupId: groupId,
        })
    }

    const handleModalCallBack = (actionType, chatRoom = null) => {
        if (actionType === 'add') {
            chatRoomServices.addChatRoom(chatRoom)
        } else if (actionType === 'edit') {
            chatRoomServices.updateChatRoom(chatRoom)
        } else if (actionType === 'delete') {
            chatRoomServices.deleteChatRoom(chatRoom)
        }
        updateModalState()
    }

    const handleAddChatRoomBtn = () => {
        setModal({
            trigger: true,
            title: <FormattedMessage id="chatroomAdd" defaultMessage="Add chatroom item" />,
            action: 'add',
            icon: 'bi-plus-circle',
            elementId: { authUserId: authUserId, element: false },
            groupId: groupId
        })
    }

    const handleEditChatRoomBtn = (chatRoom) => {
        setModal({
            trigger: true,
            title: <FormattedMessage id="chatroomEdit" defaultMessage="Edit chatroom item" />,
            action: 'edit',
            icon: 'bi-pencil-square',
            elementId: { authUserId: authUserId, element: chatRoom },
            groupId: groupId
        })
    }

    const handleDeleteChatRoomBtn = (chatRoom) => {
        setModal({
            trigger: true,
            title: <FormattedMessage id="chatroomDelete" defaultMessage="Delete chatroom item" />,
            action: 'delete',
            icon: 'bi-trash',
            elementId: { authUserId: authUserId, element: chatRoom },
            groupId: groupId,
        })
    }

    const handleChatRoomSelection = (chatRoom) => {
        setChatRooms((prevChatRooms) => ({
            ...prevChatRooms,
            selected: {
                ...prevChatRooms.selected,
                chatRoom: chatRoom,
                messages: [],
                unreaded: 0
            }
        }))
        chatRoomServices.getChatRoomMessages(chatRoom._id)
    }

    const handleSendNewMessage = (chatRoomId, participants) => {
        if (newMessageContent.trim().length > 0) {
            chatRoomServices.addChatRoomMessage(chatRoomId, participants, newMessageContent)
        }
        setNewMessageContent('')
    }

    const handleInputChange = (e) => setNewMessageContent(e.target.value)

    function getAllUsersFromGroups(groups) {
        const allUsers = {}

        groups.forEach(group => {
            const usersData = group.usersData

            if (usersData) {
                usersData.forEach(user => {
                    const userId = user._id
                    allUsers[userId] = user
                })
            }
        })
        return allUsers
    }

    function getChatRoomFromChatRoomsList(chatRoomId) {
        return chatRooms.list.find(item => item._id === chatRoomId)
    }

    function getAllUnreadedMessages(userId) {
        if (!chatRooms.list) return
        let totalUnreaded = 0
        chatRooms.list.map((chatRoom) => {
            const user = chatRoom.participants.users.find(user => user.userId === userId)
            if (user) {
                totalUnreaded += user.unreaded
            }
        })
        handleChatMessagesNum(totalUnreaded)
        return totalUnreaded
    }

    function getChatRoomUnreadedMessages(chatRoomId, userId) {
        const chatRoom = getChatRoomFromChatRoomsList(chatRoomId)
        if (chatRoom) {
            const user = chatRoom.participants.users.find(user => user.userId === userId)
            if (user) {
                const unreaded = user.unreaded
                return unreaded
            }
        }
        return 0
    }

    useEffect(() => {
    }, [sendButtonDisabled])

    useEffect(() => {
        // Scroll hacia abajo cuando cambien los mensajes
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight
        }
    }, [chatRooms.selected.messages])

    useEffect(() => {
        chatRoomsRef.current = chatRooms
        getAllUnreadedMessages(authUserId)
    }, [chatRooms])

    useEffect(() => {
        // Actualiza la fecha cada segundo
        const intervalId = setInterval(() => {
            updateChatRoomsMomentLastMessageAt(chatRoomsRef.current.list)
            updateChatRoomsMomentMessageCreatedAt(chatRoomsRef.current.selected.messages)
        }, 60000) // every 60 seconds

        // Limpia el intervalo cuando el componente se desmonta
        return () => clearInterval(intervalId)
    }, []) // El array de dependencias está vacío, por lo que solo se ejecuta una vez al montar el componente


    const updateChatRoomsMomentLastMessageAt = (list) => {
        if (!list) return
        const updatedData = list.map(item => ({
            ...item,
            moment: moment(item.lastMessageAt).fromNow()
        }))

        setChatRooms(prevChatRooms => ({
            ...prevChatRooms,
            list: updatedData
        }))
    }

    const updateChatRoomsMomentMessageCreatedAt = (list) => {
        if (!list) return
        const updatedData = list.map(item => ({
            ...item,
            moment: moment(item.createdAt).fromNow()
        }))

        setChatRooms(prevChatRooms => ({
            ...prevChatRooms,
            selected: {
                ...prevChatRooms.selected,
                messages: updatedData
            }
        }))
    }
    
    if (!groupId || !chatRoomServices || !chatRoomsRef.current.list) {
        return (
            <Spinner />
        )
    }

    return (
        <>
            <Card bg="light">
                <Card.Header className="d-flex justify-content-between align-items-start">
                    {!chatRooms.selected.chatRoom &&
                        <h4><i className="bi bi-chat-square-fill"></i> <FormattedMessage id="chatroomsText" defaultMessage="Chatrooms" /></h4>
                    }
                    {chatRooms.selected.chatRoom && chatRooms.selected.chatRoom._id &&
                        <h4><i className="bi bi-chat-square-fill"></i> <FormattedMessage id="chatroomText" defaultMessage="Chatroom" />: {chatRooms.selected.chatRoom.name}</h4>
                    }

                    <Button type="button" className="btn btn-sm btn-light btn-outline-dark" onClick={handleAddChatRoomBtn}><i className="bi bi-plus-circle"></i> <FormattedMessage id="chatroomAdd" defaultMessage="Add Chatroom" /></Button>
                </Card.Header>
                <Card.Body style={{ margin: 0, backgroundColor: '#e2e3e5' }}>
                    <Row style={{ width: '100%', maxWidth: '100%', height: '100%' }}>
                        <Col lg={4}>
                            <ListGroup className='p-1' variant="flush" defaultActiveKey={chatRooms.selected.chatRoom ? "chatRoom-" + chatRooms.selected.chatRoom._id : 'none'} style={{ maxHeight: '510px', overflowY: 'auto' }}>
                                {chatRooms && chatRooms.list && chatRooms.list.map((chatRoom) => {
                                    const msgNum = getChatRoomUnreadedMessages(chatRoom._id, authUserId)
                                    return (
                                        <ListGroup.Item
                                            href={"#chatroom-" + chatRoom._id}
                                            action
                                            className="d-flex justify-content-between align-items-start"
                                            variant="secondary"
                                            key={"chatRoom-" + chatRoom._id}
                                            onClick={() => { handleChatRoomSelection(chatRoom) }}
                                        >
                                            <div className="ms-0 me-auto">
                                                <div className="fw-bold">{chatRoom.name}</div>

                                                <OverlayTrigger
                                                    trigger={["hover", "focus"]}
                                                    key={`tr-trigger`}
                                                    placement="right"
                                                    overlay={
                                                        <Popover id={`tr-popover`}>
                                                            <Popover.Body>
                                                                <ListGroup variant="flush">
                                                                    {chatRoom.usersData.map((user, index) => (
                                                                        <ListGroup.Item key={`tr-popover-${index}`}>{user.name} ({user.email})</ListGroup.Item>
                                                                    ))}
                                                                </ListGroup>
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <span><i className="bi bi-people"></i> {chatRoom.participants.users.length}</span>
                                                </OverlayTrigger>
                                                &nbsp;&nbsp;
                                                <i className="bi bi-chat-heart"></i> {chatRoom.moment ? chatRoom.moment : moment(chatRoom.lastMessageAt).fromNow()} &nbsp;
                                                <i className="bi bi-person-gear"></i> {chatRooms.allUsers[chatRoom.createdBy]?.name} &nbsp;
                                                {(!chatRoom.createdBy || authUserId === chatRoom.createdBy) &&
                                                    <>
                                                        <i className="bi bi-pencil-square text-secondary" onClick={() => handleEditChatRoomBtn(chatRoom)}> <FormattedMessage id="textEdit" defaultMessage="Edit" /></i> &nbsp;
                                                        <i className="bi bi-trash text-danger" onClick={() => handleDeleteChatRoomBtn(chatRoom)}> <FormattedMessage id="textDelete" defaultMessage="Delete" /></i>
                                                    </>
                                                }
                                            </div>
                                            {msgNum > 0 &&
                                                <Badge bg="warning" pill>
                                                    {msgNum}
                                                </Badge>
                                            }
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                        </Col>
                        <Col lg={8} style={{ backgroundColor: '#f8f9fa' }}>
                            <Row>
                                {!chatRooms.selected.chatRoom ? (
                                    <Col className="d-flex justify-content-center align-items-center" style={{ height: '510px' }}>
                                        <div className='text-center'>
                                            <img src="/logo_civilio_trans.png" height="25" className="d-inline-block align-top" alt="CIVILIO Information Systems" />
                                            <pre><FormattedMessage id="chatroomSelect" defaultMessage="Select or create a chatroom to start the conversation" /></pre>
                                            <Button type="button" className="btn btn-sm btn-light btn-outline-dark" onClick={handleAddChatRoomBtn}>
                                                <i className="bi bi-plus-circle"></i> <FormattedMessage id="chatroomAdd" defaultMessage="Add Chatroom" />
                                            </Button>
                                        </div>
                                    </Col>
                                ) : chatRooms.selected.chatRoom && chatRooms.selected.chatRoom._id && chatRooms.selected.messages.length === 0 ? (
                                    <Col className="d-flex justify-content-center align-items-center" style={{ height: '510px' }}>
                                        <div className='text-center'>
                                            <img src="/logo_civilio_trans.png" height="25" className="d-inline-block align-top" alt="CIVILIO Information Systems" />
                                            <pre><FormattedMessage id="chatroomNoMessages" defaultMessage="No messages in this chatroom!" /></pre>
                                        </div>
                                    </Col>
                                ) : (
                                    <Col className="p-4" style={{ height: '510px', maxHeight: '510px', overflowY: 'auto' }} ref={messagesContainerRef} >
                                        <MessagesCard messages={chatRooms.selected.messages} rightUserId={authUserId} chatRoomsRef={chatRoomsRef} />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="d-flex">
                    {chatRooms.selected.chatRoom && chatRooms.selected.chatRoom._id &&
                        <>
                            <Form.Control as="textarea" rows={3} placeholder={intl.formatMessage({ id: 'chatroomWrite', defaultMessage: 'Write your message here...' })} onChange={handleInputChange} value={newMessageContent} />&nbsp;
                            <Button ref={sendButtonRef} variant="secondary" size='lg' onClick={() => { handleSendNewMessage(chatRooms.selected.chatRoom._id, chatRooms.selected.chatRoom.participants) }} disabled={sendButtonDisabled}><i className="bi bi-send"></i> <FormattedMessage id="textSend" defaultMessage="Send" /></Button>
                        </>
                    }
                </Card.Footer>
            </Card >

            {modal.trigger && (
                <FormDataModal
                    title={modal.title}
                    icon={modal.icon}
                    BodyContent={ChatRoomForm}
                    callBack={handleModalCallBack}
                    trigger={modal.trigger}
                    elementId={modal.elementId}
                    action={modal.action}
                    groupId={modal.groupId}
                />
            )}
        </>
    )
}

export default ChatGroup