import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import LayersService from '../../services/LayersService'
import EntitiesService from '../../services/EntitiesService'
import MissionsService from '../../services/MissionsService'
import TasksService from '../../services/TasksService'


const MissionActionModal = ({ modal, toggle, selectedFeature, setModal, group, updateGroupData }) => {

    const { groupId } = useParams()  // :groupId param

    const [show, setShow] = useState(false)

    const handleClose = () => {
        setModal(false)
        setShow(false)
        setEntitiesUsers(false)
        setTaskInspectors([])
        updateGroupData(true)
    }
    const handleShow = () => setShow(true)

    const [geoLayers, setGeoLayers] = useState(false)

    const getGeoLayers = () => {
        LayersService.getLayers({ pageNum: 1, type: 'GEOJSON', search: '', limit: 100 })
            .then(res => {
                setGeoLayers(res.data)
            })
    }

    const [entitiesUsers, setEntitiesUsers] = useState(false)
    const [taskName, setTaskName] = useState()
    const [taskLayer, setTaskLayer] = useState()
    const [taskDescription, setTaskDescription] = useState()
    const [taskInspectors, setTaskInspectors] = useState([])
    const [formValid, setFormValid] = useState(false)

    const handleEntityCheckBox = (e) => {
        if (e.target.checked) {
            getEntityUsers(e.target.name) // e.target.name come with entity._id
        } else {
            let newArray = []
            for (var i = 0; i < entitiesUsers.length; i++) {
                if (entitiesUsers[i].entityId != e.target.name) {
                    newArray.push(entitiesUsers[i])
                }
            }
            setEntitiesUsers(newArray)
            setTaskInspectors([])
        }

    }

    const getEntityUsers = (entityId) => {
        if (entityId) {
            EntitiesService.getEntityUsers(entityId)
                .then(res => {
                    let newArray = entitiesUsers || []
                    for (var i = 0; i < res.data.users.length; i++) {
                        var index = newArray.findIndex(x => x._id === res.data.users[i]._id)
                        index === -1 ? newArray.push(res.data.users[i]) : console.log("This item already exists")
                    }
                    setEntitiesUsers(newArray)
                    setTaskInspectors([])
                })
        }
    }

    const handleSelectInspectorsChange = (e) => {
        setTaskInspectors([].slice.call(e.target.selectedOptions).map(item => item.value))
    }

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFieldValue(name, value)
    }

    const setFieldValue = (fieldName, value) => {

        switch (fieldName) {
            case 'taskName':
                setTaskName(value)
                break
            case 'taskLayer':
                setTaskLayer(value)
                break
            case 'taskDescription':
                setTaskDescription(value)
                break
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget
        if (form.checkValidity()) {
            const mission = {
                name: taskName,
                description: taskDescription,
                groupId: group._id,
                feature: {
                    featureId: selectedFeature.id,
                    geometry: selectedFeature.geometry
                },
                layerId: taskLayer,
                users: taskInspectors
            }

            // Add mission
            MissionsService.addMission(mission)
                .then(res => {
                    setTaskInspectors([])
                    setFormValid(false)
                    handleClose()
                })
            return
        }

        setFormValid(true)
    }

    useEffect(() => {
        setEntitiesUsers(false)
        setTaskInspectors([])
    }, [groupId])

    useEffect(() => {
        if (!modal) return
        if (toggle) {
            handleShow()
        }
        getGeoLayers()
    }, [toggle])

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop={"static"}
            keyboard={false}
            fullscreen={false}
            aria-labelledby={"geoj-modal-feature-create-mission"}
            size={"lg"}
        >
            <Modal.Header closeButton>
                <Modal.Title id="geoj-modal-feature-create-mission"><i className="bi bi-grid-1x2"></i> <FormattedMessage id="missionsCreate" defaultMessage="Create mission" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>
                    <b><FormattedMessage id="missionsElementName" defaultMessage="Element name" />: </b>
                    {selectedFeature &&
                        selectedFeature.properties &&
                        selectedFeature.properties.name}
                </h5>
                <Form noValidate validated={formValid} onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="taskform.group.name">
                        <Form.Label><FormattedMessage id="missionsGroupName" defaultMessage="Group name" />:</Form.Label>
                        <Form.Control type="text" name="groupName" value={group.name} placeholder={group.name} disabled />
                    </Form.Group>

                    {geoLayers && geoLayers.layers &&
                        <Form.Group className="mb-3" controlId="taskform.task.layers">
                            <Form.Label><FormattedMessage id="missionsLayerWithin" defaultMessage="Layer to get elements within" />:</Form.Label>
                            <Form.Select
                                name="taskLayer"
                                size="md"
                                onChange={handleInputChange}
                                required
                            >
                                <option value="" key="select-layer-0"><FormattedMessage id="textSelect" defaultMessage="Select" /></option>
                                {geoLayers.layers.map((geoLayer, index) => {
                                    if (geoLayer._id != selectedFeature.layerId && geoLayer.type === 'GEOJSON' && geoLayer.active) {
                                        return (
                                            <option value={geoLayer._id} key={`select-layer-${geoLayer._id}`}>{geoLayer.name}</option>
                                        )
                                    }
                                })}

                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="missionsLayerWithinInvalid" defaultMessage="Select a layer to create tasks with elements within" /> {selectedFeature &&
                                    selectedFeature.properties &&
                                    selectedFeature.properties.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                    }

                    <Form.Group className="mb-3" controlId="taskform.task.name">
                        <Form.Label><FormattedMessage id="missionsName" defaultMessage="Mission name" />:</Form.Label>
                        <Form.Control
                            type="text"
                            name="taskName"
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="missionsNameInvalid" defaultMessage="Provide a mission name" />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="taskform.task.description">
                        <Form.Label><FormattedMessage id="missionsDescription" defaultMessage="Description" />:</Form.Label>
                        <Form.Control
                            name="taskDescription"
                            as="textarea"
                            rows={3}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="taskform.task.entities">
                        <Form.Label><FormattedMessage id="missionsEntities" defaultMessage="Entities" />:</Form.Label>
                        <div key="inline-radio-entities" className="mb-3">
                            {group && group.entities && group.entities.map((entity, index) => {
                                return (
                                    <Form.Check
                                        inline
                                        label={entity.name}
                                        name={entity._id}
                                        type="switch"
                                        id={`taskEntity-${entity._id}`}
                                        key={`taskEntity-${entity._id}`}
                                        onChange={handleEntityCheckBox}
                                    />
                                )
                            }
                            )}
                        </div>
                    </Form.Group>

                    {entitiesUsers &&
                        <Form.Group className="mb-3" controlId="taskform.task.users">
                            <Form.Label><FormattedMessage id="missionsInspectors" defaultMessage="Inspectors" />:</Form.Label>
                            <Form.Select
                                key={`taskInspectors-${group._id}`}
                                name="taskInspectors"
                                value={taskInspectors}
                                size="md"
                                htmlSize={6}
                                onChange={handleSelectInspectorsChange}
                                required
                                multiple="multiple"
                            >
                                {entitiesUsers.map((user, index) => {
                                    if (user.roles.includes('ROLE_INSPECTOR')) {
                                        return (
                                            <option value={user._id} key={`select-inspectors-${user._id}`}>{user.name} ({user.email})</option>
                                        )
                                    }
                                })}

                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="missionsInspectorsInvalid" defaultMessage="Select one o more inspectors users to distribute the tasks automatically" />
                            </Form.Control.Feedback>
                        </Form.Group>
                    }

                    <div className="d-grid gap-2">
                        <Button type="submit" variant="primary">
                            <i className="bi bi-clipboard-plus"></i> <FormattedMessage id="missionsCreate" defaultMessage="Create mission" />
                        </Button>
                    </div>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    <i className="bi bi-x-square"></i> <FormattedMessage id="textCancel" defaultMessage="Cancel" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MissionActionModal

