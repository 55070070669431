import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class TasksService {

  // Get tasks list from missions :id 
  getTasks = async (id, pageNum, search = '', limit = false) => {
    return await axios.get(API_URL + '/missions/' + id + '/tasks?page=' + pageNum + '&search=' + search + '&limit=' + limit, { headers: authHeader() })
  }

  // Add new task to mission :id
  addTask = async (id, task) => {
    return await axios.post(API_URL + '/missions/' + id + '/tasks', task, { headers: authHeader() })
  }

  // Update task :taskId data from mission :id
  updateTask = async (id, taskId, task) => {
    return await axios.patch(API_URL + '/missions/' + id + '/tasks/' + taskId, task, { headers: authHeader() })
  }

  // Get task :taskId from mission :id
  getTask = async (id, taskId, populate = false) => {
    return await axios.get(API_URL + '/missions/' + id + '/tasks/' + taskId + '?populate=' + populate, { headers: authHeader() })
  }

  // Delete task :taskId from mission :id
  deleteTask = async (id, taskId) => {
    return await axios.delete(API_URL + '/missions/' + id + '/tasks/' + taskId, { headers: authHeader() })
  }

  // Get all task from authenticated user
  getUserTasks = async () => {
    return await axios.get(API_URL + '/user/tasks', { headers: authHeader() })
  }

  // Update authenticated user task :taskId data
  updateUserTask = async (taskId, task) => {
    return await axios.patch(API_URL + '/user/tasks/' + taskId, task, { headers: authHeader() })
  }

  // Get tasks list from group :id filtering by mission, user and search query 
  getGroupTasks = async ({ groupId, missionId = false, userId = false, pageNum = 1, search = '', timestamp = false, dateFilter = false, limit = false, lonbl = false, latbl = false, lonur = false, latur = false }) => {
    let url = API_URL + '/groups/' + groupId + '/tasks?'

    if (missionId !== false) {
      url += 'missionId=' + missionId + '&'
    }
    if (userId !== false) {
      url += 'userId=' + userId + '&'
    }
    if (timestamp !== false) {
      url += 'timestamp=' + timestamp + '&'
    }
    if (dateFilter !== false) {
      //url += 'dateFilter=' + dateFilter + '&'
    }
    if (pageNum !== false) {
      url += 'page=' + pageNum + '&'
    }
    if (search !== false) {
      url += 'search=' + search + '&'
    }
    if (limit !== false && !lonbl) {
      url += 'limit=' + limit + '&'
    }
    if (lonbl !== false) {
      url += 'lonbl=' + lonbl + '&'
    }
    if (latbl !== false) {
      url += 'latbl=' + latbl + '&'
    }
    if (lonur !== false) {
      url += 'lonur=' + lonur + '&'
    }
    if (latur !== false) {
      url += 'latur=' + latur + '&'
    }

    return await axios.get(url, { headers: authHeader() })
  }

  // Get tasks stats from group :id filtering by mission, user and search query 
  getGroupTasksStats = async ({ groupId, missionId = false, userId = false, search = '', timestamp = false, dateFilter = false, lonbl = false, latbl = false, lonur = false, latur = false }) => {
    let url = API_URL + '/groups/' + groupId + '/tasksStats?'

    if (missionId !== false) {
      url += 'missionId=' + missionId + '&'
    }
    if (userId !== false) {
      url += 'userId=' + userId + '&'
    }
    if (timestamp !== false) {
      url += 'timestamp=' + timestamp + '&'
    }
    if (dateFilter !== false) {
      //url += 'dateFilter=' + dateFilter + '&'
    }
    if (search !== false) {
      url += 'search=' + search + '&'
    }
    if (lonbl !== false) {
      url += 'lonbl=' + lonbl + '&'
    }
    if (latbl !== false) {
      url += 'latbl=' + latbl + '&'
    }
    if (lonur !== false) {
      url += 'lonur=' + lonur + '&'
    }
    if (latur !== false) {
      url += 'latur=' + latur + '&'
    }

    return await axios.get(url, { headers: authHeader() })
  }

}

export default new TasksService()