import { Alert } from 'react-bootstrap'
import Spinner from 'components/spinner/Spinner'

const getErrorMessage = (error) => {
    return (
        (error.response && error.response.data && error.response.data.msg) ||
        error.message ||
        error.toString()
    )
}

export const AlertError = ({ error, callBack = false }) => {
    const resMessage = getErrorMessage(error)
    callBack && callBack(resMessage)

    return error && (
        <Alert key={'error-message-'} variant={'danger'}>
            {resMessage}
        </Alert>
    )
}

export const AlertLoading = ({ isLoaded, callBack = false }) => {
    callBack && callBack(isLoaded)

    return !isLoaded && <Spinner />
}

export const AlertMsg = ({ message, variant = 'danger', onCloseHandler, dismissible = true, show = true }) => {
    const resMessage = message instanceof Error ? getErrorMessage(message) : message

    return (
        <Alert
            key={'alert-message'}
            variant={variant}
            dismissible={!(message instanceof Error) && dismissible}
            onClose={onCloseHandler}
            show={show}
        >
            {resMessage}
        </Alert>
    )
}

export const handleScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'auto'
    })
}