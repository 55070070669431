import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'
import Alert from 'react-bootstrap/Alert'

import ImageUploading from "react-images-uploading"
import imageCompression from 'browser-image-compression'

import Spinner from "../../components/spinner/Spinner"

import PicturesService from 'services/PicturesService'

const PhotosModal = ({ task, callback, updateTasksList }) => {

    const [alertMsg, setAlertMsg] = useState(false)
    const [alertType, setAlertType] = useState(false)

    const [show, setShow] = useState(false)
    const [fullscreen, setFullscreen] = useState(true)

    const handleShow = () => setShow(true)
    const handleClose = () => {
        setShow(false)
        callback(false)

        const update = { pictures: images }
        updateTasksList(task._id, update)
    }

    const [isLoaded, setIsLoaded] = useState(false)
    const [images, setImages] = useState([])
    const maxNumber = 5

    const onChangeImages = async (imageList, addUpdateIndex) => {

        if (addUpdateIndex !== undefined) {
            setAlertMsg("Uploading ...")
            setAlertType("warning")
            const updatedImages = [...imageList]
            const promises = addUpdateIndex.map(async index => {
                if (!images[index]) {
                    const imageToAdd = imageList[index]
                    const compressedDataUrl = await handleImageCompression(
                        imageToAdd,
                        index
                    )
                    const response = await PicturesService.addPicture(task._id, {
                        dataUrl: compressedDataUrl,
                        title: imageToAdd.title || "",
                        description: imageToAdd.description || "",
                    })
                    const updatedImage = response.data
                    updatedImage.saved = true
                    updatedImage.alertMsg = "Photo added"
                    updatedImage.alertType = "primary"
                    updatedImages[index] = updatedImage
                } else {
                    const imageToUpdate = images[index]
                    const dataUrlToUpdate = await handleImageCompression(
                        imageList[index],
                        index
                    )
                    const update = {
                        dataUrl: dataUrlToUpdate,
                        title: imageToUpdate.title || "",
                        description: imageToUpdate.description || "",
                    }
                    const response = await PicturesService.updatePicture(
                        task._id,
                        imageToUpdate._id,
                        update
                    )
                    const updatedImage = response.data
                    updatedImage.saved = true
                    updatedImage.alertMsg = "Photo updated"
                    updatedImage.alertType = "primary"
                    updatedImages[index] = updatedImage
                }
            })

            await Promise.all(promises)
            setImages(updatedImages)
            setAlertMsg(false)
            setAlertType(false)

        }
    }

    const handleTitleInput = (e, index) => {
        const newImages = [...images]
        newImages[index] = {
            ...newImages[index],
            title: e.target.value,
            saved: false
        }
        setImages(newImages)
    }

    const handleDescriptionInput = (e, index) => {
        const newImages = [...images]
        newImages[index] = {
            ...newImages[index],
            description: e.target.value,
            saved: false
        }
        setImages(newImages)
    }

    const handleSaveImageBtn = async (e, index) => {
        e.target.disabled = true

        const picture = images[index]
        const update = { title: picture.title, description: picture.description }

        await PicturesService.updatePicture(task._id, picture._id, update).then(
            response => {
                let updatedPicture = {}
                updatedPicture = response.data
                updatedPicture.saved = true
                updatedPicture.alertMsg = "Photo title updated"
                updatedPicture.alertType = "primary"
                const updatedImages = [...images]
                updatedImages[index] = updatedPicture
                setImages(updatedImages)
            },
            error => {
                contentMessage('Error: ' + error.message, "danger")
                e.target.disabled = false
            }
        )
    }

    const handleDeleteImageBtn = (e, pictureId, index, callback) => {
        e.target.disabled = true

        PicturesService.deletePicture(task._id, pictureId).then(
            response => {
                const newImagesArray = [...images]
                newImagesArray.splice(index, 1)
                setImages(newImagesArray)
                e.target.disabled = false
                //callback(index)
            },
            error => {
                contentMessage('Error: ' + error.message, "danger")
                e.target.disabled = false
            }
        )
    }

    const contentMessage = (msg, type) => {
        setAlertMsg(msg)
        setAlertType(type)
    }

    const handleImageCompression = async (picture, index) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1024,
            //onProgress: Function,
            fileType: 'image/jpeg',
        }

        try {
            const imageFile = await imageCompression.getFilefromDataUrl(picture.dataUrl, 'imageCompress.jpeg')
            const compressedFile = await imageCompression(imageFile, options)
            const reader = new FileReader()
            reader.readAsDataURL(compressedFile)
            const dataUrl = await new Promise((resolve, reject) => {
                reader.onloadend = () => {
                    resolve(reader.result)
                }
                reader.onerror = reject
            })
            picture.dataUrl = dataUrl
            return dataUrl
        } catch (error) {
            contentMessage('Error: ' + error.message, 'danger')
            return picture.dataUrl
        }
    }

    useEffect(() => {
        if (isLoaded) return

        PicturesService.getPictures(task._id, true).then(  // true to get picture dataUrl
            response => {
                const loadedImages = response.data
                setImages(loadedImages.map(obj => ({ ...obj, saved: true })))
                setIsLoaded(true)
            },
            error => {
                contentMessage('Error: ' + error.message, "danger")
            }
        )

        handleShow()
    }, [])

    return (
        show && (
            <ImageUploading
                multiple
                value={images}
                onChange={onChangeImages}
                maxNumber={maxNumber}
                dataURLKey="dataUrl"
                acceptType={["jpg"]}
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps
                }) => (
                    <>
                        <Modal show={show} fullscreen={fullscreen} onHide={handleClose} >
                            <Modal.Header closeButton={false} className={'p-2 bg-' + task.resultProperties.bgcolor} style={{ color: task.resultProperties.textcolor }}>
                                <Button size={'sm'} onClick={handleClose} variant={task.resultProperties.bgcolor}><i className="bi bi-arrow-left h4"></i></Button>
                                <Modal.Title style={{ color: task.resultProperties.textcolor }} className="h6 d-flex justify-content-between align-items-center">
                                    <i className="bi bi-camera-fill"></i>&nbsp;{task.name}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='p-2'>
                                <>
                                    {alertMsg && alertType && <Alert variant={alertType}>{alertMsg}</Alert>}
                                </>
                                {isLoaded ? (
                                    <ListGroup variant="flush">
                                        {imageList && imageList.map((image, index) => {
                                            return (
                                                <ListGroup.Item key={'photos-list-group-' + index} variant="light">
                                                    <Card key={'photos-list-group-card-' + index}>
                                                        <Card.Img key={'photos-list-group-card-img-' + index} variant="top" src={image.dataUrl} />
                                                        <Card.Body>
                                                            <Form>
                                                                <Form.Group key={'photos-list-group-card-img-title-gr-' + index} className="mb-3">
                                                                    {image.alertMsg && image.alertType && <Alert variant={image.alertType}>{image.alertMsg}</Alert>}
                                                                    <Form.Label>Image title {image._id}</Form.Label>
                                                                    <Form.Control
                                                                        key={'photos-list-group-card-img-title-fc-' + index}
                                                                        as="input"
                                                                        value={image.title || ''}
                                                                        onChange={(e) => handleTitleInput(e, index)}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group key={'photos-list-group-card-img-description-gr-' + index} className="mb-3">
                                                                    <Form.Label>Description</Form.Label>
                                                                    <Form.Control
                                                                        key={'photos-list-group-card-img-description-fc-' + index}
                                                                        as="textarea"
                                                                        rows={2}
                                                                        value={image.description || ''}
                                                                        onChange={(e) => handleDescriptionInput(e, index)}
                                                                    />
                                                                </Form.Group>
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    {image && !image.saved ? <Button variant="warning" size={'sm'} onClick={(e) => handleSaveImageBtn(e, index)}><i className="bi bi-save2"></i> Save</Button> : <Button variant="secondary" size={'sm'} disabled><i className="bi bi-save2"></i> Save</Button>}
                                                                    <span>
                                                                        <Button variant="info" size={'sm'} onClick={() => onImageUpdate(index)}><i className="bi bi-image"></i></Button>&nbsp;
                                                                        <Button variant="danger" size={'sm'} onClick={(e) => handleDeleteImageBtn(e, image._id, index, onImageRemove)}><i className="bi bi-trash"></i></Button>
                                                                    </span>
                                                                </div>
                                                            </Form>
                                                        </Card.Body>
                                                    </Card>
                                                </ListGroup.Item>
                                            )
                                        }
                                        )}
                                    </ListGroup>
                                ) : (
                                    <div>
                                        <Spinner />
                                    </div>
                                )}
                            </Modal.Body>
                            <Modal.Footer className="d-flex justify-content-center align-items-center p-0">
                                {isLoaded && (
                                    <>
                                        <Button variant={isDragging ? 'info' : 'primary'} onClick={onImageUpload} {...dragProps}>
                                            <i className="bi bi-camera-fill"></i> Select photos
                                        </Button>
                                        <Button variant="danger" onClick={onImageRemoveAll} disabled>
                                            <i className="bi bi-trash"></i> Remove all
                                        </Button>
                                    </>
                                )}
                            </Modal.Footer>
                        </Modal>
                    </>
                )}
            </ImageUploading>
        )
    )
}

export default PhotosModal