import React, { useEffect, useState, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import TableList from "../components/tables/TableList"

import ConfigService from 'services/ConfigService'
import GroupsService from '../services/GroupsService'
import GroupsForm from './GroupsForm'

import { AlertError, AlertLoading } from 'helpers/AlertHelper'

const Groups = ({ update, updateFunction }) => {
  const intl = useIntl()

  const fronConfig = ConfigService.getFrontConfig()

  const [keys, setKeys] = useState({})
  const [groups, setGroups] = useState([])
  const [pagination, setPagination] = useState([])
  const [currPage, setCurrPage] = useState(1)
  const [search, setSearch] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(false)

  const goToPage = (pageNum) => {
    setCurrPage(pageNum)
  }

  const refreshData = () => {
    setIsLoaded(false)
    getData()

    // Update sidebar event&groups accordion Is a trigger passed throught app.js -> sidebar.js -> accordion.js
    updateFunction(true)
  }

  const getData = async () => {
    await GroupsService.getGroups(currPage, search, fronConfig.app.itemsListLimit).then(
      response => {
        const groupsData = response.data.groups
        groupsData.map((group, num) => {
          if (group.form) {
            groupsData[num].formTitle = group.form.title
          }
          return groupsData[num]
        })

        setKeys({
          name: <FormattedMessage id="groupsName" defaultMessage="Name" />,
          skill: <FormattedMessage id="groupsSkill" defaultMessage="Skill" />,
          event: <FormattedMessage id="groupsEvent" defaultMessage="Event" />,
          formTitle: <FormattedMessage id="groupsForm" defaultMessage="Form" />,
          entities: <FormattedMessage id="groupsEntities" defaultMessage="Entities" />,
          missions: <FormattedMessage id="groupsMissions" defaultMessage="Missions" />,
          createdAt: <FormattedMessage id="groupsCreatedAt" defaultMessage="Created at" />
        })
        setGroups(groupsData)
        setPagination(response.data.pagination)
        setIsLoaded(true)
      },
      error => {
        setError(error)
      }
    )

  }

  const prevSearch = useRef()
  useEffect(() => {
    if (!prevSearch.current || prevSearch.current !== search) {
      prevSearch.current = search
      setIsLoaded(false)
    }
    setCurrPage(1)
    getData()
  }, [search])

  const prevCurrentPage = useRef()
  useEffect(() => {
    if (!prevCurrentPage.current || prevCurrentPage.current !== currPage) {
      prevCurrentPage.current = currPage
      setIsLoaded(false)
    }
    getData()
  }, [currPage])

  if (error) {
    return (
      <AlertError error={error} />
    )
  } else if (!isLoaded) {
    return (
      <AlertLoading isLoaded={isLoaded} />
    )
  }
  
  return (
    <TableList
      caption={intl.formatMessage({ id: 'groups', defaultMessage: 'Groups' })}
      icon="bi-diagram-3-fill"
      data={groups}
      keys={keys}
      pagination={true}
      paginationData={pagination}
      goToPageFunction={goToPage}
      refreshData={refreshData}
      setSearchValue={setSearch}
      searchValue={search}
      titleAdd={intl.formatMessage({ id: 'groupsAdd', defaultMessage: 'Add group' })}
      titleEdit={intl.formatMessage({ id: 'groupsEdit', defaultMessage: 'Edit group' })}
      titleDelete={intl.formatMessage({ id: 'groupsDelete', defaultMessage: 'Delete group' })}
      ElementForm={GroupsForm}
    />
  )
}

export default Groups