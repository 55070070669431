import React, { Component } from "react"
import { Routes, Route, Link } from "react-router-dom"
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import 'leaflet/dist/leaflet.css'

import "./App.css"

import EventBus from "utils/EventBus"

import AuthService from "services/AuthService"

// Components WITH AUTH requirements
import NavbarMenu from "components/bars/Navbar"
import Sidebar from "components/bars/Sidebar"

// Pages WITH AUTH requirements
import Config from "pages/Config"
import Dashboard from "pages/Dashboard"
import Profile from "pages/Profile"
import Logout from "pages/Logout"
import Users from "pages/Users"
import Entities from "pages/Entities"
import Forms from "pages/Forms"
import Layers from "pages/Layers"
import Events from "pages/Events"
import Groups from "pages/Groups"
import Missions from "pages/Missions"
import NotFound from "pages/NotFound"

import InspectorTasks from "pages/inspector/InspectorTasks"

// Pages WITHOUT AUTH requirements
import Login from "pages/Login"
import Signup from "pages/Signup";


class App extends Component {
  constructor(props) {
    super(props)

    this.logIn = this.logIn.bind(this)
    this.logOut = this.logOut.bind(this)

    this.state = {
      showInspectorBoard: false,
      showCoordinatorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      updateAccordion: false,
      frontConfig: false,
    }
  }

  updateSideBarAccordion = (value) => {
    this.setState({
      ...this.state,
      updateAccordion: value
    })
  }

  componentDidUpdate = (_, prevState) => {
    if (prevState.updateAccordion !== this.state.updateAccordion) {
      console.log("App.js updating... value:" + this.state.updateAccordion)
    }
  }

  componentDidMount = async () => {
    document.title = "Civilio Information Systems"

    await this.logIn()

    EventBus.on("login", () => {
      this.logIn()
    })

    EventBus.on("logout", () => {
      this.logOut()
    })
  }

  componentWillUnmount() {
    EventBus.remove("login")
    EventBus.remove("logout")
  }

  logIn = async () => {
    const user = await AuthService.getCurrentUser()

    if (user) {
      this.setState({
        currentUser: user,
        showInspectorBoard: user.roles.includes("ROLE_INSPECTOR"),
        showCoordinatorBoard: user.roles.includes("ROLE_COORDINATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
        updateAccordion: false,
        frontConfig: user.frontConfig,
      })
      document.title = user.frontConfig.app.title
    }
  }

  logOut() {
    localStorage.removeItem("user")
    this.setState({
      showInspectorBoard: false,
      showCoordinatorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      updateAccordion: false,
      frontConfig: false,
    })
    document.title = "Civilio Information Systems"
  }

  render() {
    const { currentUser, showAdminBoard, showInspectorBoard, showCoordinatorBoard } = this.state

    const commonProps = { update: this.state.updateAccordion, updateFunction: this.updateSideBarAccordion }

    if (!currentUser) {
      return (
        <Routes>
          <Route index path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      )
    } else if (showAdminBoard || showCoordinatorBoard) {
      return (
        <>
          <NavbarMenu state={this.state} />

          <div className="container-fluid">
            <div className="row">
              <Sidebar state={this.state} update={this.state.updateAccordion} updateFunction={this.updateSideBarAccordion} />
              <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <Routes>
                  <Route index path="/" element={<Dashboard />} />
                  <Route path="/home" element={<Dashboard />} />
                  <Route path="/missions/:groupId" element={<Missions />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/events" element={<Events {...commonProps} />} />
                  <Route path="/groups" element={<Groups {...commonProps} />} />
                  <Route path="/entities" element={<Entities />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/layers" element={<Layers />} />
                  <Route path="/forms" element={<Forms />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/config" element={<Config />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </main>
            </div>
          </div>
        </>
      )
    } else if (showInspectorBoard) {
      return (
        <>
          <Navbar collapseOnSelect bg="dark" variant="light" expand="sm" sticky="top" className="mob-navbar-bg">
            <Container fluid>
              <Navbar.Brand bsPrefix="mob-bg-brand">
                <Link to={"/"}>
                  <img
                    src="/logo_civilio_trans.png"
                    height="25"
                    className="d-inline-block align-top"
                    alt="CIVILIO"
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="mobile-inspectors-navbar-nav" />
              <Navbar.Collapse id="mobile-inspectors-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Item>
                    <Link to={"/home"} className="nav-link"><i className="bi bi-speedometer"></i> Tasks list</Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link to={"/profile"} className="nav-link"><i className="bi bi-person-square"></i> My account</Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link to={"/logout"} className="nav-link"><i className="bi bi-door-closed-fill"></i> Logout</Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Container className='p-0' fluid>
            <Routes>
              <Route index path="/" element={<InspectorTasks mapCenter={this.state.frontConfig.map.center} mapZoom={this.state.frontConfig.map.zoom} />} />
              <Route path="/home" element={<InspectorTasks mapCenter={this.state.frontConfig.map.center} mapZoom={this.state.frontConfig.map.zoom} />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/login" element={<Profile />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Container>
        </>
      )
    } else {
      return (
        <>
          <Navbar collapseOnSelect bg="dark" variant="light" expand="sm" sticky="top" className="mob-navbar-bg">
            <Container fluid>
              <Navbar.Brand bsPrefix="mob-bg-brand">
                <Link to={"/"}>
                  <img
                    src="/logo_civilio_trans.png"
                    height="25"
                    className="d-inline-block align-top"
                    alt="CIVILIO"
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="mobile-inspectors-navbar-nav" />
              <Navbar.Collapse id="mobile-inspectors-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Item>
                    <Link to={"/home"} className="nav-link"><i className="bi bi-speedometer"></i> Tasks list</Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link to={"/profile"} className="nav-link"><i className="bi bi-person-square"></i> My account</Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link to={"/logout"} className="nav-link"><i className="bi bi-door-closed-fill"></i> Logout</Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Container className='p-0' fluid>
            <Routes>
              <Route index path="/" element={<InspectorTasks />} />
              <Route path="/home" element={<InspectorTasks />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/login" element={<Profile />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Container>
        </>
      )
    }

  }
}

export default App