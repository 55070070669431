import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export const FormModal = (props) => {

    const title = props.title
    const icon = props.icon
    const BodyContent = props.body
    const elementId = props.elementId
    const action = props.action
    const filterActive = props.filterActive || false

    const [show, setShow] = useState(false)

    const handleClose = () => {
        setShow(false)
        props.refreshData(true)
    }
    const handleShow = () => setShow(true)

    useEffect(() => {
        if (props.modalTrigger) {
            handleShow()
        }
    }, [props.modalTrigger])

    return (
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title><i className={"bi "+icon}></i> {title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <BodyContent onSubmit={handleClose} elementId={elementId} action={action} filterActive={filterActive} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                <i className="bi bi-x-square"></i> <FormattedMessage id="textClose" defaultMessage="Close" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default FormModal