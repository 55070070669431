import React from 'react'
import { FormattedMessage } from 'react-intl'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Table from 'react-bootstrap/Table'
import Badge from 'react-bootstrap/Badge'

import moment from 'moment'

const GroupInfo = ({ group, show, onClose }) => {
    
    const handleClose = () => {
        onClose()
    }

    return (
        <Offcanvas show={show} onHide={handleClose} key={"canvas-" + group._id} placement="end" scroll={true} backdrop={false}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title><i className="bi bi-shield-fill"></i> {group.name}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <p>{group.description}</p>
                <dl className="row" key={"canvas-dl-data-" + group._id}>
                    <dt className="col-sm-3"><FormattedMessage id="groupsSkill" defaultMessage="Skill" /></dt>
                    <dd className="col-sm-9">{group.skill}</dd>

                    <dt className="col-sm-3"><FormattedMessage id="groupsCreatedAt" defaultMessage="Created at" /></dt>
                    <dd className="col-sm-9">{moment(group.createdAt).fromNow()}</dd>

                    <dt className="col-sm-3"><FormattedMessage id="groupsEvent" defaultMessage="Event" /></dt>
                    <dd className="col-sm-9">{group.event.name}</dd>

                    <dt className="col-sm-3"><FormattedMessage id="groupsForm" defaultMessage="Form" /></dt>
                    <dd className="col-sm-9">{group.form.title}</dd>

                    <dt className="col-sm-3"><FormattedMessage id="groupsDrawLayer" defaultMessage="Draw layer" /></dt>
                    <dd className="col-sm-9">{group.drawLayer.name}</dd>
                </dl>

                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th className="col-sm-3"><FormattedMessage id="groupsEntities" defaultMessage="Entities" /></th>
                            <th className="col-sm-9">
                                <Badge bg="secondary">{group.entities && Object.keys(group.entities).length} <i className="bi bi-briefcase-fill"></i></Badge>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {group.entities.map((entity, index) => {
                            return (
                                <tr key={"canvas-table-" + group._id + "-tr-entity-" + entity._id}>
                                    <td>
                                        {index + 1}.
                                    </td>
                                    <td>
                                        {entity.name}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>

                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th className="col-sm-3"><FormattedMessage id="groupsMissions" defaultMessage="Missions" /></th>
                            <th className="col-sm-9">
                                <Badge bg="secondary">
                                    {group.missions && Object.keys(group.missions).length} <i className="bi bi-lightning-fill"></i>
                                </Badge>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {group.missions.map((mission, index) => {
                            return (
                                <tr key={"canvas-table-" + group._id + "-tr-mission-" + mission._id}>
                                    <td>
                                        <Badge bg="secondary">
                                            <i className="bi bi-people-fill"></i> {mission.users.length}
                                        </Badge>
                                        &nbsp;
                                        <Badge bg="secondary">
                                            <i className="bi bi-clipboard2-fill"></i> {mission.features.length}
                                        </Badge>
                                    </td>
                                    <td>
                                        {mission.name}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>

            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default GroupInfo