import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class FeaturesService {

    // Get features list from layerId
    getFeatures = async ({ layerId, pageNum = 1, search = '', limit = false }) => {
        let url = API_URL + '/layers/' + layerId + 'features?'

        if (pageNum !== false) {
            url += 'page=' + pageNum + '&'
        }
        if (search !== false) {
            url += 'search=' + search + '&'
        }
        if (search !== false) {
            url += 'limit=' + limit
        }

        return await axios.get(url, { headers: authHeader() })
    }

    // Add new feature to layerId
    addFeature = async ({ layerId, feature }) => {
        return await axios.post(API_URL + '/layers/' + layerId + '/features', feature, { headers: authHeader() })
    }

    // Update layer data
    updateFeature = async ({ layerId, featureId, feature }) => {
        return await axios.patch(API_URL + '/layers/' + layerId + '/features/' + featureId, feature, { headers: authHeader() })
    }

    // Get layer data
    getFeature = async ({ layerId, featureId }) => {
        return await axios.get(API_URL + '/layers/' + layerId + '/features/' + featureId, { headers: authHeader() })
    }

    // Delete layer 
    deleteFeature = async ({ layerId, featureId }) => {
        return await axios.delete(API_URL + '/layers/' + layerId + '/features/' + featureId, { headers: authHeader() })
    }

}

export default new FeaturesService()