import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from "react-router-dom"
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import * as serviceWorker from "./serviceWorker"

import { IntlProvider } from 'react-intl'
import translations_en from './lang/en.json'  // English
import translations_es from './lang/es.json'  // Español
import translations_fr from './lang/fr.json'  // Francais

const translations = {
  en: translations_en,
  es: translations_es,
  fr: translations_fr,
}

const locale = navigator.language.split('-')[0]
const defaultLocale = 'en'
const selectedTranslations = translations[locale] || translations[defaultLocale]

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <IntlProvider locale={locale} messages={selectedTranslations} defaultLocale="en">
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </IntlProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

serviceWorker.unregister()
