import React from 'react'
import Modal from 'react-bootstrap/Modal'

export const HelpModal = ({ show, helpInfo, onClose }) => {

    const { title, description } = helpInfo

    const handleClose = () => onClose()

    return (
        <Modal show={show} onHide={handleClose} centered backdrop="static" fullscreen>
            <Modal.Header className='bg-warning' closeButton>
                <Modal.Title><h6><i className="bi bi-info-circle-fill"></i> {title}</h6></Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-light'>
                {description}
            </Modal.Body>
        </Modal>
    )
}

export default HelpModal