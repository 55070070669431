import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import EntitiesService from '../services/EntitiesService'

import { ENTITY_SKILLS } from '../data/entities'

import { AlertMsg, AlertError, AlertLoading } from 'helpers/AlertHelper'

function EntitiesForm(props) {

    const [elementId, setElementId] = useState(props.elementId)
    const [action, setAction] = useState(props.action)
    const [name, setName] = useState('')
    const [linkCode, setLinkCode] = useState('')
    const [skills, setSkills] = useState([])
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [description, setDescription] = useState('')
    const [buttonTitle, setButtonTitle] = useState('')
    const [inputDisabled, setInputDisabled] = useState(false)
    const [formValid, setFormValid] = useState(false)

    const [showMsg, setShowMsg] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)

    const getData = async () => {
        await EntitiesService.getEntity(elementId).then(
            response => {
                setName(response.data.name || '')
                setLinkCode(response.data.linkCode || '')
                setSkills(response.data.skills || [])
                setPhone(response.data.phone || '')
                setEmail(response.data.email || '')
                setDescription(response.data.description || '')
                setIsLoaded(true)
            },
            error => {
                setError(error)
            })
    }

    useEffect(() => {

        if (elementId) {
            getData()
        }

        switch (action) {
            case 'edit':
                setButtonTitle(<FormattedMessage id="textSave" defaultMessage="Save" />)
                break
            case 'delete':
                setButtonTitle(<FormattedMessage id="textDelete" defaultMessage="Delete" />)
                setInputDisabled(true)
                break
            default:
                setButtonTitle(<FormattedMessage id="textAdd" defaultMessage="Add" />)

        }

    }, [])

    const onSubmit = () => {

        props.onSubmit()
    }

    const handleSelectSkillsChange = (e) => {
        setSkills([].slice.call(e.target.selectedOptions).map(item => item.value))
    }

    const handleInputChange = (e) => {

        const name = e.target.name
        const value = e.target.value
        setFieldValue(name, value)
    }

    const setFieldValue = (fieldName, value) => {

        switch (fieldName) {
            case 'name':
                setName(value)
                break
            case 'linkCode':
                setLinkCode(value.toUpperCase())
                break
            case 'phone':
                setPhone(value)
                break
            case 'email':
                setEmail(value)
                break;
            case 'description':
                setDescription(value)
                break
            default:
                break;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()
    
        const form = e.currentTarget
        if (!form.checkValidity()) {
            setFormValid(true)
            return
        }
    
        const entity = {
            name,
            linkCode,
            skills,
            phone,
            email,
            description,
        }
    
        try {
            let response
    
            if (elementId && action === "edit") {
                response = await EntitiesService.updateEntity(entity, elementId)
            } else if (action === "add") {
                response = await EntitiesService.addEntity(entity)
            } else if (action === "delete") {
                response = await EntitiesService.deleteEntity(elementId)
            }
    
            if (response) {
                setFormValid(false)
                onSubmit()
            }
        } catch (error) {
            setShowMsg({ text: error || "An error occurred", style: 'danger' })
            setShowAlert(true)
        } finally {
            setFormValid(true)
        }
    }

    if (error) {
        return (
            <AlertError error={error} />
        )
    } else if (elementId && !isLoaded) {
        return (
            <AlertLoading isLoaded={isLoaded} />
        )
    }

    return (
        <div>
            <Form noValidate validated={formValid} onSubmit={handleSubmit}>
                {elementId && (
                    <Form.Group className="mb-3" controlId="id">
                        <Form.Label>Id:</Form.Label>
                        <Form.Control
                            type="text"
                            name="id"
                            value={elementId}
                            disabled={"disabled"}
                        />
                    </Form.Group>
                )}

                <Form.Group className="mb-3" controlId="name">
                    <Form.Label><FormattedMessage id="entitiesName" defaultMessage="Name" />:</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={name}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="entitiesNameInvalid" defaultMessage="Provide an entity name" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="linkCode">
                    <Form.Label><FormattedMessage id="entitiesLinkCode" defaultMessage="Link code" />:</Form.Label>
                    <Form.Control
                        type="text"
                        name="linkCode"
                        value={linkCode}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="entitiesLinkCodeInvalid" defaultMessage="Provide a link code to link the users to this entity" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="skills">
                    <Form.Label><FormattedMessage id="entitiesSkills" defaultMessage="Skills" />:</Form.Label>
                    <Form.Select
                        name="skills"
                        value={skills}
                        onChange={handleSelectSkillsChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                        multiple="multiple"
                    >
                        {ENTITY_SKILLS.map(skill =>
                            <option value={skill} key={skill}>{skill}</option>
                        )}
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="entitiesSkillsInvalid" defaultMessage="Select at least one skill" />
                    </Form.Control.Feedback>
                </Form.Group>


                <Form.Group className="mb-3" controlId="phone">
                    <Form.Label><FormattedMessage id="entitiesPhoneNumber" defaultMessage="Phone Number" />:</Form.Label>
                    <Form.Control
                        type="text"
                        name="phone"
                        value={phone}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="entitiesPhoneNumberInvalid" defaultMessage="Provide a phone number" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="email">
                    <Form.Label><FormattedMessage id="entitiesEmail" defaultMessage="E-mail" />:</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                        pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="entitiesEmailInvalid" defaultMessage="Provide an Email address" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="description">
                    <Form.Label><FormattedMessage id="entitiesDescription" defaultMessage="Description" />:</Form.Label>
                    <Form.Control
                        name="description"
                        value={description}
                        as="textarea"
                        disabled={inputDisabled && ("disabled")}
                        rows={3}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <AlertMsg message={showMsg.text} variant={showMsg.style} dismissible onCloseHandler={() => setShowAlert(false)} show={showAlert} />

                <div className="d-grid gap-2">
                    {action === "add" && (
                        <Button
                            className="btn btn-dark"
                            type="submit"
                        >
                            <i className="bi bi-plus-circle"></i> {buttonTitle}
                        </Button>
                    )}

                    {action === "edit" && (
                        <Button
                            className="btn btn-primary"
                            type="submit"
                        >
                            <i className="bi bi-save2"></i> {buttonTitle}
                        </Button>
                    )}

                    {action === "delete" && (
                        <Button
                            className="btn btn-danger"
                            type="submit"
                        >
                            <i className="bi bi-trash"></i> {buttonTitle}
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    )

}

export default EntitiesForm