import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class MissionsService {

  // Get missions list
  getMissions = async (pageNum, search = '', limit = false) => {
    return await axios.get(API_URL + '/missions?page=' + pageNum + '&search=' + search + '&limit=' + limit, { headers: authHeader() })
  }

  // Add new mission
  addMission = async (mission) => {
    return await axios.post(API_URL + '/missions', mission, { headers: authHeader() })
  }

  // Update mission data
  updateMission = async (mission, id) => {
    return await axios.patch(API_URL + '/missions/' + id, mission, { headers: authHeader() })
  }

  // Get mission data
  getMission = async (id, populate = false) => {
    return await axios.get(API_URL + '/missions/' + id + '?populate=' + populate, { headers: authHeader() })
  }

  // Delete mission
  deleteMission = async (id) => {
    return await axios.delete(API_URL + '/missions/' + id, { headers: authHeader() })
  }

  // Get missions list from group :id filtering by mission, user and search query 
  getGroupMissions = async ({ groupId, missionId = false, userId = false, pageNum = 1, search = '', timestamp = false, limit = false, lonbl = false, latbl = false, lonur = false, latur = false }) => {
    let url = API_URL + '/groups/' + groupId + '/missions?'

    if (missionId !== false) {
      url += 'missionId=' + missionId + '&'
    }
    if (userId !== false) {
      url += 'userId=' + userId + '&'
    }
    if (timestamp !== false) {
      url += 'timestamp=' + timestamp + '&'
    }
    if (pageNum !== false) {
      url += 'page=' + pageNum + '&'
    }
    if (search !== false) {
      url += 'search=' + search + '&'
    }
    if (limit !== false && !lonbl) {
      url += 'limit=' + limit + '&'
    }
    if (lonbl !== false) {
      url += 'lonbl=' + lonbl + '&'
    }
    if (latbl !== false) {
      url += 'latbl=' + latbl + '&'
    }
    if (lonur !== false) {
      url += 'lonur=' + lonur + '&'
    }
    if (latur !== false) {
      url += 'latur=' + latur + '&'
    }

    return await axios.get(url, { headers: authHeader() });
  }

}

export default new MissionsService()