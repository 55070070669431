import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'

import LayersService from '../services/LayersService'

import { LAYER_TYPES } from '../config/layers'

import { AlertMsg, AlertError, AlertLoading } from 'helpers/AlertHelper'

function LayersForm(props) {

    const [elementId, setElementId] = useState(props.elementId)
    const [action, setAction] = useState(props.action)

    const [type, setType] = useState('')
    const [active, setActive] = useState(false)
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const [wmsLayers, setWmsLayers] = useState('')
    const [wmsFormat, setWmsFormat] = useState('image/png')
    const [featuresColor, setFeaturesColor] = useState('')
    const [transparent, setTransparent] = useState(false)
    const [minZoom, setMinZoom] = useState(10)
    const [maxZoom, setMaxZoom] = useState(19)
    const [featureIdInProperty, setFeatureIdInProperty] = useState('')
    const [featureNameInProperty, setFeatureNameInProperty] = useState('')
    const [propertiesSchema, setPropertiesSchema] = useState([])
    const [attribution, setAttribution] = useState('')
    const [description, setDescription] = useState('')
    const [crsProperties, setCrsProperties] = useState({})
    const [layerFile, setLayerFile] = useState([])

    const [uploadProgress, setUploadProgress] = useState(0)
    const [buttonTitle, setButtonTitle] = useState('')
    const [inputDisabled, setInputDisabled] = useState(false)
    const [formValid, setFormValid] = useState(false)

    const [showMsg, setShowMsg] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)

    const getData = async () => {
        await LayersService.getLayer(elementId).then(
            response => {
                setType(response.data.type || '')
                setActive(response.data.active || false)
                setName(response.data.name || '')
                setUrl(response.data.url || '')
                setWmsLayers(response.data.wmsLayers || '')
                setWmsFormat(response.data.wmsFormat || 'image/png')
                setTransparent(response.data.transparent || false)
                setFeaturesColor(response.data.featuresColor || '#0000ff')
                setMinZoom(response.data.minZoom || 10)
                setMaxZoom(response.data.maxZoom || 19)
                setAttribution(response.data.attribution || '')
                setFeatureIdInProperty(response.data.featureIdInProperty || '')
                setFeatureNameInProperty(response.data.featureNameInProperty || '')
                setPropertiesSchema(response.data.propertiesSchema || [])
                setDescription(response.data.description || '')
                if (response.data.crs && response.data.crs.properties) {
                    setCrsProperties(response.data.crs.properties || {})
                } else {
                    setCrsProperties({})
                }
                setIsLoaded(true)
            },
            error => {
                setError(error)
            })
    }

    useEffect(() => {

        if (elementId) {
            getData()
        }

        switch (action) {
            case 'edit':
                setButtonTitle(<FormattedMessage id="textSave" defaultMessage="Save" />)
                break
            case 'delete':
                setButtonTitle(<FormattedMessage id="textDelete" defaultMessage="Delete" />)
                setInputDisabled(true)
                break
            default:
                setButtonTitle(<FormattedMessage id="textAdd" defaultMessage="Add" />)
        }

    }, [])

    const onSubmit = () => {
        props.onSubmit()
    }

    const handleActiveCheckBox = (e) => {
        if (e.target.checked) {
            setActive(true)
        } else {
            setActive(false)
        }
    }

    const handleTransparentCheckBox = (e) => {
        if (e.target.checked) {
            setTransparent(true)
        } else {
            setTransparent(false)
        }
    }

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFieldValue(name, value)
    }

    const setFieldValue = (fieldName, value) => {

        switch (fieldName) {
            case 'type':
                setType(value)
                break
            case 'name':
                setName(value)
                break
            case 'url':
                setUrl(value)
                break
            case 'wmsLayers':
                setWmsLayers(value)
                break
            case 'wmsFormat':
                setWmsFormat(value)
                break
            case 'featuresColor':
                setFeaturesColor(value)
                break
            case 'minZoom':
                setMinZoom(value)
                break
            case 'maxZoom':
                setMaxZoom(value)
                break
            case 'featureIdInProperty':
                setFeatureIdInProperty(value)
                break
            case 'featureNameInProperty':
                setFeatureNameInProperty(value)
                break
            case 'attribution':
                setAttribution(value)
                break
            case 'description':
                setDescription(value)
                break
            default:
                break;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget
        if (!form.checkValidity()) {
            setFormValid(true)
            return
        }

        const layer = {
            active,
            type,
            name,
            url,
            wmsLayers,
            wmsFormat,
            featuresColor,
            transparent,
            minZoom,
            maxZoom,
            attribution,
            description,
        }

        try {
            if (elementId && action === "edit") {
                layer.featureIdInProperty = featureIdInProperty
                layer.featureNameInProperty = featureNameInProperty
                await LayersService.updateLayer(layer, elementId)

            } else if (action === "add") {
                const formData = new FormData()
                formData.append("file", layerFile[0])

                if (type === 'DRAWED') {
                    layer.featureIdInProperty = 'ref'
                    layer.featureNameInProperty = 'name'
                    layer.propertiesSchema = ['radius', 'ref', 'name', 'description']
                }

                const addLayerResponse = await LayersService.addLayer(layer)

                if (type === 'GEOJSON') {
                    await LayersService.uploadFile(addLayerResponse.data._id, formData, onUploadProgress)
                }

            } else if (action === "delete") {
                await LayersService.deleteLayer(elementId)
            }

            setFormValid(false)
            onSubmit()
        } catch (error) {
            setShowMsg({ text: error || "An error occurred", style: 'danger' })
            setShowAlert(true)
        } finally {
            setFormValid(true)
        }
    }

    const onUploadProgress = (e) => {
        const percentage = Math.round((100 * e.loaded) / e.total)
        setUploadProgress(percentage)
    }

    if (error) {
        return (
            <AlertError error={error} />
        )
    } else if (elementId && !isLoaded) {
        return (
            <AlertLoading isLoaded={isLoaded} />
        )
    }

    return (
        <div>
            <Form noValidate validated={formValid} onSubmit={handleSubmit} encType="multipart/form-data">
                {elementId && (
                    <Form.Group className="mb-3" controlId="id">
                        <Form.Label>Id:</Form.Label>
                        <Form.Control
                            type="text"
                            name="id"
                            value={elementId}
                            disabled={"disabled"}
                        />
                    </Form.Group>
                )}

                <Form.Group className="mb-3" controlId="type">
                    <Form.Label><FormattedMessage id="layersType" defaultMessage="Type" />:</Form.Label>
                    <Form.Select
                        name="type"
                        value={type}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    >
                        <option value="">Select layer type</option>
                        {LAYER_TYPES.map(layerType =>
                            <option value={layerType} key={layerType}>{layerType}</option>
                        )}
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="layersTypeInvalid" defaultMessage="Select layer type" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="active">
                    <Form.Label><FormattedMessage id="layersActive" defaultMessage="Active" />:</Form.Label>
                    <Form.Check
                        inline
                        label={active ? 'Yes' : 'No'}
                        name="active"
                        checked={active}
                        type="switch"
                        onChange={handleActiveCheckBox}
                        disabled={inputDisabled && ("disabled")}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="name">
                    <Form.Label><FormattedMessage id="layersName" defaultMessage="Name" />:</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={name}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="layersNameInvalid" defaultMessage="Provide a layer name" />
                    </Form.Control.Feedback>
                </Form.Group>

                {(type === 'BASE' || type === 'WMS') &&
                    <Form.Group className="mb-3" controlId="url">
                        <Form.Label><FormattedMessage id="layersUrl" defaultMessage="Url" />:</Form.Label>
                        <Form.Control
                            type="text"
                            name="url"
                            value={url}
                            onChange={handleInputChange}
                            disabled={inputDisabled && ("disabled")}
                            required="required"
                        />
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="layersUrlInvalid" defaultMessage="Provide a service layer url" />
                        </Form.Control.Feedback>
                    </Form.Group>
                }

                {type === 'WMS' &&
                    <Form.Group className="mb-3" controlId="wmsLayers">
                        <Form.Label><FormattedMessage id="layersWMSLayers" defaultMessage="WMS layers" />:</Form.Label>
                        <Form.Control
                            type="text"
                            name="wmsLayers"
                            value={wmsLayers}
                            placeholder='WMS layers name separated by ","'
                            onChange={handleInputChange}
                            disabled={inputDisabled && ("disabled")}
                            required="required"
                        />
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="layersWMSLayersInvalid" defaultMessage="Provide WMS layers name separated by ," />
                        </Form.Control.Feedback>
                    </Form.Group>
                }

                {type === 'WMS' &&
                    <Form.Group className="mb-3" controlId="wmsFormat">
                        <Form.Label><FormattedMessage id="layersWMSFormat" defaultMessage="WMS format" />:</Form.Label>
                        <Form.Control
                            type="text"
                            name="wmsFormat"
                            value={wmsFormat}
                            onChange={handleInputChange}
                            disabled={inputDisabled && ("disabled")}
                            required="required"
                        />
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="layersWMSFormatInvalid" defaultMessage="Provide WMS format" />
                        </Form.Control.Feedback>
                    </Form.Group>
                }

                {type === 'WMS' &&
                    <Form.Group className="mb-3" controlId="transparent">
                        <Form.Label><FormattedMessage id="layersWMSTransparent" defaultMessage="WMS Transparent" />:</Form.Label>
                        <Form.Check
                            inline
                            label={transparent ? 'Yes' : 'No'}
                            name="transparent"
                            checked={transparent}
                            type="switch"
                            onChange={handleTransparentCheckBox}
                            disabled={inputDisabled && ("disabled")}
                        />
                    </Form.Group>
                }

                {(type === 'GEOJSON' || type === 'DRAWED') &&
                    <Form.Group className="mb-3" controlId="featuresColor">
                        <Form.Label><FormattedMessage id="layersFeaturesColor" defaultMessage="Features color" />:</Form.Label>
                        <Form.Control
                            type="color"
                            name="featuresColor"
                            value={featuresColor}
                            placeholder='Features color'
                            onChange={handleInputChange}
                            disabled={inputDisabled && ("disabled")}
                            required="required"
                        />
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="layersFeaturesColorInvalid" defaultMessage="Provide a color for the features" />
                        </Form.Control.Feedback>
                    </Form.Group>
                }

                <Form.Group className="mb-3" controlId="minZoom">
                    <Form.Label><FormattedMessage id="layersMinZoom" defaultMessage="Min. zoom" />: {minZoom}</Form.Label>
                    <Form.Control
                        type="range"
                        min={1}
                        max={19}
                        name="minZoom"
                        value={minZoom}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="maxZoom">
                    <Form.Label><FormattedMessage id="layersMaxZoom" defaultMessage="Max. zoom" />: {maxZoom}</Form.Label>
                    <Form.Control
                        type="range"
                        min={1}
                        max={19}
                        name="maxZoom"
                        value={maxZoom}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="attribution">
                    <Form.Label><FormattedMessage id="layersCopyAttr" defaultMessage="© Attribution" />:</Form.Label>
                    <Form.Control
                        type="text"
                        name="attribution"
                        value={attribution}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="layersCopyAttrInvalid" defaultMessage="Provide Copyrights attributions of the layer" />
                    </Form.Control.Feedback>
                </Form.Group>

                {action !== "add" && type === 'GEOJSON' && (
                    <Form.Group className="mb-3" controlId="crsProperties">
                        <Form.Label><FormattedMessage id="layersCRSProperties" defaultMessage="CRS properties" />:</Form.Label>
                        <Form.Control
                            type="text"
                            name="crsProperties"
                            value={JSON.stringify(crsProperties)}
                            disabled={"disabled"}
                        />
                    </Form.Group>
                )}

                {action !== "add" && type === 'GEOJSON' && (
                    <Form.Group className="mb-3" controlId="featureIdInProperty">
                        <Form.Label><FormattedMessage id="layersIdField" defaultMessage="Id field" />:</Form.Label>
                        <Form.Select
                            name="featureIdInProperty"
                            value={featureIdInProperty}
                            onChange={handleInputChange}
                            disabled={inputDisabled && ("disabled")}
                            required="required"
                        >
                            <option value=""><FormattedMessage id="textSelect" defaultMessage="Select" /></option>
                            {propertiesSchema.map(propertySchema =>
                                <option value={propertySchema} key={propertySchema}>{propertySchema}</option>
                            )}
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="layersIdFieldInvalid" defaultMessage="Select the Id property field" />
                        </Form.Control.Feedback>
                    </Form.Group>
                )}

                {action !== "add" && type === 'GEOJSON' && (
                    <Form.Group className="mb-3" controlId="featureNameInProperty">
                        <Form.Label><FormattedMessage id="layersNameField" defaultMessage="Name field" />:</Form.Label>
                        <Form.Select
                            name="featureNameInProperty"
                            value={featureNameInProperty}
                            onChange={handleInputChange}
                            disabled={inputDisabled && ("disabled")}
                            required="required"
                        >
                            <option value=""><FormattedMessage id="textSelect" defaultMessage="Select" /></option>
                            {propertiesSchema.map(propertySchema =>
                                <option value={propertySchema} key={propertySchema}>{propertySchema}</option>
                            )}
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="layersNameFieldInvalid" defaultMessage="Select the Name property field" />
                        </Form.Control.Feedback>
                    </Form.Group>
                )}

                <Form.Group className="mb-3" controlId="description">
                    <Form.Label><FormattedMessage id="layersDescription" defaultMessage="Description" />:</Form.Label>
                    <Form.Control
                        name="description"
                        value={description}
                        as="textarea"
                        disabled={inputDisabled && ("disabled")}
                        rows={3}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                {action === "add" && type === 'GEOJSON' && (
                    <Form.Group className="mb-3" controlId="layerFile">
                        <Form.Label><FormattedMessage id="layersGEOJSONFile" defaultMessage="GEOJSON file" />:</Form.Label>
                        <Form.Control
                            type="file"
                            name="layerFile"
                            accept=".json,.geojson"
                            onChange={e => setLayerFile(e.target.files)}
                            disabled={inputDisabled && ("disabled")}
                            required="required"
                        />
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="layersGEOJSONFileInvalid" defaultMessage="Select a layer file in .json or .geojson format" />
                        </Form.Control.Feedback>
                        <ProgressBar striped variant="info" now={uploadProgress} label={`${uploadProgress}%`} />
                    </Form.Group>
                )
                }

                <AlertMsg message={showMsg.text} variant={showMsg.style} dismissible onCloseHandler={() => setShowAlert(false)} show={showAlert} />

                <div className="d-grid gap-2">
                    {action === "add" && (
                        <Button
                            className="btn btn-dark"
                            type="submit"
                        >
                            <i className="bi bi-plus-circle"></i> {buttonTitle}
                        </Button>
                    )}

                    {action === "edit" && (
                        <Button
                            className="btn btn-primary"
                            type="submit"
                        >
                            <i className="bi bi-save2"></i> {buttonTitle}
                        </Button>
                    )}

                    {action === "delete" && (
                        <Button
                            className="btn btn-danger"
                            type="submit"
                        >
                            <i className="bi bi-trash"></i> {buttonTitle}
                        </Button>
                    )}
                </div>
            </Form >
        </div >
    )

}

export default LayersForm