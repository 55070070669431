import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import EventsService from '../services/EventsService'

import { HAZARD_TYPES } from '../data/events'

import { AlertMsg, AlertError, AlertLoading } from 'helpers/AlertHelper'

function EventsForm(props) {

    const [elementId, setElementId] = useState(props.elementId)
    const [action, setAction] = useState(props.action)
    const [name, setName] = useState('')
    const [hazardType, setHazardType] = useState('')
    const [active, setActive] = useState(false)
    const [description, setDescription] = useState('')
    const [buttonTitle, setButtonTitle] = useState('')
    const [inputDisabled, setInputDisabled] = useState(false)
    const [formValid, setFormValid] = useState(false)

    const [showMsg, setShowMsg] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)

    const getData = async () => {
        await EventsService.getEvent(elementId).then(
            response => {
                setActive(response.data.active || false)
                setName(response.data.name || '')
                setHazardType(response.data.hazardType || '')
                setDescription(response.data.description || '')
                setIsLoaded(true)
            },
            error => {
                setError(error)
            })
    }

    useEffect(() => {

        if (elementId) {
            getData()
        }

        switch (action) {
            case 'edit':
                setButtonTitle(<FormattedMessage id="textSave" defaultMessage="Save" />)
                break
            case 'delete':
                setButtonTitle(<FormattedMessage id="textDelete" defaultMessage="Delete" />)
                setInputDisabled(true)
                break
            default:
                setButtonTitle(<FormattedMessage id="textAdd" defaultMessage="Add" />)
        }


    }, [])

    const onSubmit = () => {
        props.onSubmit()
    }

    const handleActiveCheckBox = (e) => {
        if (e.target.checked) {
            setActive(true)
        } else {
            setActive(false)
        }
    }

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFieldValue(name, value)
    }

    const setFieldValue = (fieldName, value) => {
        switch (fieldName) {
            case 'name':
                setName(value)
                break
            case 'hazardType':
                setHazardType(value)
                break
            case 'description':
                setDescription(value)
                break
            default:
                break;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget
        if (form.checkValidity()) {
            const event = {
                name: name,
                hazardType: hazardType,
                active: active,
                description: description
            }

            if (elementId && action === "edit") {
                EventsService.updateEvent(event, elementId).then(
                    response => {
                        setFormValid(false)
                        onSubmit()
                    },
                    error => {
                        setShowMsg({ text: error, style: 'danger' })
                        setShowAlert(true)
                    })
            } else if (action === "add") {
                EventsService.addEvent(event).then(
                    response => {
                        setFormValid(false)
                        onSubmit()
                    },
                    error => {
                        setShowMsg({ text: error, style: 'danger' })
                        setShowAlert(true)
                    })
            } else if (action === "delete") {
                EventsService.deleteEvent(elementId).then(
                    response => {
                        setFormValid(false)
                        onSubmit()
                    },
                    error => {
                        setShowMsg({ text: error, style: 'danger' })
                        setShowAlert(true)
                    })
            }
        }

        setFormValid(true)
    }

    if (error) {
        return (
            <AlertError error={error} />
        )
    } else if (elementId && !isLoaded) {
        return (
            <AlertLoading isLoaded={isLoaded} />
        )
    }

    return (
        <div>
            <Form noValidate validated={formValid} onSubmit={handleSubmit}>
                {elementId && (
                    <Form.Group className="mb-3" controlId="id">
                        <Form.Label>Id:</Form.Label>
                        <Form.Control
                            type="text"
                            name="id"
                            value={elementId}
                            disabled={"disabled"}
                        />
                    </Form.Group>
                )}

                <Form.Group className="mb-3" controlId="name">
                    <Form.Label><FormattedMessage id="eventsName" defaultMessage="Name" />:</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={name}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="eventsNameInvalid" defaultMessage="Provide an event name" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="hazardType">
                    <Form.Label><FormattedMessage id="eventsHazardType" defaultMessage="Hazard type" />:</Form.Label>
                    <Form.Select
                        name="hazardType"
                        value={hazardType}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    >
                        <option value="" key="select-layer-0"><FormattedMessage id="textSelect" defaultMessage="Select" /></option>
                        {HAZARD_TYPES.map(hazardType =>
                            <option value={hazardType} key={hazardType}>{hazardType}</option>
                        )}
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="eventsHazardTypeInvalid" defaultMessage="Select an hazard type" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="active">
                    <Form.Label><FormattedMessage id="eventsActive" defaultMessage="Active" />:</Form.Label>
                    <Form.Check
                        inline
                        label={active ? 'Yes' : 'No'}
                        name="active"
                        checked={active}
                        type="switch"
                        onChange={handleActiveCheckBox}
                        disabled={inputDisabled && ("disabled")}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="description">
                    <Form.Label><FormattedMessage id="eventsDescription" defaultMessage="Description" />:</Form.Label>
                    <Form.Control
                        name="description"
                        value={description}
                        as="textarea"
                        disabled={inputDisabled && ("disabled")}
                        rows={3}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <AlertMsg message={showMsg.text} variant={showMsg.style} dismissible onCloseHandler={() => setShowAlert(false)} show={showAlert} />

                <div className="d-grid gap-2">
                    {action === "add" && (
                        <Button
                            className="btn btn-dark"
                            type="submit"
                        >
                            <i className="bi bi-plus-circle"></i> {buttonTitle}
                        </Button>
                    )}

                    {action === "edit" && (
                        <Button
                            className="btn btn-primary"
                            type="submit"
                        >
                            <i className="bi bi-save2"></i> {buttonTitle}
                        </Button>
                    )}

                    {action === "delete" && (
                        <Button
                            className="btn btn-danger"
                            type="submit"
                        >
                            <i className="bi bi-trash"></i> {buttonTitle}
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    )

}

export default EventsForm