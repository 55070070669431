import React, { useEffect, useRef } from "react"
import { Row, Col } from 'react-bootstrap'

import moment from 'moment'

import MessageCard from 'components/card/MessageCard'

const MessagesCard = ({ messages, rightUserId, chatRoomsRef }) => {

    const messagesRef = useRef([])

    useEffect(() => {
        messagesRef.current = messages
    }, [messages])

    const updateMessagesMoment = (messagesCurrent) => {
        if (!messagesCurrent) return
        const updatedData = messagesCurrent.map(message => ({
            ...message,
            moment: moment(message.createdAt).fromNow()
        }))
        //setMessages(updatedData)
    }
    
    useEffect(() => {
        const intervalId = setInterval(() => {
            updateMessagesMoment(messagesRef.current)
        }, 60000) // every 60 seconds

        return () => clearInterval(intervalId)
    }, [])

    return (
        <Row xs={1} md={1} className="g-3">
            {messages && messages?.map((message) => (
                <Col xs={12} md={12} key={"col-message-" + message._id} className="p-0 d-flex">
                    <MessageCard
                        sender={chatRoomsRef.current.allUsers[message.senderId]}
                        content={message.content}
                        recipients={message.recipients}
                        date={message.moment ? message.moment : moment(message.createdAt).fromNow()}
                        halign={message.senderId === rightUserId ? 'ms-auto' : 'me-auto'}
                    />
                </Col>
            ))}
        </Row>
    )
}

export default MessagesCard