import { useEffect, useState } from "react"
import { createRoot } from 'react-dom/client'
import { useIntl, FormattedMessage } from 'react-intl'
import { useMap } from "react-leaflet"

import L from "leaflet"

import TimestampSlider from "components/sliders/TimestampSlider"

const MapSlider = ({ callBack }) => {
    const map = useMap()

    const [sliderValue, setSliderValue] = useState(0)
    const handleSliderValue = (value) => {
        setSliderValue(value)
        callBack(value)
    }
    
    useEffect(() => {
    }, [sliderValue])

    useEffect(() => {
        if (!map) return
        // create custom button
        const sliderContainer = L.DomUtil.create('div', 'leaflet-control leaflet-bar')

        sliderContainer.style.position = 'absolute';
        sliderContainer.style.bottom = '20px'; // Ajusta la posición bottom según tu necesidad
        sliderContainer.style.left = '50%'; // Centrar horizontalmente
        sliderContainer.style.transform = 'translateX(-50%)'; // Centrar horizontalmente
        sliderContainer.style.display = 'flex'; // Utilizar flexbox para centrar verticalmente
        sliderContainer.style.alignItems = 'center'; // Centrar verticalmente
        sliderContainer.style.width = '400px'; // Ajusta el ancho según tu necesidad
        sliderContainer.style.height = '30px'; // Ajusta la altura según tu necesidad
        sliderContainer.style.backgroundColor = '#e8e8e8'

        map.getContainer().appendChild(sliderContainer);

        const root = createRoot(sliderContainer);
        root.render(<TimestampSlider onRangeChange={handleSliderValue} value={sliderValue} disabled={false} />)

    }, [map])

    return
}

export default MapSlider
