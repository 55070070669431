import React, { useEffect, useState, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'

import TableList from "../components/tables/TableList"

import ConfigService from 'services/ConfigService'
import UsersService from '../services/UsersService'
import UsersForm from './UsersForm'

import { AlertError, AlertLoading } from 'helpers/AlertHelper'

const Users = (props) => {
  const intl = useIntl()

  const fronConfig = ConfigService.getFrontConfig()

  const [keys, setKeys] = useState({})
  const [users, setUsers] = useState([])
  const [pagination, setPagination] = useState([])
  const [currPage, setCurrPage] = useState(1)
  const [search, setSearch] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(false)

  const goToPage = (pageNum) => {
    setCurrPage(pageNum)
  }

  const refreshData = () => {
    setIsLoaded(false)
    getData()
  }

  const getData = async () => {
    await UsersService.getUsers(currPage, search, fronConfig.app.itemsListLimit).then(
      response => {
        const usersData = response.data.users
        usersData.map((user, num) => {
          if (user.entity) {
            usersData[num].entityName = user.entity.name
          }
          return usersData[num]
        })
        setKeys({
          name: <FormattedMessage id="usersName" defaultMessage="Name" />,
          entityName: <FormattedMessage id="usersEntityName" defaultMessage="Entity name" />,
          phone: <FormattedMessage id="usersPhoneNumber" defaultMessage="Phone number" />,
          email: <FormattedMessage id="usersEmail" defaultMessage="E-mail" />,
          roles: <FormattedMessage id="usersRoles" defaultMessage="Roles" />,
          lastLoginAt: <FormattedMessage id="usersLastLogin" defaultMessage="Last login" />,
          createdAt: <FormattedMessage id="usersCreatedAt" defaultMessage="Created at" />
        })
        setUsers(usersData)
        setPagination(response.data.pagination)
        setIsLoaded(true)
      },
      error => {
        setError(error)
      }
    )
  }

  const prevSearch = useRef()
  useEffect(() => {
    if (!prevSearch.current || prevSearch.current !== search) {
      prevSearch.current = search
      setIsLoaded(false)
    }
    setCurrPage(1)
    getData()
  }, [search])

  const prevCurrentPage = useRef()
  useEffect(() => {
    if (!prevCurrentPage.current || prevCurrentPage.current !== currPage) {
      prevCurrentPage.current = currPage
      setIsLoaded(false)
    }
    getData()
  }, [currPage])

  if (error) {
    return (
      <AlertError error={error} />
    )
  } else if (!isLoaded) {
    return (
      <AlertLoading isLoaded={isLoaded} />
    )
  }

  return (
    <TableList
      caption={intl.formatMessage({ id: 'users', defaultMessage: 'Users' })}
      icon="bi-people-fill"
      data={users}
      keys={keys}
      pagination={true}
      paginationData={pagination}
      goToPageFunction={goToPage}
      refreshData={refreshData}
      setSearchValue={setSearch}
      searchValue={search}
      titleAdd={intl.formatMessage({ id: 'usersAdd', defaultMessage: 'Add user' })}
      titleEdit={intl.formatMessage({ id: 'usersEdit', defaultMessage: 'Edit user' })}
      titleDelete={intl.formatMessage({ id: 'usersDelete', defaultMessage: 'Delete user' })}
      ElementForm={UsersForm}
    />
  )
}

export default Users