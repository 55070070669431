import React, { useEffect, useState, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'

import TableList from "../components/tables/TableList"

import ConfigService from 'services/ConfigService'
import LayersService from '../services/LayersService'
import LayersForm from './LayersForm'

import { AlertError, AlertLoading } from 'helpers/AlertHelper'

const Layers = (props) => {
  const intl = useIntl()

  const fronConfig = ConfigService.getFrontConfig()

  const [keys, setKeys] = useState({})
  const [layers, setLayers] = useState([])
  const [pagination, setPagination] = useState([])
  const [currPage, setCurrPage] = useState(1)
  const [search, setSearch] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(false)

  const goToPage = (pageNum) => {
    setCurrPage(pageNum)
  }

  const refreshData = () => {
    setIsLoaded(false)
    getData()
  }

  const getData = async () => {
    await LayersService.getLayers({ pageNum: currPage, search: search, limit: fronConfig.app.itemsListLimit }).then(
      response => {
        setKeys({
          type: <FormattedMessage id="layersType" defaultMessage="Type" />,
          name: <FormattedMessage id="layersName" defaultMessage="Name" />,
          active: <FormattedMessage id="layersActive" defaultMessage="Active" />,
          featureIdInProperty: <FormattedMessage id="layersIdField" defaultMessage="Id field" />,
          featureNameInProperty: <FormattedMessage id="layersNameField" defaultMessage="Name field" />,
          createdAt: <FormattedMessage id="layersCreatedAt" defaultMessage="Created at" />
        })
        setLayers(response.data.layers)
        setPagination(response.data.pagination)
        setIsLoaded(true)
      },
      error => {
        setError(error)
      }
    )

  }

  const prevSearch = useRef()
  useEffect(() => {
    if (!prevSearch.current || prevSearch.current !== search) {
      prevSearch.current = search
      setIsLoaded(false)
    }
    setCurrPage(1)
    getData()
  }, [search])

  const prevCurrentPage = useRef()
  useEffect(() => {
    if (!prevCurrentPage.current || prevCurrentPage.current !== currPage) {
      prevCurrentPage.current = currPage
      setIsLoaded(false)
    }
    getData()
  }, [currPage])

  if (error) {
    return (
      <AlertError error={error} />
    )
  } else if (!isLoaded) {
    return (
      <AlertLoading isLoaded={isLoaded} />
    )
  }

  return (
    <TableList
      caption={intl.formatMessage({ id: 'layers', defaultMessage: 'Layers' })}
      icon="bi-layers-fill"
      data={layers}
      keys={keys}
      pagination={true}
      paginationData={pagination}
      goToPageFunction={goToPage}
      refreshData={refreshData}
      setSearchValue={setSearch}
      searchValue={search}
      titleAdd={intl.formatMessage({ id: 'layersAdd', defaultMessage: 'Add layer' })}
      titleEdit={intl.formatMessage({ id: 'layersEdit', defaultMessage: 'Edit layer' })}
      titleDelete={intl.formatMessage({ id: 'layersDelete', defaultMessage: 'Delete layer' })}
      ElementForm={LayersForm}
    />
  )
}

export default Layers