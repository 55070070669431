import { useState, useEffect, useRef } from "react"
import { useIntl, FormattedMessage } from 'react-intl'
import Accordion from 'react-bootstrap/Accordion'
import ListGroup from 'react-bootstrap/ListGroup'
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'

import moment from 'moment'

import ChatRoomServices from 'socket/ChatRoomServices.socket'

import ChatModal from './ChatModal'

const ChatRoomsList = ({ selectedGroupId, setSelectedGroupId, groupsList, authUserId, socket, socketData, handleChatMessagesNum, handleToasts, sendButtonDisabled }) => {

    const chatRoomServices = new ChatRoomServices({ authUserId: authUserId, groupId: selectedGroupId, socketService: socket })

    const [chatModalShow, setChatModalShow] = useState(false)
    const [chatRooms, setChatRooms] = useState({
        list: null,
        allUsers: null,
        selected: {
            chatRoom: null,
            messages: null,
            unreaded: 0
        }
    })

    const chatRoomsRef = useRef(chatRooms)

    const handleSelectGroup = (val) => {
        setSelectedGroupId(val)
    }

    const handleChatRoomSelection = (chatRoom) => {
        setChatRooms((prevChatRooms) => ({
            ...prevChatRooms,
            selected: {
                ...prevChatRooms.selected,
                chatRoom: chatRoom,
                messages: [],
                unreaded: 0
            }
        }))
        chatRoomServices.getChatRoomMessages(chatRoom._id)
        setChatModalShow(true)
    }

    const handleAddChatRoomBtn = () => {
    }

    const handleEditChatRoomBtn = (chatRoom) => {
    }

    const handleDeleteChatRoomBtn = (chatRoom) => {
    }

    function getAllUsersFromGroups(groups) {
        const allUsers = {}

        groups.forEach(group => {
            const usersData = group.usersData

            if (usersData) {
                usersData.forEach(user => {
                    const userId = user._id
                    allUsers[userId] = user
                })
            }
        })
        return allUsers
    }

    function getChatRoomFromChatRoomsList(chatRoomId) {
        return chatRooms.list[selectedGroupId]?.items.find(item => item._id === chatRoomId)
    }

    const groupByChatRoomGroupId = (chatRoomsList) => {
        const orderedChatRooms = chatRoomsList.reduce((result, item) => {
            const groupId = item.groupId

            if (!result[groupId]) {
                result[groupId] = {
                    groupId: groupId,
                    items: [item]
                }
            } else {
                result[groupId].items.push(item)
            }

            return result
        }, {})

        handleChatMessagesNum(getAllUnreadedMessages(authUserId, orderedChatRooms))
        return orderedChatRooms

    }

    function getAllUnreadedMessages(userId, data) {
        let totalUnread = 0
        for (const groupId in data) {
            if (data.hasOwnProperty(groupId)) {
                const group = data[groupId]
                for (const item of group.items) {
                    const user = item.participants.users.find(user => user.userId === userId)
                    if (user) {
                        totalUnread += user.unreaded
                    }
                }
            }
        }
        return totalUnread
    }

    const getChatRoomsTotalUnread = (userId, data) => {
        if (!data) return 0
        let totalUnread = 0
        data.map((item) => {
            const users = item.participants?.users || []
            const user = users.find(u => u.userId === userId)
            if (user) {
                totalUnread += user.unreaded || 0
            }
        })
        return totalUnread
    }

    function getChatRoomUnreadedMessages(chatRoom, userId) {
        if (chatRoom) {
            const user = chatRoom.participants.users.find(user => user.userId === userId)
            if (user) {
                const unreaded = user.unreaded
                return unreaded
            }
        }
        return 0
    }

    const getChatRooms = () => {
        chatRoomServices.getChatRooms()
    }

    /* Chatrooms */
    useEffect(() => {
        if (!chatRoomServices) return
        getChatRooms()

    }, [])

    useEffect(() => {
        if (!socketData) return

        const sData = socketData
        switch (sData.request?.event) {
            case 'userPosition':
                // Do nothing
                break

            case 'joinChatRoom':
                // Do nothing
                break

            case 'addChatRoom':
                chatRoomServices.joinChatRoom({ chatRoomId: sData.data.chatRoom._id })
                chatRoomServices.getChatRooms()
                break

            case 'updateChatRoom':
                chatRoomServices.getChatRooms()
                setChatRooms({
                    ...chatRooms,
                    selected: {
                        chatRoom: null,
                        messages: null,
                        unreaded: 0
                    }
                })
                break

            case 'deleteChatRoom':
                chatRoomServices.getChatRooms()
                setChatRooms({
                    ...chatRooms,
                    selected: {
                        chatRoom: null,
                        messages: null,
                        unreaded: 0
                    }
                })
                break

            case 'getChatRooms':
                setChatRooms((prevChatRooms) => ({
                    ...prevChatRooms,
                    list: groupByChatRoomGroupId(sData.data),
                    allUsers: getAllUsersFromGroups(sData.data),
                }))
                break

            case 'getChatRoomMessages':
                chatRoomServices.getChatRooms()
                setChatRooms((prevChatRooms) => ({
                    ...prevChatRooms,
                    selected: {
                        ...prevChatRooms.selected,
                        chatRoom: getChatRoomFromChatRoomsList(sData.request.chatRoomId),
                        messages: sData.data
                    }
                }))
                //console.log('Chatroom mensajes recibidos:', sData.data)
                break

            case 'addChatRoomMessage':
                chatRoomServices.getChatRooms()
                if (sData.request.chatRoomId === chatRooms.selected.chatRoom?._id && chatModalShow) {
                    setChatRooms((prevChatRooms) => ({
                        ...prevChatRooms,
                        selected: {
                            ...prevChatRooms.selected,
                            messages: [...prevChatRooms.selected.messages, sData.data.message]
                        }
                    }))
                    //console.log('Chatroom mensaje recibido:', sData.data)
                } else {
                    handleToasts({
                        id: sData.data.message._id,
                        group: sData.data.chatRoom.group,
                        chatRoom: sData.data.chatRoom,
                        sender: sData.data.sender,
                        createdAt: sData.data.message.createdAt,
                        moment: moment(sData.data.message.createdAt).fromNow(),
                        content: sData.data.message.content
                    })
                }
                break

            default:
                break
        }
    }, [socketData])

    // Geolocation
    useEffect(() => {
        if (navigator.geolocation) {
            const geoOptions = {
                enableHighAccuracy: true, // Precisión moderada
                timeout: 10000, // Tiempo de espera de 10 segundos
                maximumAge: 60000, // Datos en caché válidos por 1 minuto
            }

            const successCallback = (position) => {
                //const { latitude, longitude } = position.coords
                //console.log("Position:", position)
                chatRoomServices.userPosition({
                    position: {
                        coords: {
                            accuracy: position.coords.accuracy,
                            altitude: position.coords.altitude,                   
                            altitudeAccuracy: position.coords.altitudeAccuracy,
                            heading: position.coords.heading,
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            speed: position.coords.speed
                        },
                        timestamp: position.timestamp
                    }
                })
            }

            const errorCallback = (error) => {
                console.error('Error getting location:', error.message)
            }

            navigator.geolocation.getCurrentPosition(successCallback, errorCallback, geoOptions)

            const watchId = navigator.geolocation.watchPosition(successCallback, errorCallback, geoOptions)

            return () => {
                navigator.geolocation.clearWatch(watchId)
            }
        } else {
            console.error('Web browser not support this action')
        }
    }, [])

    useEffect(() => {
        if (!selectedGroupId) return
    }, [selectedGroupId])

    if (!chatRooms.list) {
        return (
            <div className="container p-1">
                <Alert variant={'warning'}>
                    No socket connection!
                </Alert>
            </div>
        )
    }

    return (
        <div style={{ marginBottom: 40 }}>
            {groupsList && groupsList.length > 0 && chatRooms ? (
                <>
                    <h5 className="bg-light p-1"><i className="bi bi-chat-square-fill"></i> Chatrooms Messages</h5>
                    <Accordion flush activeKey={selectedGroupId} onSelect={handleSelectGroup}>
                        {groupsList.map((group) => {
                            const chatRoomsMsgUnread = getChatRoomsTotalUnread(authUserId, chatRooms.list[group._id]?.items)
                            return (
                                <Accordion.Item key={'accordion-group-' + group._id} eventKey={group._id}>
                                    <Accordion.Header className="d-flex justify-content-between align-items-center w-100">
                                        <span style={{ width: "80%", textAlign: "left" }}><i className='bi bi-shield'></i> {group.name}</span>
                                        {chatRooms.list[group._id]?.groupId !== selectedGroupId && chatRooms.list[group._id]?.items.length != 0 &&
                                            <>
                                                <Badge bg="secondary"><i className="bi bi-chat-square"></i> {chatRooms.list[group._id]?.items.length || 0}</Badge>&nbsp;
                                            </>
                                        }
                                        {chatRooms.list[group._id]?.groupId !== selectedGroupId && chatRoomsMsgUnread != 0 &&
                                            <Badge bg="warning"><i className="bi bi-envelope"></i> {chatRoomsMsgUnread}</Badge>
                                        }
                                    </Accordion.Header>
                                    <Accordion.Body className="p-0">
                                        <ListGroup variant="flush">
                                            {chatRooms.list[group._id] && chatRooms.list[group._id].items.map((chatRoom) => {
                                                const msgNum = getChatRoomUnreadedMessages(chatRoom, authUserId)
                                                return (
                                                    <ListGroup.Item
                                                        href={"#chatroom-" + chatRoom._id}
                                                        action
                                                        className="d-flex justify-content-between align-items-start"
                                                        variant="secondary"
                                                        key={"chatRoom-" + chatRoom._id}
                                                        onClick={() => { handleChatRoomSelection(chatRoom) }}
                                                    >
                                                        <div className="ms-0 me-auto">
                                                            <div className="fw-bold">{chatRoom.name}</div>

                                                            <OverlayTrigger
                                                                trigger={["hover", "focus"]}
                                                                key={`tr-trigger`}
                                                                placement="right"
                                                                overlay={
                                                                    <Popover id={`tr-popover`}>
                                                                        <Popover.Body>
                                                                            <ListGroup variant="flush">
                                                                                {chatRoom.usersData.map((user, index) => (
                                                                                    <ListGroup.Item key={`tr-popover-${index}`}>{user.name} ({user.email})</ListGroup.Item>
                                                                                ))}
                                                                            </ListGroup>
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <span><i className="bi bi-people"></i> {chatRoom.participants.users.length}</span>
                                                            </OverlayTrigger>
                                                            &nbsp;&nbsp;
                                                            <i className="bi bi-chat-heart"></i> {chatRoom.moment ? chatRoom.moment : moment(chatRoom.lastMessageAt).fromNow()} &nbsp;
                                                            <i className="bi bi-person-gear"></i> {chatRooms.allUsers[chatRoom.createdBy]?.name} &nbsp;
                                                            {(!chatRoom.createdBy || authUserId === chatRoom.createdBy) &&
                                                                <>
                                                                    <i className="bi bi-pencil-square text-secondary" onClick={() => handleEditChatRoomBtn(chatRoom)}> <FormattedMessage id="textEdit" defaultMessage="Edit" /></i> &nbsp;
                                                                    <i className="bi bi-trash text-danger" onClick={() => handleDeleteChatRoomBtn(chatRoom)}> <FormattedMessage id="textDelete" defaultMessage="Delete" /></i>
                                                                </>
                                                            }
                                                        </div>
                                                        {msgNum > 0 &&
                                                            <Badge bg="warning">
                                                                <i className="bi bi-envelope"></i> {msgNum}
                                                            </Badge>
                                                        }
                                                    </ListGroup.Item>
                                                )
                                            })}
                                        </ListGroup>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                    </Accordion >

                    {chatModalShow &&
                        <ChatModal authUserId={authUserId} chatRooms={chatRooms} chatRoomServices={chatRoomServices} sendButtonDisabled={sendButtonDisabled} callback={setChatModalShow} />
                    }
                </>
            ) : (
                <div className="container p-1">
                    <Alert variant={'warning'}>
                        No groups to show!
                    </Alert>
                </div>
            )}
        </div>
    )
}

export default ChatRoomsList