const ChatRoomEvents = (socket) => {

    const setEvents = (callBackSetData) => {

        // Join to ChatRoom
        socket.on('joinChatRoom', (data) => {
            callBackSetData(data)
        })

        // Evento addChatRoom
        socket.on('addChatRoom', (data) => {
            callBackSetData(data)
        })

        // Evento editChatRoom
        socket.on('updateChatRoom', (data) => {
            callBackSetData(data)
        })

        // Evento deleteChatRoom
        socket.on('deleteChatRoom', (data) => {
            callBackSetData(data)
        })

        // Evento getChatRooms
        socket.on('getChatRooms', (data) => {
            callBackSetData(data)
        })

        // Evento getChatRoomMessages
        socket.on('getChatRoomMessages', (data) => {
            callBackSetData(data)
        })

        // Evento sendNewChatRoomMessage
        socket.on('addChatRoomMessage', (data) => {
            callBackSetData(data)
        })

    }

    return {
        setEvents,
    }
}

export default ChatRoomEvents