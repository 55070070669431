import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { isUrlValid } from '../../utils/ValidationForm'

const FeatureInfoModal = ({ modal, toggle, selectedFeature, setModal }) => {

    let documentLink = false

    const [show, setShow] = useState(false)

    const handleClose = () => {
        setModal(false)
        setShow(false)
    }
    const handleShow = () => setShow(true)

    useEffect(() => {
        if (!modal) return
        if (toggle) {
            handleShow()
        }
    }, [toggle])

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop={"static"}
            keyboard={false}
            fullscreen={false}
            aria-labelledby={"geoj-modal-feature-info"}
        >
            <Modal.Header closeButton>
                <Modal.Title id={"geoj-modal-feature-info"}>Id: {selectedFeature &&
                    selectedFeature.properties &&
                    selectedFeature.properties[selectedFeature.properties._featureIdInProperty]}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>
                    <b><FormattedMessage id="featureName" defaultMessage="Name" />: </b>
                    {selectedFeature &&
                        selectedFeature.properties &&
                        selectedFeature.properties.name}
                </h5>
                <p>
                    <b><FormattedMessage id="featureProperties" defaultMessage="Properties" />: </b>
                    {selectedFeature &&
                        selectedFeature.properties && Object.keys(selectedFeature.properties).map((keyName, i) => {
                            if (selectedFeature.properties[keyName] != null && selectedFeature.properties[keyName] != "") {
                                if (!keyName.startsWith('_')) {
                                    if (isUrlValid(selectedFeature.properties[keyName])) {
                                        return (
                                            <li className="travelcompany-input" key={i}>
                                                <span className="input-label"><b>{keyName}</b>: <a href={selectedFeature.properties[keyName]} target={"_propInfo"}><FormattedMessage id="textOpenLink" defaultMessage="Open link" /></a></span>
                                            </li>
                                        )
                                    } else {
                                        return (
                                            <li className="travelcompany-input" key={i}>
                                                <span className="input-label"><b>{keyName}</b>: {selectedFeature.properties[keyName]}</span>
                                            </li>
                                        )
                                    }
                                } else {
                                    if (keyName === "_document_") {
                                        documentLink = selectedFeature.properties[keyName]
                                        return null
                                    }
                                }
                            }
                        })}
                </p>
            </Modal.Body>
            <Modal.Footer>
                {documentLink &&
                    <Button variant="primary" href={documentLink} target={'_documentPDF'}>
                        <i className="bi bi-file-earmark-richtext"></i> <FormattedMessage id="textDocuments" defaultMessage="Documents" />
                    </Button>
                }
                <Button variant="secondary" onClick={handleClose}>
                    <i className="bi bi-x-square"></i> <FormattedMessage id="textClose" defaultMessage="Close" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default FeatureInfoModal

