import React from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

const RJSFormPosition = (props) => {

    const { id, formData, classNames, label, help, helpInfo, description, errors, children, hidden, onChange, required, schema } = props

    const lat = formData[0]
    const lon = formData[1]

    const handleCustomChange = (name, e) => {
        const value = e.target.value
        onChange([name === 'lat' ? value : lat, name === 'lon' ? value : lon])
    }

    return (
        <>
            <Form.Group className="mb-3" controlId={id}>
                <Form.Label className="d-flex justify-content-between">
                    <div>
                        <i className="bi bi-info-circle-fill" onClick={() => helpInfo({ title: schema.title, description: schema.description })}></i> {label}{required ? '*' : null}
                    </div>
                    {formData && formData !== '' && <i className="bi bi-check text-success"></i>}
                </Form.Label>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Lat</InputGroup.Text>
                    <Form.Control
                        type="text"
                        pattern="[0-9]*[.]?[0-9]+"
                        name={"lat_" + id}
                        value={lat || ''}
                        onChange={(e) => handleCustomChange('lat', e)}
                        required={schema.required}
                    />

                    <InputGroup.Text>Lon</InputGroup.Text>
                    <Form.Control
                        type="text"
                        pattern="[0-9]*[.]?[0-9]+"
                        name={"lon_" + id}
                        value={lon || ''}
                        onChange={(e) => handleCustomChange('lon', e)}
                        required={schema.required}
                    />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                    {children}
                    {errors}
                    {help}
                </Form.Control.Feedback>
            </Form.Group>
        </>
    )
}

export default RJSFormPosition
