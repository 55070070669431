import React from 'react'
import Form from 'react-bootstrap/Form'

const RJSFormSelect = (props) => {
    const { id, formData, classNames, label, help, helpInfo, description, errors, children, hidden, onChange, onBlur, schema, required } = props

    const handleCustomChange = (e) => {
        onChange(e.target.value)
    }

    return (
        <Form.Group className="mb-3" controlId={id}>
            <Form.Label className="d-flex justify-content-between">
                <div>
                    <i className="bi bi-info-circle-fill" onClick={() => helpInfo({ title: schema.title, description: schema.description })}></i> {label}{required ? '*' : null}
                </div>
                {formData && formData !== '' && <i className="bi bi-check text-success"></i>}
            </Form.Label>
            <Form.Select
                name={id}
                value={formData || ''}
                onChange={handleCustomChange}
                required={required}
            >
                <option value="" key="select-0"></option>
                {schema.enum.map(optionValue =>
                    <option value={optionValue} key={optionValue}>{optionValue}</option>
                )}
            </Form.Select>

            <Form.Control.Feedback type="invalid">
                {children}
                {errors}
                {help}
            </Form.Control.Feedback>
        </Form.Group>

    )
}

export default RJSFormSelect
