import React from "react"
import { useIntl, FormattedMessage } from 'react-intl'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

const GroupBar = ({
    group,
    missions,
    users,
    searchValue = '',
    missionId,
    userId,
    numTasks,
    onChangeMission,
    onChangeUser,
    onChangeSearch,
    onClickClearBtn,
    handleShowGroupInfo
}) => {

    const intl = useIntl()

    const handleShowInfo = () => {
        handleShowGroupInfo()
    }

    const handleMission = (e) => {
        onChangeMission(e)
    }

    return (
        <div className="input-group input-group-sm mb-3">
            <span className="input-group-text">
                <i className="bi bi-shield"></i>
            </span>
            <Button variant="outline-light text-dark" size="sm" onClick={handleShowInfo}>
                {group.event.name} / {group.name}
            </Button>
            <span className="input-group-text"><i className="bi bi-lightning-fill"></i>&nbsp;{missions && missions.length}</span>
            <span className="input-group-text"><i className="bi bi-clipboard2-fill"></i>&nbsp;{numTasks}</span>
            <Form.Select name="selectMission" className='form-select' size="sm" value={missionId} onChange={handleMission} >
                <option value={false}><FormattedMessage id="textAllMissions" defaultMessage="All missions" /></option>
                {missions && missions.map((mission) => {
                    return <option key={"missions-select-mission-" + mission._id} value={mission._id}>{mission.name}</option>
                })
                }
            </Form.Select>
            <span className="input-group-text"><i className="bi bi-people-fill"></i>&nbsp; {users && users.length}</span>
            <Form.Select name="selectUser" className='form-select' size="sm" value={userId} onChange={onChangeUser}>
                <option value={false}><FormattedMessage id="textAllUsers" defaultMessage="All users" /></option>
                {users && users.map((user) => {
                    return <option key={"missions-select-user-" + user._id} value={user._id}>{user.name}</option>
                })
                }
            </Form.Select>
            <span className="input-group-text"><i className="bi bi-search"></i></span>
            <input className="form-control form-control-sm" name="search" type="text" placeholder={intl.formatMessage({ id: 'textSearch', defaultMessage: 'Search' }) + '...'} value={searchValue} onChange={onChangeSearch} />
            <Button variant="outline-secondary" size="sm" onClick={onClickClearBtn}><i className="bi bi-x-square-fill"></i> <FormattedMessage id="textClear" defaultMessage="Clear" /></Button>
        </div>
    )
}

export default GroupBar