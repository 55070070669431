import { useEffect } from "react"
import { useIntl } from 'react-intl'
import { useMap } from "react-leaflet"
import styles from "./location-button.module.css"
import L from "leaflet"

const CenterButton = ({coordinates, zoom}) => {
    const intl = useIntl()

    const map = useMap()

    useEffect(() => {
        // create custom button
        const customControl = L.Control.extend({
            // button position
            options: {
                position: "topleft",
                className: `${styles.locateButton} leaflet-bar`,
                html: '<i class="bi bi-pin-map-fill"></i>',
                style: "width: 34px; height: 34px; left: 0; margin-top: 0; display: flex; cursor: pointer; justify-content: center; font-size: 1rem;",
            },

            // method
            onAdd: function (map) {
                this._map = map
                const button = L.DomUtil.create("div")
                L.DomEvent.disableClickPropagation(button)

                button.title = intl.formatMessage({ id: 'mapCenterMap', defaultMessage: 'Center map to starting position' })
                button.innerHTML = this.options.html
                button.className = this.options.className
                button.setAttribute("style", this.options.style)

                L.DomEvent.on(button, "click", this._clicked, this)

                return button
            },
            _clicked: function (e) {
                L.DomEvent.stopPropagation(e)
                map.setView(coordinates, zoom)
                return
            }
        })

        // adding new button to map controll
        map.addControl(new customControl())
    }, [map])

    return null
}

export default CenterButton
