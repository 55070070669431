import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'

import moment from 'moment'

import MissionsService from 'services/MissionsService'

/*
*   modalType: 'edit' and 'delete'
*/
const MissionModal = ({ show, modalType, mission, callBack }) => {

    const [confirmModalShow, setConfirmModalShow] = useState(false)
    const handleConfirmModalClose = (update) => {
        // update true for callback
        setConfirmModalShow(false)
        callBack(update)
    }
    const handleConfirmModalShow = () => setConfirmModalShow(true)

    const [name, setName] = useState(mission.name)
    const [description, setDescription] = useState(mission.description)
    const [formValid, setFormValid] = useState(false)

    const handleNameInput = (e) => {
        setName(e.target.value)
    }

    const handleDescriptionInput = (e) => {
        setDescription(e.target.value)
    }

    const handleUpdateBtn = async () => {
        await updateMission(mission._id)
        handleConfirmModalClose(true)
    }

    const updateMission = async (missionId) => {
        const update = { name: name, description: description }
        await MissionsService.updateMission(update, missionId)
        setFormValid(false)
    }

    const handleDeleteBtn = async () => {
        await deleteMission(mission._id)
        handleConfirmModalClose(true)
    }

    const deleteMission = async (missionId) => {
        await MissionsService.deleteMission(missionId)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget
        if (form.checkValidity()) {
            const update = { name: name, description: description }
            await MissionsService.updateMission(update, mission._id)
            handleConfirmModalClose(true)
            return
        }

        setFormValid(true)
    }

    const getModalTitle = (type) => {
        switch (type) {
            case 'edit':
                return <><i className="bi bi-pencil-square"></i> <FormattedMessage id="missionsEdit" defaultMessage="Edit mission" /></>
            case 'delete':
                return <><i className="bi bi-trash"></i> <FormattedMessage id="missionsDelete" defaultMessage="Delete mission?" /></>
            default:
                return <><i className="bi bi-pencil-square"></i> <FormattedMessage id="missionsEdit" defaultMessage="Edit mission" /></>
        }
    }

    useEffect(() => {
        if (show) {
            handleConfirmModalShow()
        } else {
            handleConfirmModalClose(false)
        }
    }, [show])

    return (
        <Modal
            show={confirmModalShow}
            onHide={() => handleConfirmModalClose(false)}
            animation={false}
            backdrop={"static"}
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{getModalTitle(modalType)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>
                    <b><FormattedMessage id="missionsName" defaultMessage="Mission name" />: </b> {name}
                </h5>
                {modalType === 'edit' && (
                    <Form noValidate validated={formValid} onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label><FormattedMessage id="missionsName" defaultMessage="Mission name" />:</Form.Label>
                            <Form.Control
                                type="text"
                                value={name}
                                name="missionName"
                                onChange={handleNameInput}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="missionsNameInvalid" defaultMessage="Provide a mission name" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label><FormattedMessage id="missionsDescription" defaultMessage="Description" />:</Form.Label>
                            <Form.Control as="textarea" rows={3} value={description} onChange={handleDescriptionInput} />
                        </Form.Group>

                        <div className="d-grid gap-2">
                            <Button type="submit" variant="primary">
                                <i className="bi bi-pencil-square"></i> <FormattedMessage id="textUpdate" defaultMessage="Update" />
                            </Button>
                        </div>
                    </Form>
                )}

                {modalType === 'delete' && (
                    <>
                        <p>{description}</p>

                        <Alert variant={'danger'}>
                            <FormattedMessage
                                id="missionsDeleteAdvise"
                                defaultMessage="This action will delete the mission and its <strong>[all] {numTasks} tasks!</strong>"
                                values={{ numTasks: mission.features.length, strong: chunks => <strong>{chunks}</strong> }}
                                 />
                        </Alert>
                    </>
                )}

            </Modal.Body>
            <Modal.Footer>
                {modalType === 'edit' && (
                    <Button variant="secondary" onClick={() => handleConfirmModalClose(false)}>
                        <i className="bi bi-x-square"></i> <FormattedMessage id="textClose" defaultMessage="Close" />
                    </Button>
                )}
                {modalType === 'delete' && (
                    <>
                        <Button variant="danger" onClick={handleDeleteBtn}>
                            <i className="bi bi-trash"></i> <FormattedMessage id="textYes" defaultMessage="Yes" />
                        </Button>
                        <Button variant="secondary" onClick={() => handleConfirmModalClose(false)}>
                            <i className="bi bi-x-square"></i> <FormattedMessage id="textNo" defaultMessage="No" />
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default MissionModal