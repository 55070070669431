import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class TeamService {

    // Add new response team item to group :id
    addTeam = async (id, team) => {
        return await axios.post(API_URL + '/groups/' + id + '/team', team, { headers: authHeader() })
    }

    // Update response team item :teamId data from group :id
    updateTeam = async (id, teamId, team) => {
        return await axios.patch(API_URL + '/groups/' + id + '/team/' + teamId, team, { headers: authHeader() })
    }

    // Get response team item :teamId from group :id
    getTeam = async (id, teamId) => {
        return await axios.get(API_URL + '/groups/' + id + '/team/' + teamId, { headers: authHeader() })
    }

    // Delete response team item :teamId from group :id
    deleteTeam = async (id, teamId) => {
        return await axios.delete(API_URL + '/groups/' + id + '/team/' + teamId, { headers: authHeader() })
    }

    // Get response team item list from group :id filtering by user and search query 
    getTeams = async ({ groupId, userId = false, pageNum = 1, search = '', limit = false }) => {
        let url = API_URL + '/groups/' + groupId + '/team?'

       if (userId !== false) {
            url += 'userId=' + userId + '&'
        }
        if (pageNum !== false) {
            url += 'page=' + pageNum + '&'
        }
        if (search !== false) {
            url += 'search=' + search + '&'
        }
        if (limit !== false) {
            url += 'limit=' + limit
        }

        return await axios.get(url, { headers: authHeader() });
    }

}

export default new TeamService()