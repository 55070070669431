import React, { useState, useEffect } from "react"
import { FormattedMessage } from 'react-intl'
import { Link } from "react-router-dom"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import { withRouter } from '../utils/withRouter'
import EventBus from "../utils/EventBus"

import AuthService from "services/AuthService"

import { AlertError } from "helpers/AlertHelper"

const Login = ({ router }) => {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [formValid, setFormValid] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  
  const handleInputChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setFieldValue(name, value)
  }

  const setFieldValue = (fieldName, value) => {
    switch (fieldName) {
      case 'username':
        setUsername(value)
        break
      case 'password':
        setPassword(value)
        break
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    const form = e.currentTarget
    if (form.checkValidity()) {
      setLoading(true)

      await AuthService.login(username, password).then(
        response => {
          if (response.data.accessToken) {
            setFormValid(false)
            localStorage.setItem("user", JSON.stringify(response.data))
            EventBus.dispatch("login")
            router.navigate("/home")
          } else {
            setLoading(false)
            EventBus.dispatch("logout")
            router.navigate("/")
          }
        },
        error => {
          setLoading(false)
          EventBus.dispatch("logout")
          router.navigate("/")
          setError(error)
          setFormValid(false)
        }
      )
    }
    setFormValid(true)
  }

  return (
    <div className="col-md-12">
      <div className="login login-container">
        <p><img
          src="/logo_civilio_trans.png"
          alt="Civilio.eu Information Systems"
          className="login-img"
        /></p>
        <h1 className="h3 mb-3 fw-normal"><FormattedMessage id="loginTitle" defaultMessage="Please sign in" /></h1>
        <Form
          noValidate
          validated={formValid}
          onSubmit={handleSubmit}
          autoComplete={"off"}
        >
          <Form.Group className="mb-3" controlId="username">
            <Form.Label><FormattedMessage id="loginEmail" defaultMessage="E-mail" />:</Form.Label>
            <Form.Control
              type="email"
              name="username"
              value={username}
              onChange={handleInputChange}
              required="required"
              autoComplete={"off"}
              pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
            />
            <Form.Control.Feedback type="invalid">
              <FormattedMessage id="loginEmailInvalid" defaultMessage="Provide your email address" />
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label><FormattedMessage id="loginPassword" defaultMessage="Password" />:</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={password}
              onChange={handleInputChange}
              required="required"
              autoComplete={"off"}
            />
            <Form.Control.Feedback type="invalid">
              <FormattedMessage id="loginPasswordInvalid" defaultMessage="Provide your password" />
            </Form.Control.Feedback>
          </Form.Group>

          <hr />
          <div className="form-group">
            <Button
              type="submit"
              className="w-100 btn btn-lg btn-primary btn-block"
            >
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span> <FormattedMessage id="loginButton" defaultMessage="Login" /></span>
            </Button>
          </div>

          <div className="form-group">
            <p className="text-center">
              <FormattedMessage id="loginNoAccount" defaultMessage="Don't have an account?" /> <Link to={"/signup"}><FormattedMessage id="loginSignup" defaultMessage="Sign up" /></Link>
            </p>
          </div>

          <AlertError error={error} />

        </Form>
      </div>
      <div className="text-center">
        &copy; {new Date().getFullYear()} Civilio Information Systems
      </div>
      <div className="text-center">
        <FormattedMessage id="version" defaultMessage="Version" />: Beta TR1.0
      </div>
    </div>
  )
}

export default withRouter(Login)