import React, { useState, useEffect } from "react"
import { useIntl, FormattedMessage } from 'react-intl'
import { Link, useParams } from "react-router-dom"
import Accordion from 'react-bootstrap/Accordion'

import moment from 'moment'

import FormModal from "../modals/FormModal";
import GroupsForm from "../../pages/GroupsForm";

import EventsService from '../../services/EventsService'

import { AlertLoading, AlertError } from 'helpers/AlertHelper'

const EventsAccordion = (props) => {

    const intl = useIntl()

    const state = props.state
    const { currentUser, showModeratorBoard, showAdminBoard } = state

    const update = props.update
    const updateFunction = props.updateFunction

    const [error, setError] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    const [events, setEvents] = useState(false)

    // Open modal form control to add/edit element
    const [modalTrigger, setModalTrigger] = useState(false);

    // Setting modal title
    const [modalTitle, setModalTitle] = useState(false);

    // Setting modal icon
    const [modalIcon, setModalIcon] = useState(false);

    // Setting modal action enum[add,edit,delete]
    const [modalAction, setModalAction] = useState(false);

    const getData = async () => {
        await EventsService.getActiveEvents().then(
            response => {
                setEvents(response.data.events)
                setIsLoaded(true)
            },
            error => {
                setError(error)
            }
        )

    }

    const updateAccordion = () => {
        setModalTrigger(false)
        setIsLoaded(false)
        setEvents(false)
    }

    useEffect(() => {
        if (!update) return
        updateAccordion()
    }, [update])

    useEffect(() => {
        if (!events || !isLoaded) {
            getData()
        }
    }, [isLoaded])

    if (error) {
        return (
            <AlertError error={error} />
        )
    } else if (!isLoaded) {
        return (
            <AlertLoading isLoaded={isLoaded} />
        )
    }
    
    return (
        <>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
                <span><i className="bi bi-shield-fill-exclamation"></i> <FormattedMessage id="navERG" defaultMessage="Emergency responder groups" /></span>
                <a className="link-secondary" href="#" title={intl.formatMessage({ id: 'navAddERG', defaultMessage: 'Add a new responder group' })} onClick={() => {
                    setModalTrigger(true)
                    setModalTitle(<FormattedMessage id="groupsAdd" defaultMessage="Add group" />)
                    setModalAction('add')
                    setModalIcon('bi-plus-circle')
                }}>
                    <i className="bi bi-plus-circle"></i>
                </a>
            </h6>

            <div className="container p-1">
                <Accordion flush >
                    {events && events.map((event, index) => {
                        return (
                            <Accordion.Item eventKey={'event-acc-' + index} key={"events-accordion-" + event._id}>
                                <Accordion.Header><i className="bi bi-shield"></i> &nbsp;{event.name}</Accordion.Header>
                                <GroupsAccordion state={state} event={event} update={update} updateFunction={updateFunction} />
                            </Accordion.Item>
                        )
                    })}
                </Accordion>
            </div>

            <FormModal
                key={'events-accordion-modal'}
                title={modalTitle}
                icon={modalIcon}
                body={GroupsForm}
                refreshData={updateAccordion}
                modalTrigger={modalTrigger}
                elementId={false}
                action={modalAction}
                filterActive={true} />
        </>
    )
}


const GroupsAccordion = (props) => {
    const state = props.state
    const { currentUser, showModeratorBoard, showAdminBoard } = state

    const update = props.update
    const updateFunction = props.updateFunction

    const [groupSelected, setGroupSelected] = useState(false)

    const [groups, setGroups] = useState(props.event.groups || false)

    useEffect(() => {
        if (!update) return
        updateFunction(false)
    }, [update])

    useEffect(() => {
        if (!update) return
    }, [groupSelected])

    return (
        <Accordion.Body style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
            <div className="list-group list-group-flush border-bottom scrollarea p0">
                {props.event && groups && groups.map((group, index) => {
                    const dateTimeAgo = moment(group.createdAt).fromNow()
                    if (group._id === groupSelected) {
                        return (
                            <Link to={"/missions/" + group._id} className="list-group-item list-group-item-action lh-sm active" key={'a-acc-group' + index} onClick={() => setGroupSelected(group._id)}>
                                <div className="d-flex w-100 align-items-center justify-content-between" key={'div-acc-group' + index}>
                                    <strong className="mb-1">{group.name}</strong>
                                    <small>{dateTimeAgo}</small>
                                </div>
                                <div className="col-10 mb-1 small">{group.description}</div>
                            </Link>
                        )
                    }
                    return (
                        <Link to={"/missions/" + group._id} className="list-group-item list-group-item-action lh-sm" key={'a-acc-group' + index} onClick={() => setGroupSelected(group._id)}>
                            <div className="d-flex w-100 align-items-center justify-content-between" key={'div-acc-group' + index}>
                                <strong className="mb-1">{group.name}</strong>
                                <small>{dateTimeAgo}</small>
                            </div>
                            <div className="col-10 mb-1 small">{group.description}</div>
                        </Link>
                    )
                })}

            </div>
        </Accordion.Body>
    )
}
export default EventsAccordion