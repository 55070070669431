import { useEffect, useState } from "react"
import { useIntl, FormattedMessage } from 'react-intl'
import { useMap, LayerGroup, GeoJSON, Popup, Tooltip, Circle } from "react-leaflet"
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'

import styles from "./userLocationButton.module.css"
import L from "leaflet"

import _ from 'lodash'

import moment from 'moment'

import UserLocationService from 'services/UserLocationService'

import { personIcon } from "helpers/Markers"
import { generateRandomColors } from "helpers/Colors"

const UsersLocationButton = ({ groupId, missionId, userId, searchValue, mapSliderValue, onError }) => {
    const intl = useIntl()

    const map = useMap()

    const [usersLocation, setUsersLocation] = useState(false)
    const [usersColors, setUsersColors] = useState({})

    const [showUsersLocation, setShowUsersLocation] = useState(false)

    const getUsersLocation = async () => {
        const mapBounds = map.getBounds()
        const options = {
            groupId: groupId,
            userId: userId,
            search: searchValue,
            lonbl: mapBounds._southWest.lng,
            latbl: mapBounds._southWest.lat,
            lonur: mapBounds._northEast.lng,
            latur: mapBounds._northEast.lat,
            timestamp: mapSliderValue
        }

        await UserLocationService.getLatestUsersLocation(options).then(
            response => {
                setUsersLocation(response.data)
                assignUsersColors(response.data)
                onError(false)
            },
            error => {
                onError(error)
            })

    }

    const assignUsersColors = (locationsData) => {
        const colors = generateRandomColors(200)
        const usersColorsObject = usersColors
        let userIndex = 0
        locationsData.forEach((location) => {
            const userId = location.properties.userId
            if (!usersColorsObject[userId]) {
                usersColorsObject[userId] = colors[userIndex]
                userIndex = (userIndex + 1) % colors.length
            }
        })

        setUsersColors(usersColorsObject)
    }

    const handleFeatureClick = (geoJ) => {
        if (geoJ.layer.feature.geometry.type !== 'Point') {
            geoJ.layer.bringToBack()
        }
    }

    const handleFeatureMouseOver = (geoJ, featureColor) => {
        //if (!taskGeoLayerRef.current) return

        if (geoJ.layer.feature.geometry.type !== "Point") {
            geoJ.layer.setStyle({ color: "black", fillColor: featureColor, fillOpacity: 0.5 })
        } else {
            if (geoJ.layer.getElement() !== null) {
                geoJ.layer.getElement().querySelector('path').style.fill = featureColor
            }
        }
    }

    const handleFeatureMouseOut = (geoJ, featureColor) => {
        //if (!taskGeoLayerRef.current) return

        if (geoJ.layer.feature.geometry.type !== "Point") {
            geoJ.layer.setStyle({ color: 'black', fillColor: featureColor, fillOpacity: 0.5 })
        } else {
            if (geoJ.layer.getElement() !== null) {
                geoJ.layer.getElement().querySelector('path').style.fill = featureColor
            }
        }
    }

    useEffect(() => {
        if (!map) return
        // create custom button
        const customControl = L.Control.extend({
            // button position
            options: {
                position: "topright",
                className: `${styles.userLocationButton} leaflet-bar`,
                html: '<i class="bi bi-person-circle"></i>',
                style: "width: 34px; height: 34px; left: 0; margin-top: 0; display: flex; cursor: pointer; justify-content: center; font-size: 1rem;",
            },

            // method
            onAdd: function (map) {
                this._map = map
                const button = L.DomUtil.create("div")
                L.DomEvent.disableClickPropagation(button)

                button.title = intl.formatMessage({ id: 'mapUsersLocation', defaultMessage: 'Display users location' })
                button.innerHTML = this.options.html
                button.className = this.options.className
                button.setAttribute("style", this.options.style)

                L.DomEvent.on(button, "click", this._clicked, this)

                return button
            },
            _clicked: function (e) {
                L.DomEvent.stopPropagation(e)

                const usersLocationBtnActive = document.querySelector(`.${styles.userLocationButton}`)
                const usersLocationBtn = usersLocationBtnActive.classList.contains(styles.userLocationActive)
                // add/remove class from task button
                usersLocationBtnActive.classList[usersLocationBtn ? "remove" : "add"](styles.userLocationActive)

                // Click funtion
                ///////////////////////////
                setShowUsersLocation(!usersLocationBtn)

                return
            }
        });

        // adding new button to map controll
        map.addControl(new customControl())
    }, [map])

    useEffect(() => {
        if (!map) return
        if (!showUsersLocation) return

        getUsersLocation()

        const getUsersLocationThrottled = _.throttle(() => {
            getUsersLocation()
        }, 1000) // 1000ms 

        const onMoveEnd = () => {
            getUsersLocationThrottled()
        }

        const interval = setInterval(() => {
            getUsersLocation()
        }, 60000) // 60000ms = 1 minute

        map.on('moveend', onMoveEnd)
        return () => {
            map.off('moveend', onMoveEnd)
            getUsersLocationThrottled.cancel()
            clearInterval(interval)
        }

    }, [showUsersLocation, missionId, userId, searchValue, mapSliderValue])

    return (
        map && showUsersLocation && usersLocation && (
            <LayerGroup
                attribution="Civilio users location"
                color="red"
                key={"lg-users-location-" + groupId}
            >
                {usersLocation && usersLocation.map((location, index) => {
                    location.type = "Feature"
                    return (
                        <GeoJSON
                            key={"lg-users-location-" + groupId + "-fg-feature-" + location._id}
                            data={location}
                            weight={2}
                            opacity={1}
                            color={"orange"}
                            //dashArray={3}
                            fillOpacity={1} //0.3
                            //fillColor={"orange"}
                            fillColor={usersColors[location.properties.userId]}
                            eventHandlers={{
                                click: (geoJ) => {
                                    handleFeatureClick(geoJ, usersColors[location.properties.userId])
                                },
                                mouseover: (geoJ) => {
                                    handleFeatureMouseOver(geoJ, 'black')
                                },
                                mouseout: (geoJ) => {
                                    handleFeatureMouseOut(geoJ, usersColors[location.properties.userId])
                                },
                                popupopen: (geoJ) => {
                                    //handleFeaturePopupOpen(geoJ)
                                },
                                popupclose: (geoJ) => {
                                    handleFeatureMouseOut(geoJ, usersColors[location.properties.userId])
                                },
                                add: (geoJ) => {
                                },
                                remove: (geoJ) => {
                                }
                            }}
                            pointToLayer={function (geoJsonPoint, latlng) {
                                return L.marker(latlng, { icon: personIcon(usersColors[location.properties.userId]) })
                            }}
                        >
                            <Tooltip direction="top" offset={[0, 0]} opacity={1} sticky={true} >
                                {<span key={"lg-users-location-" + groupId + "-tooltip-" + location.properties.userId}>{location.properties.user.name} <br /> {location.properties.entity.name}</span>}
                            </Tooltip>
                            <Popup
                                key={"lg-users-location-" + groupId + "-popup-" + location.properties.userId}
                                maxWidth={500}
                            >
                                <h6><i className="bi bi-crosshair2"></i> {location.properties.user.name}</h6>
                                <div className="text-primary">
                                    <hr />
                                </div>

                                <p className="text-end"><i className="bi bi-clock"></i> {moment(location.timestamp).fromNow()}</p>

                                {location.properties.accuracy &&
                                    <p>Accuracy: {location.properties.accuracy.toFixed(2)}m</p>
                                }
                                {location.properties.speed &&
                                    <p>Speed: {location.properties.speed.toFixed(2)}m/s</p>
                                }
                                {location.properties.altitude &&
                                    <>
                                        <p>Altitude: {location.properties.altitude.toFixed(2)}m</p>
                                        {location.properties.altitudeAccuracy &&
                                            <p>Altitude accuracy: {location.properties.altitudeAccuracy.toFixed(2)}</p>
                                        }
                                    </>
                                }
                                {location.properties.heading &&
                                    <p>heading: {location.properties.heading.toFixed(0)}</p>
                                }
                                <p><i className="bi bi-phone"></i> {location.properties.user.phone}</p>
                                <p><i className="bi bi-envelope"></i> {location.properties.user.email}</p>
                                <p><i className="bi bi-briefcase-fill"></i> {location.properties.entity.name}</p>

                                <div className="text-primary">
                                    <hr />
                                </div>
                            </Popup>
                            {location.geometry.type === 'Point' && location.properties.accuracy && (
                                <Circle
                                    key={"lg-users-location-circle-" + groupId + "-fg-feature-" + location._id}
                                    center={[location.geometry.coordinates[1], location.geometry.coordinates[0]]}
                                    radius={(location.properties.accuracy / 2)}
                                    weight={2}
                                    opacity={0.7}
                                    color={usersColors[location.properties.userId]}
                                    fillOpacity={0.2}
                                    fillColor={usersColors[location.properties.userId]}
                                    className={styles.locatedAnimation}
                                />
                            )}
                        </GeoJSON>
                    )
                })

                }

            </LayerGroup>
        )
    )
}


const PulsingCircle = ({ location, usersColors }) => {
    const [pulsing, setPulsing] = useState(false);

    useEffect(() => {
        setPulsing(true);

        // Detener el pulso después de 1 segundo
        const timeout = setTimeout(() => {
            setPulsing(false);
        }, 1000);

        // Limpiar el timeout al desmontar el componente
        return () => clearTimeout(timeout);
    }, [location]);

    return (
        <Circle
            center={[location.geometry.coordinates[1], location.geometry.coordinates[0]]}
            radius={pulsing ? location.properties.accuracy * 1.5 : location.properties.accuracy / 2}
            weight={0}
            opacity={0.7}
            color={usersColors[location.properties.userId]}
            fillOpacity={0.2}
            fillColor={usersColors[location.properties.userId]}
        />
    );
};
export default UsersLocationButton
