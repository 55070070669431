import { useState, useEffect, useRef } from "react"
import { MapContainer } from "react-leaflet"
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'

import _ from 'lodash'

import PrintMapButton from "../../components/map/controls/PrintMapButton"
import CenterButton from "../../components/map/controls/CenterButton"
import LocationButton from "../../components/map/controls/LocationButton"
import GetCoordinates from "../../components/map/controls/GetCoordinates"
import SearchButton from "../../components/map/controls/SearchButton"
import CustomButton from "../../components/map/controls/CustomButton"
import MissionsButton from "../../components/map/buttons/MissionsButton"
import TasksButton from "../../components/map/buttons/TasksButton"
import UsersTasksButton from "../../components/map/buttons/UsersTasksButton"
import UsersLocationButton from "../../components/map/buttons/UserLocationButton"
import InspectionsButton from "../../components/map/buttons/InspectionsButton"
import MapSlider from "../../components/map/buttons/MapSlider"

import Layers from "../../components/map/layers/Layers"

import ConfigService from "services/ConfigService"

import { AlertError } from 'helpers/AlertHelper'

const MapGroup = ({ group, groupId, missionId, userId, searchValue, updateGroupData }) => {
  const mapRef = useRef(null)

  const [frontConfig, setFrontConfig] = useState(ConfigService.getFrontConfig())

  const [error, setError] = useState(false)
  const handleMapError = (error) => {
    setError(error)
  }

  const [refreshData, setRefreshData] = useState(false)
  const handleUpdateGroupData = (value) => {
    updateGroupData(value)
    setRefreshData(Date.now())
  }

  const [mapTimeSliderValue, setMapTimeSliderValue] = useState(false)
  const handleMapSliderValue = (value) => {
    const timestamp = value !== 0 ? Date.now() + (value * 60 * 60 * 1000) : false
    setMapTimeSliderValue(timestamp)
  }

  useEffect(() => {
    if (!mapRef) return
  }, [mapRef])

  return (
    <>
      {error &&
        <AlertError error={error} />
      }
      <MapContainer
        key={"Group-Map-" + groupId}
        fullscreenControl={true}
        //whenCreated={setMap}
        whenCreated={(mapInstance) => (mapRef.current = mapInstance)}
        center={frontConfig.map.center}
        zoom={frontConfig.map.zoom}
        scrollWheelZoom={frontConfig.map.scrollWheelZoom}
        style={{ height: '80vh', width: '100wh' }}
        maxZoom={19}
      >
        <Layers group={group} updateGroupData={handleUpdateGroupData} />

        <SearchButton />

        <PrintMapButton />

        <LocationButton />

        <CenterButton coordinates={frontConfig.map.center} zoom={frontConfig.map.zoom} />

        <GetCoordinates />

        <MissionsButton
          groupId={groupId}
          missionId={missionId}
          userId={userId}
          searchValue={searchValue}
          callBack={handleUpdateGroupData}
          mapSliderValue={mapTimeSliderValue}
          refresh={refreshData}
          onError={handleMapError}
        />

        <TasksButton
          groupId={groupId}
          missionId={missionId}
          userId={userId}
          searchValue={searchValue}
          mapSliderValue={mapTimeSliderValue}
          refresh={refreshData}
          onError={handleMapError}
        />

        <InspectionsButton
          groupId={groupId}
          missionId={missionId}
          userId={userId}
          searchValue={searchValue}
          mapSliderValue={mapTimeSliderValue}
          refresh={refreshData}
          onError={handleMapError}
        />

        <UsersTasksButton
          groupId={groupId}
          missionId={missionId}
          userId={userId}
          searchValue={searchValue}
          mapSliderValue={mapTimeSliderValue}
          refresh={refreshData}
          onError={handleMapError}
        />

        <UsersLocationButton
          groupId={groupId}
          missionId={missionId}
          userId={userId}
          searchValue={searchValue}
          mapSliderValue={mapTimeSliderValue}
          onError={handleMapError}
        />

        <CustomButton callBack={handleUpdateGroupData} />

        <MapSlider callBack={handleMapSliderValue} />
      </MapContainer>
    </>
  )
}

export default MapGroup
