module.exports = {
    DEFAULT_ROLES: [
        "ROLE_USER",
        "ROLE_ADMIN",
        "ROLE_COORDINATOR",
        "ROLE_SUPERVISOR",
        "ROLE_INSPECTOR",
        "ROLE_SUPPORT"
    ],
    DEFAULT_ROLE: "ROLE_USER"
}