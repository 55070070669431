import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import ChatRoomsService from 'services/ChatRoomsService'
import GroupsService from 'services/GroupsService'
import EntitiesService from 'services/EntitiesService'

import { CHATROOM_TYPES } from '../../config/chat'

function ChatRoomForm({ groupId, elementId, actionType, callBack }) {

    const [group, setGroup] = useState(false)
    const [entitiesUsers, setEntitiesUsers] = useState([])

    // Chatroom schema
    const [chatroomId, setChatRoomId] = useState(null)
    const [type, setType] = useState(false)
    const [roomName, setRoomName] = useState('')
    const [participants, setParticipants] = useState([])
    const [entitiesId, setEntitiesId] = useState([])
    const [usersData, setUsersData] = useState([])


    const [formValid, setFormValid] = useState(false)
    const [buttonTitle, setButtonTitle] = useState('')
    const [inputDisabled, setInputDisabled] = useState(false)
    const [showMsg, setShowMsg] = useState(false)
    const [message, setMessage] = useState(false)

    const handleEntityCheckBox = (e) => {
        if (e.target.checked) {
            getEntityUsers(e.target.name) // e.target.name come with entity._id
        } else {
            let newArray = []
            for (var i = 0; i < entitiesUsers.length; i++) {
                if (entitiesUsers[i].entityId != e.target.name) {
                    newArray.push(entitiesUsers[i])
                }
            }
            setEntitiesUsers(newArray)
            setParticipants([])
        }
    }

    const getGroup = async () => {
        await GroupsService.getGroup(groupId, true)
            .then(res => {
                setGroup(res.data)
            },
                error => {
                    handleErrorMsg(error)
                })
    }

    const getEntityUsers = (entityId) => {
        if (entityId) {
            EntitiesService.getEntityUsers(entityId)
                .then(res => {
                    let newArray = entitiesUsers || []
                    for (var i = 0; i < res.data.users.length; i++) {
                        var index = newArray.findIndex(x => x._id === res.data.users[i]._id)
                        index === -1 ? newArray.push(res.data.users[i]) : console.log("This item already exists")
                    }
                    setEntitiesUsers(newArray.filter(user => user._id !== elementId.authUserId))
                    setParticipants([])
                })
        }
    }

    const handleSelectParticipantsChange = (e) => {
        setParticipants([].slice.call(e.target.selectedOptions).map(item => item.value))
    }


    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFieldValue(name, value)
    }

    const setFieldValue = (fieldName, value) => {
        switch (fieldName) {
            case 'type':
                setType(value)
                break
            case 'roomName':
                setRoomName(value)
                break
            default:
                break
        }
    }

    const handleErrorMsg = (data) => {
        setShowMsg(true)
        setMessage(`Error (${data.error.code}): ${data.msg}`)
    }

    useEffect(() => {

        getGroup()

        if (elementId.element) {
            setChatRoomId(elementId.element._id)
            setType(elementId.element.type)
            const participantsArray = elementId.element.participants.users.filter(user => user.userId !== elementId.authUserId).map(participant => participant.userId)
            setParticipants(participantsArray)
            setRoomName(elementId.element.name)
            const entitiesIdArray = elementId.element.usersData.map(entity => entity.entityId);
            setEntitiesId(entitiesIdArray)
            //setUsersData(participantsData)
        }

        switch (actionType) {
            case 'edit':
                setButtonTitle(<FormattedMessage id="textSave" defaultMessage="Save" />)
                break
            case 'delete':
                setButtonTitle(<FormattedMessage id="textDelete" defaultMessage="Delete" />)
                setInputDisabled(true)
                break
            default:
                setButtonTitle(<FormattedMessage id="textAdd" defaultMessage="Add" />)

        }

    }, [])

    const handleCallBack = (chatRoom) => {
        callBack(actionType, chatRoom)
    }

    const handleOnSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget
        if (form.checkValidity()) {

            const chatRoomUpdate = {
                name: roomName
            }

            if (elementId && actionType === "edit") {
                const chatRoomEdit = {
                    groupId: groupId,
                    chatRoomId: chatroomId,
                    type: type,
                    participants: {
                        users: participants.map(userId => ({
                            userId: userId
                        })),
                    },
                    name: roomName
                }
                chatRoomEdit.participants.users.push({ userId: elementId.authUserId })
                setFormValid(false)
                handleCallBack(chatRoomEdit)
            } else if (actionType === "add") {
                const chatRoomAdd = {
                    groupId: groupId,
                    type: type,
                    participants: {
                        users: participants.map(userId => ({
                            userId: userId
                        })),
                    },
                    ...chatRoomUpdate
                }
                chatRoomAdd.participants.users.push({ userId: elementId.authUserId })
                setFormValid(false)
                handleCallBack(chatRoomAdd)

            } else if (actionType === "delete") {
                const chatRoomDelete = {
                    groupId: groupId,
                    chatRoomId: chatroomId,
                    type: type,
                    participants: {
                        users: participants.map(userId => ({
                            userId: userId
                        })),
                    },
                    usersData: usersData,
                    ...chatRoomUpdate
                }
                setFormValid(false)
                handleCallBack(chatRoomDelete)
            }
        } else {
            setFormValid(true)
        }
    }

    return (
        <div>
            <Alert show={showMsg} variant={'danger'} onClose={() => setShowMsg(false)} dismissible>
                {message}
            </Alert>

            <Form noValidate validated={formValid} onSubmit={handleOnSubmit}>
                {chatroomId && (
                    <Form.Group className="mb-3" controlId="id">
                        <Form.Label>Id:</Form.Label>
                        <Form.Control
                            type="text"
                            name="id"
                            value={chatroomId}
                            disabled={"disabled"}
                        />
                    </Form.Group>
                )}

                <Form.Group className="mb-3" controlId="type">
                    <Form.Label><FormattedMessage id="chatroomType" defaultMessage="Type" />:</Form.Label>
                    {CHATROOM_TYPES.map((ChatRoomType) => (
                        <Form.Check
                            inline
                            label={ChatRoomType}
                            name="type"
                            type="radio"
                            id={ChatRoomType}
                            key={ChatRoomType}
                            checked={type === ChatRoomType ? true : false}
                            onChange={() => setType(ChatRoomType)}
                            disabled={inputDisabled && ("disabled")}
                            required
                        />
                    ))}
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="chatroomTypeInvalid" defaultMessage="Select the chatroom type" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="roomName">
                    <Form.Label><FormattedMessage id="chatroomName" defaultMessage="Chatroom name" />:</Form.Label>
                    <Form.Control
                        type="text"
                        name="roomName"
                        value={roomName}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="chatroomNameInvalid" defaultMessage="Provide a chatroom name" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="entities">
                    <Form.Label><FormattedMessage id="chatroomEntities" defaultMessage="Entities" />:</Form.Label>
                    <div key="inline-radio-entities" className="mb-3">
                        {group && group.entities && group.entities.map((entity, index) => {
                            return (
                                <Form.Check
                                    inline
                                    label={entity.name}
                                    name={entity._id}
                                    value={entity._id}
                                    type="switch"
                                    id={`chatRoomEntity-${entity._id}`}
                                    key={`chatRoomEntity-${entity._id}`}
                                    onChange={handleEntityCheckBox}
                                    disabled={inputDisabled && ("disabled")}
                                />
                            )
                        }
                        )}
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="participants">
                    <Form.Label><FormattedMessage id="chatroomParticipants" defaultMessage="Participants" />:</Form.Label>
                    <Form.Select
                        key={`participants-${group._id}`}
                        name="participants"
                        size="md"
                        htmlSize={6}
                        onChange={handleSelectParticipantsChange}
                        required
                        value={participants}
                        multiple="multiple"
                        disabled={inputDisabled && ("disabled")}
                    >
                        {entitiesUsers && entitiesUsers.map((user, index) => {
                            return (
                                <option value={user._id} key={`select-participants-${user._id}`}>{user.name} ({user.email})</option>
                            )
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="chatroomParticipantsInvalid" defaultMessage="Select one o more participants users for this chatroom" />
                    </Form.Control.Feedback>
                </Form.Group>

                <div className="d-grid gap-2">
                    {actionType === "add" && (
                        <Button
                            className="btn btn-dark"
                            type="submit"
                        >
                            <i className="bi bi-plus-circle"></i> {buttonTitle}
                        </Button>
                    )}

                    {actionType === "edit" && (
                        <Button
                            className="btn btn-primary"
                            type="submit"
                        >
                            <i className="bi bi-save2"></i> {buttonTitle}
                        </Button>
                    )}

                    {actionType === "delete" && (
                        <Button
                            className="btn btn-danger"
                            type="submit"
                        >
                            <i className="bi bi-trash"></i> {buttonTitle}
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    )
}

export default ChatRoomForm