import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import ListGroup from 'react-bootstrap/ListGroup'

import moment from 'moment'

import ConfigService from 'services/ConfigService'
import LogbookService from 'services/LogbookService'

import Spinner from "components/spinner/Spinner"
import Pagination from "components/pagination/TablePagination";
import LogbookForm from 'components/forms/LogbookForm'
import FormDataModal from 'components/modals/FormDataModal'

const LogbookList = ({ groupId, missionId, userId, searchValue }) => {

    const fronConfig = ConfigService.getFrontConfig()

    const [modal, setModal] = useState({
        trigger: false,
        title: false,
        action: false,
        icon: false,
        elementId: false,
        groupId: groupId
    })

    const [logsBook, setLogsBook] = useState(false)
    const [pagination, setPagination] = useState(false)

    const getLogsBook = async (pageNum = 1) => {
        const options = {
            groupId: groupId,
            missionId: missionId,
            userId: userId,
            search: searchValue,
            pageNum: pageNum,
            limit: fronConfig.app.itemsListLimit            
        }

        await LogbookService.getLogsBook(options)
            .then(res => {
                setLogsBook(res.data.logsBook)
                setPagination(res.data.pagination)
            },
                error => {
                    setLogsBook(false)
                })

    }

    const goToPage = (pageNum) => {
        getLogsBook(pageNum)
    }

    const handleAddLogbookBtn = () => {
        setModal({
            trigger: true,
            title: <FormattedMessage id="logbookAdd" defaultMessage="Add logbook item" />,
            action: 'add',
            icon: 'bi-plus-circle',
            elementId: false,
            groupId: groupId
        })
    }

    const handleEditLogbookBtn = (logbookId) => {
        setModal({
            trigger: true,
            title: <FormattedMessage id="logbookEdit" defaultMessage="Edit logbook item" />,
            action: 'edit',
            icon: 'bi-pencil-square',
            elementId: logbookId,
            groupId: groupId
        })
    }

    const handleDeleteLogbookBtn = (logbookId) => {
        setModal({
            trigger: true,
            title: <FormattedMessage id="logbookDelete" defaultMessage="Delete logbook item" />,
            action: 'delete',
            icon: 'bi-trash',
            elementId: logbookId,
            groupId: groupId
        })
    }

    const handleModalCallBack = (update) => {
        setModal({
            trigger: false,
            title: false,
            action: false,
            icon: false,
            elementId: false,
            groupId: groupId
        })
        if (update) {
            getLogsBook()
        }
    }

    useEffect(() => {
        getLogsBook()

        const interval = setInterval(() => {
            getLogsBook()
        }, 60000) // 60000ms = 1 minute

        return () => {
            clearInterval(interval)
        }
    }, [missionId, userId, searchValue])

    if (!logsBook) {
        return (
            <Spinner />
        )
    }
    return (
        <div>
            <Table hover>
                <thead>
                    <tr className="table-secondary">
                        <th scope="col">#</th>
                        <th><i className="bi bi-tag-fill"></i> <FormattedMessage id="logbookType" defaultMessage="Type" /></th>
                        <th><i className="bi bi-book-half"></i> <FormattedMessage id="logbookTitle" defaultMessage="Title" /></th>
                        <th><i className="bi bi-people-fill"></i> <FormattedMessage id="logbookUser" defaultMessage="User" /></th>
                        <th className='text-end'><i className="bi bi-lightning-fill"></i> <FormattedMessage id="logbookMissions" defaultMessage="Missions" /></th>
                        <th className='text-end'><i className="bi bi-calendar-event-fill"></i> <FormattedMessage id="logbookCreatedAt" defaultMessage="Created at" /></th>
                        <th className='text-end'><i className="bi bi-calendar-event-fill"></i> <FormattedMessage id="logbookUpdatedAt" defaultMessage="Updated at" /></th>
                        <th className='text-end'>
                            <Button type="button" className="btn btn-sm btn-light btn-outline-dark" onClick={handleAddLogbookBtn}><i className="bi bi-plus-circle"></i> <FormattedMessage id="textAdd" defaultMessage="Add" /></Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {logsBook && logsBook.map((logbook, index) => {
                        return (
                            <tr key={"tr-logbook-" + logbook._id}>
                                <th scope="row">{index + 1 + pagination.skipItems}</th>
                                <td>{logbook.type}</td>
                                <td>{logbook.title}</td>
                                <td>{logbook.user.name}</td>
                                <td className='text-end'>
                                    {logbook.missions.length}&nbsp;
                                    <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        key={`tr-logbook-${logbook._id}-overlay`}
                                        placement="right"
                                        overlay={
                                            <Popover id={`tr-logbook-${logbook._id}-popover`}>
                                                <Popover.Header as="h3"><FormattedMessage id="logbookMissions" defaultMessage="Missions" /> {logbook.title}</Popover.Header>
                                                <Popover.Body>
                                                    <ListGroup variant="flush" className='p-0'>
                                                        {logbook.missions.map((mission) => {
                                                            return (
                                                                <ListGroup.Item key={`tr-logbook-${mission._id}-popover-${mission._id}`} className='p-1'>
                                                                    {mission.name}
                                                                </ListGroup.Item>
                                                            )
                                                        })}
                                                    </ListGroup>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i className="bi bi-lightning"></i>
                                    </OverlayTrigger>                                    
                                </td>
                                <td className='text-end'>
                                    {moment(logbook.createdAt).fromNow()}
                                </td>
                                <td className='text-end'>
                                    {moment(logbook.updatedAt).fromNow()}
                                </td>
                                <td>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <Button type="button" className="btn btn-sm btn-light btn-outline-primary" onClick={() => handleEditLogbookBtn(logbook._id)}><i className="bi bi-pencil-square"></i> <FormattedMessage id="textEdit" defaultMessage="Edit" /></Button>
                                        <Button type="button" className="btn btn-sm btn-light btn-outline-danger" onClick={() => handleDeleteLogbookBtn(logbook._id)}><i className="bi bi-trash"></i> <FormattedMessage id="textDelete" defaultMessage="Delete" /></Button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                    }
                </tbody>
            </Table>

            {pagination && pagination.totalPages > 1 && (
                <Pagination data={pagination} goToPageFunction={goToPage} />
            )}

            {modal.trigger && (
                <FormDataModal                
                    title={modal.title}
                    icon={modal.icon}
                    BodyContent={LogbookForm}
                    callBack={handleModalCallBack}
                    trigger={modal.trigger}
                    elementId={modal.elementId}
                    groupId={modal.groupId}
                    action={modal.action} />
            )}

        </div >
    )
}

export default LogbookList