import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class LogbookService {

    // Add new logbook to group :id
    addLogBook = async (id, logbook) => {
        return await axios.post(API_URL + '/groups/' + id + '/logbook', logbook, { headers: authHeader() })
    }

    // Update logbook :logbookId data from group :id
    updateLogBook = async (id, logbookId, logbook) => {
        return await axios.patch(API_URL + '/groups/' + id + '/logbook/' + logbookId, logbook, { headers: authHeader() })
    }

    // Get logbook :logbookId from group :id
    getLogBook = async (id, logbookId) => {
        return await axios.get(API_URL + '/groups/' + id + '/logbook/' + logbookId, { headers: authHeader() })
    }

    // Delete logbook :logbookId from group :id
    deleteLogBook = async (id, logbookId) => {
        return await axios.delete(API_URL + '/groups/' + id + '/logbook/' + logbookId, { headers: authHeader() })
    }

    // Get logsbook list from group :id filtering by mission, user and search query 
    getLogsBook = async ({ groupId, missionId = false, userId = false, pageNum = 1, search = '', limit = false }) => {
        let url = API_URL + '/groups/' + groupId + '/logbook?'

        if (missionId !== false) {
            url += 'missionId=' + missionId + '&'
        }
        if (userId !== false) {
            url += 'userId=' + userId + '&'
        }
        if (pageNum !== false) {
            url += 'page=' + pageNum + '&'
        }
        if (search !== false) {
            url += 'search=' + search + '&'
        }
        if (limit !== false) {
            url += 'limit=' + limit
        }

        return await axios.get(url, { headers: authHeader() });
    }

}

export default new LogbookService()