import { useEffect, useState } from "react"
import { useIntl } from 'react-intl'
import { useMap } from "react-leaflet"
import L from "leaflet"
import "leaflet-search/dist/leaflet-search.src.js"
import "leaflet-search/dist/leaflet-search.src.css"

const SearchButton = () => {
    const intl = useIntl()

    const map = useMap()

    const [layerAdded, setLayerAdded] = useState(false)
    const [poiLayers, setPoiLayers] = useState(new L.layerGroup())

    useEffect(() => {
        if (!map) return

        const filterLayersAndOrder = (text, records) => {
            text = text.replace(/[.*+?^${}()|[\]\\]/g, '') // Sanitize: remove all special characters
            if (text === '') {
                return []
            }
            const regSearch = new RegExp(text, 'i')
            const filteredRecords = Object.keys(records)
                .filter(key => regSearch.test(key))
                .map(key => [key, records[key]])
                .sort((a, b) => a[0].localeCompare(b[0], undefined, { numeric: true }))
            return Object.fromEntries(filteredRecords)
        }

        const resetPoiLayersStyle = () => {
            poiLayers.eachLayer(function (layer) {	//restore feature color
                layer.resetStyle()
            })
        }

        /*  
         *   https://opengeo.tech/maps/leaflet-search/
         */
        const searchControl = new L.control.search({
            layer: poiLayers,
            filterData: filterLayersAndOrder,
            initial: false,
            propertyName: '_searchByName', // This property is defined in utils NormalizeGeojsonLayerProperties.js for this map search function
            marker: {
                //icon: new L.Icon({iconUrl:'data/custom-icon.png', iconSize: [20,20]}),
                animate: true,
                circle: {
                    radius: 20,
                    weight: 3,
                    stroke: true,
                    color: '#000',
                    opacity: 1,
                    fill: false
                }
            },
            hideMarkerOnCollapse: true,
            //autoCollapse: true,
            autoType: false,
            minLength: 2,
            textErr: 'Location not found',
            textCancel: 'Cancel',
            textPlaceholder: intl.formatMessage({ id: 'mapSearchLayers', defaultMessage: 'Search in open layers' }),
            position: 'topleft',
            buildTip: function (text, val) {
                var layerName = val.layer.feature.properties._searchLayerName
                var layerIcon = val.layer.feature.properties._searchLayerIcon
                return '<a href="#" class="' + layerName + '">' + text + ' <b>[' + layerIcon + ' ' + layerName + ']</b></a>'
            },
            moveToLocation: function (latlng, title, map) {
                resetPoiLayersStyle()
                const featureType = latlng.layer.feature.geometry.type
                const zoom = (featureType === "Point") ? 18 : map.getBoundsZoom(latlng.layer.getBounds())
                map.setView(latlng, zoom) // access the zoom
            }

        })

        searchControl.on('search:locationfound', function (e) {
            const featureType = e.layer.feature.geometry.type
            if (featureType !== "Point") {
                e.layer.setStyle({ fillColor: '#3f0', color: '#0f0' })
            }
            if (e.layer._popup)
                e.layer.openPopup()

        }).on('search:collapsed', function (e) {
            resetPoiLayersStyle()
        }).on('search:cancel', function (e) {
            map.removeLayer(this._markerSearch)
            resetPoiLayersStyle()
        }).on('search:expanded', function (e) {
            // Update poilayers when expand searchControl
            setLayerAdded(true)
        })

        map.addControl(searchControl)

    }, [map])

    useEffect(() => {
        if (!layerAdded) return

        const setLayers = () => {
            const layers = []
            map.eachLayer(function (layer) {
                if (layer instanceof L.GeoJSON) {
                    layers.push(layer)
                }
            })
    
            poiLayers.clearLayers()
    
            layers.map((layer, index) => {
                return poiLayers.addLayer(layer)
            })
    
            setLayerAdded(false)
        }
    
        setLayers()

    }, [layerAdded])

    return null
}

export default SearchButton
