import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class ProfileService {

  // Get auth user profile data
  getProfile = async () => {
    return await axios.get(API_URL + '/profile', { headers: authHeader() })
  }

  // Update auth user profile data
  updateProfile = async (user) => {
    return await axios.patch(API_URL + '/profile', user, { headers: authHeader() })
  }

  // Delete auth user profile data
  deleteProfile = async () => {
    return await axios.delete(API_URL + '/profile', { headers: authHeader() })
  }

}

export default new ProfileService()