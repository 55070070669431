import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

const ChartCard = ({ header = false, footer = false, icon, iconSize = 50, iconColor = '#f9cf35', title, titleSize = 20, text, textSize = 50, border = false }) => {
  return (
    <Card
      border={border ? border : ''}
      bg="light"
      text="black"
      className=""
    >
      {header &&
        <Card.Footer>{header}</Card.Footer>
      }
      <Card.Body>
        <Row>
          <Col xs={4} className="align-items-center justify-content-center text-center">
            <span style={{ color: iconColor, fontSize: iconSize }}>{icon}</span>
          </Col>
          <Col xs={8} className="text-right">
            <h5 style={{ fontSize: titleSize, textAlign: 'right' }}>{title}</h5>
            <h1 style={{ fontSize: textSize, textAlign: 'right' }}>{text}</h1>
          </Col>
        </Row>
      </Card.Body>
      {footer &&
        <Card.Footer>{footer}</Card.Footer>
      }
    </Card>
  )
}

export default ChartCard