import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class EventsService {

  // Get events list
  getEvents = async (pageNum, search = '', limit = false) => {
    return await axios.get(API_URL + '/events?page=' + pageNum + '&search=' + search + '&limit=' + limit, { headers: authHeader() })
  }

  // Get all active events list
  getActiveEvents = async () => {
    return await axios.get(API_URL + '/events?active=true', { headers: authHeader() })
  }

  // Add new event
  addEvent = async (event) => {
    return await axios.post(API_URL + '/events', event, { headers: authHeader() })
  }

  // Update event data
  updateEvent = async (event, id) => {
    return await axios.patch(API_URL + '/events/' + id, event, { headers: authHeader() })
  }

  // Get event data
  getEvent = async (id) => {
    return await axios.get(API_URL + '/events/' + id, { headers: authHeader() })
  }

  // Delete event 
  deleteEvent = async (id) => {
    return await axios.delete(API_URL + '/events/' + id, { headers: authHeader() })
  }

  // Get event stats
  getEventStats = async (id) => {
    return await axios.get(API_URL + '/events/' + id + '/stats', { headers: authHeader() })
  }

}

export default new EventsService()