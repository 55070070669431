import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import ListGroup from 'react-bootstrap/ListGroup'

import moment from 'moment'

import ConfigService from 'services/ConfigService'
import TasksService from 'services/TasksService'

import { taskStatusProperties, taskResultProperties } from '../../helpers/Tasks'

import Spinner from "components/spinner/Spinner"
import InspectionModal from 'components/modals/InspectionModal'
import ReportModal from 'components/modals/ReportModal'
import Pagination from "components/pagination/TablePagination";

const TasksList = ({ groupId, missionId, userId, searchValue, callBack }) => {

    const fronConfig = ConfigService.getFrontConfig()

    const [modalTrigger, setModalTrigger] = useState(false)
    const [modalType, setModalType] = useState(false)
    const [taskSelected, setTaskSelected] = useState(false)

    const [reportModalTrigger, setReportModalTrigger] = useState(false)

    const [tasks, setTasks] = useState(false)
    const [pagination, setPagination] = useState(false)

    const getTasks = async (pageNum = 1) => {
        const options = {
            groupId: groupId,
            missionId: missionId,
            userId: userId,
            search: searchValue,
            pageNum: pageNum,
            limit: fronConfig.app.itemsListLimit
        }

        await TasksService.getGroupTasks(options)
            .then(res => {
                setTasks(res.data.tasks)
                setPagination(res.data.pagination)
            },
                error => {
                    setTasks(false)
                })

    }

    const goToPage = (pageNum) => {
        getTasks(pageNum)
    }

    const handleEditTaskBtn = (task) => {
        setModalType('edit')
        setTaskSelected(task)
        setModalTrigger(true)
    }

    const handleDeleteTaskBtn = (task) => {
        setModalType('delete')
        setTaskSelected(task)
        setModalTrigger(true)
    }

    const handleModalCallBack = (update) => {
        setModalTrigger(false)
        if (update) {
            getTasks()
            callBack(true)
        }
    }

    const handleTaskReportBtn = (task) => {
        setTaskSelected(task)
        setReportModalTrigger(true)
    }

    const handleTaskReportModalCallBack = (update) => {
        setReportModalTrigger(false)
        if (update) {
            getTasks()
            callBack(true)
        }
    }

    useEffect(() => {
        getTasks()

        const interval = setInterval(() => {
            getTasks()
        }, 60000) // 60000ms = 1 minute

        return () => {
            clearInterval(interval)
        }
    }, [missionId, userId, searchValue])

    if (!tasks) {
        return (
            <Spinner />
        )
    }
    return (
        <div>
            <Table hover>
                <thead>
                    <tr className="table-secondary">
                        <th scope="col">#</th>
                        <th><i className="bi bi-clock-fill"></i> <FormattedMessage id="tasksStatus" defaultMessage="Status" /></th>
                        <th><i className="bi bi-house-fill"></i> <FormattedMessage id="tasksResult" defaultMessage="Result" /></th>
                        <th><i className="bi bi-clipboard2-fill"></i> <FormattedMessage id="tasksReference" defaultMessage="Reference" /></th>
                        <th><i className="bi bi-clipboard2-fill"></i> <FormattedMessage id="tasksText" defaultMessage="Task" /></th>
                        <th><i className="bi bi-lightning-fill"></i> <FormattedMessage id="missionsText" defaultMessage="Mission" /></th>
                        <th><i className="bi bi-people-fill"></i> <FormattedMessage id="tasksInspector" defaultMessage="Inspector" /></th>
                        <th className='text-end'><i className="bi bi-calendar-event-fill"></i> <FormattedMessage id="tasksCreatedAt" defaultMessage="Created at" /></th>
                        <th className='text-end'><i className="bi bi-calendar-event-fill"></i> <FormattedMessage id="tasksUpdatedAt" defaultMessage="Updated at" /></th>
                        <th className='text-end'></th>
                    </tr>
                </thead>
                <tbody>
                    {tasks && tasks.map((task, index) => {
                        task.statusProps = taskStatusProperties(task.status)
                        task.resultProps = taskResultProperties(task.result)

                        return (
                            <tr key={"tr-task-" + task._id}>
                                <th scope="row">{index + 1 + pagination.skipItems}</th>
                                <td className={'table-' + task.statusProps.bgcolor}><i className={task.statusProps.icon}></i> {task.statusProps.text}</td>
                                <td className={'table-' + task.resultProps.bgcolor}><i className={task.resultProps.icon}></i> {task.resultProps.text}</td>
                                <td>{task.feature.ref}</td>
                                <td>{task.feature.name}</td>
                                <td>{task.mission.name}</td>
                                <td>
                                    <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        key={`tr-task-${task._id}-user-trigger`}
                                        placement="left"
                                        overlay={
                                            <Popover id={`tr-task-${task._id}-user-trigger-popover`}>
                                                <Popover.Header as="h3">{task.user.name}</Popover.Header>
                                                <Popover.Body>
                                                    <ListGroup variant="flush" className='p-0'>
                                                        <ListGroup.Item className='p-1'>
                                                            <i className="bi bi-briefcase"></i> {task.user.entity.name}
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className='p-1'>
                                                            <i className="bi bi-phone"></i> {task.user.phone}
                                                        </ListGroup.Item>
                                                        <ListGroup.Item className='p-1'>
                                                            <i className="bi bi-envelope"></i> {task.user.email}
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i className="bi bi-person-vcard"></i>
                                    </OverlayTrigger>
                                    &nbsp;{task.user.name}
                                </td>
                                <td className='text-end'>
                                    {moment(task.createdAt).fromNow()}
                                </td>
                                <td className='text-end'>
                                    {moment(task.formChangedAt).fromNow()}
                                </td>
                                <td>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <Button type="button" className="btn btn-sm btn-light btn-outline-primary" onClick={() => handleEditTaskBtn(task)}><i className="bi bi-pencil-square"></i> <FormattedMessage id="textEdit" defaultMessage="Edit" /></Button>
                                        <Button type="button" className={task.statusProps.text === 'Finished' ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-secondary'} onClick={() => handleTaskReportBtn(task)}><i className="bi bi-filetype-pdf"></i> <FormattedMessage id="textReport" defaultMessage="Report" /></Button>
                                        <Button type="button" className="btn btn-sm btn-light btn-outline-danger" onClick={() => handleDeleteTaskBtn(task)}><i className="bi bi-trash"></i> <FormattedMessage id="textDelete" defaultMessage="Delete" /></Button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                    }
                </tbody>
            </Table>

            {pagination && pagination.totalPages > 1 && (
                <Pagination data={pagination} goToPageFunction={goToPage} />
            )}

            {modalTrigger && (
                <InspectionModal show={modalTrigger} modalType={modalType} task={taskSelected} callBack={handleModalCallBack} />
            )}

            {reportModalTrigger && (
                <ReportModal show={reportModalTrigger} task={taskSelected} callBack={handleTaskReportModalCallBack} />
            )}

        </div >
    )
}

export default TasksList