import React, { useEffect, useState } from "react"
import { useIntl, FormattedMessage } from 'react-intl'
import { useMap, LayerGroup, GeoJSON, Popup, Tooltip } from "react-leaflet"
import Table from 'react-bootstrap/Table'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'

import styles from "./usersTasksButton.module.css"
import L from "leaflet"

import _ from 'lodash'

import moment from 'moment'

import TasksService from 'services/TasksService'

import InspectionModal from '../../modals/InspectionModal'
import { taskStatusProperties, taskResultProperties } from 'helpers/Tasks'
import { markerIcon } from "helpers/Markers"
import { generateRandomColors } from "helpers/Colors"

const UsersTasksButton = ({ groupId, missionId, userId, searchValue, mapSliderValue, refresh, onError }) => {
    const intl = useIntl()

    const map = useMap()

    const [tasks, setTasks] = useState(false)
    const [usersColors, setUsersColors] = useState({})

    const [modalTrigger, setModalTrigger] = useState(false)
    const [modalType, setModalType] = useState(false)
    const [taskSelected, setTaskSelected] = useState(false)

    const [showTasks, setShowTasks] = useState(false)

    const getTasks = async () => {
        const mapBounds = map.getBounds()
        const options = {
            groupId: groupId,
            missionId: missionId,
            userId: userId,
            search: searchValue,
            lonbl: mapBounds._southWest.lng,
            latbl: mapBounds._southWest.lat,
            lonur: mapBounds._northEast.lng,
            latur: mapBounds._northEast.lat,
            timestamp: mapSliderValue
        }

        await TasksService.getGroupTasks(options).then(
            response => {
                setTasks(response.data.tasks)
                assignUsersColors(response.data.tasks)
                onError(false)
            },
            error => {
                onError(error)
            })

    }

    const assignUsersColors = (tasksData) => {
        const colors = generateRandomColors(200)
        const usersColorsObject = usersColors
        let userIndex = 0
        tasksData.forEach((task) => {
            const userId = task.user._id
            if (!usersColorsObject[userId]) {
                usersColorsObject[userId] = colors[userIndex]
                userIndex = (userIndex + 1) % colors.length
            }
        })

        setUsersColors(usersColorsObject)
    }

    const handleFeatureClick = (geoJ) => {
        if (geoJ.layer.feature.geometry.type !== 'Point') {
            geoJ.layer.bringToBack()
        }
    }

    const handleFeatureMouseOver = (geoJ, featureColor) => {
        //if (!taskGeoLayerRef.current) return

        if (geoJ.layer.feature.geometry.type !== "Point") {
            geoJ.layer.setStyle({ color: "black", fillColor: featureColor, fillOpacity: 0.5 })
        } else {
            if (geoJ.layer.getElement() !== null) {
                geoJ.layer.getElement().querySelector('path').style.fill = featureColor
            }
        }
    }

    const handleFeatureMouseOut = (geoJ, featureColor) => {
        //if (!taskGeoLayerRef.current) return

        if (geoJ.layer.feature.geometry.type !== "Point") {
            geoJ.layer.setStyle({ color: 'black', fillColor: featureColor, fillOpacity: 0.5 })
        } else {
            if (geoJ.layer.getElement() !== null) {
                geoJ.layer.getElement().querySelector('path').style.fill = featureColor
            }
        }
    }

    const handleEditTaskBtn = (task) => {
        setModalType('edit')
        setTaskSelected(task)
        setModalTrigger(true)
    }

    const handleDeleteTaskBtn = (task) => {
        setModalType('delete')
        setTaskSelected(task)
        setModalTrigger(true)
    }

    const handleModalCallBack = (update) => {
        setModalTrigger(false)
        if (update) {
            getTasks()
        }
    }

    useEffect(() => {
        if (!map) return
        // create custom button
        const customControl = L.Control.extend({
            // button position
            options: {
                position: "topright",
                className: `${styles.usersTasksButton} leaflet-bar`,
                html: '<i class="bi bi-people-fill"></i>',
                style: "width: 34px; height: 34px; left: 0; margin-top: 0; display: flex; cursor: pointer; justify-content: center; font-size: 1rem;",
            },

            // method
            onAdd: function (map) {
                this._map = map
                const button = L.DomUtil.create("div")
                L.DomEvent.disableClickPropagation(button)

                button.title = intl.formatMessage({ id: 'mapUsersTasks', defaultMessage: 'Display users tasks' })
                button.innerHTML = this.options.html
                button.className = this.options.className
                button.setAttribute("style", this.options.style)

                L.DomEvent.on(button, "click", this._clicked, this)

                return button
            },
            _clicked: function (e) {
                L.DomEvent.stopPropagation(e)

                const usersTasksBtnActive = document.querySelector(`.${styles.usersTasksButton}`)
                const usersTasksBtn = usersTasksBtnActive.classList.contains(styles.usersTasksActive)
                // add/remove class from task button
                usersTasksBtnActive.classList[usersTasksBtn ? "remove" : "add"](styles.usersTasksActive)

                // Click funtion
                ///////////////////////////
                setShowTasks(!usersTasksBtn)

                return
            }
        })

        // adding new button to map control
        map.addControl(new customControl())
    }, [map])


    useEffect(() => {
        if (!map) return
        if (!showTasks) return

        getTasks()

        const getTasksThrottled = _.throttle(() => {
            getTasks()
        }, 1000) // 1000ms 

        const onMoveEnd = () => {
            getTasksThrottled()
        }

        const interval = setInterval(() => {
            getTasks()
        }, 60000) // 60000ms = 1 minute

        map.on('moveend', onMoveEnd)
        return () => {
            map.off('moveend', onMoveEnd)
            getTasksThrottled.cancel()
            clearInterval(interval)
        }

    }, [showTasks, missionId, userId, searchValue, mapSliderValue, refresh])

    return (
        map && showTasks && tasks && (
            <>
                <LayerGroup
                    attribution="Civilio users tasks"
                    color="red"
                    key={"lg-users-tasks-" + groupId}
                >
                    {tasks && tasks.map((task, index) => {
                        task.feature.type = "Feature"
                        task.feature.statusProps = taskStatusProperties(task.status)
                        task.feature.resultProps = taskResultProperties(task.result)
                        return (
                            <GeoJSON
                                key={"lg-users-task-" + groupId + "-fg-feature-" + task._id}
                                data={task.feature}
                                weight={2}
                                opacity={0.5}
                                color={"black"}
                                //dashArray={3}
                                fillOpacity={0.5} //0.3
                                fillColor={usersColors[task.user._id]}
                                eventHandlers={{
                                    click: (geoJ) => {
                                        handleFeatureClick(geoJ, usersColors[task.user._id])
                                    },
                                    mouseover: (geoJ) => {
                                        handleFeatureMouseOver(geoJ, 'black')
                                    },
                                    mouseout: (geoJ) => {
                                        handleFeatureMouseOut(geoJ, usersColors[task.user._id])
                                    },
                                    popupopen: (geoJ) => {
                                        //handleFeaturePopupOpen(geoJ)
                                    },
                                    popupclose: (geoJ) => {
                                        handleFeatureMouseOut(geoJ, usersColors[task.user._id])
                                    },
                                    add: (geoJ) => {
                                    },
                                    remove: (geoJ) => {
                                    }
                                }}
                                pointToLayer={function (geoJsonPoint, latlng) {
                                    return L.marker(latlng, { icon: markerIcon(usersColors[task.user._id]) })
                                }}
                            >
                                <Tooltip direction="top" offset={[0, 0]} opacity={1} sticky={true} >
                                    {task.feature.name}
                                </Tooltip>
                                <Popup
                                    key={"lg-users-task-" + groupId + "-pop-" + task._id}
                                    maxWidth={500}
                                >
                                    <p><i className="bi bi-clipboard2-fill"></i> <FormattedMessage id="tasksLayerName" defaultMessage="Tasks" /> {index + 1}/{tasks.length}</p>
                                    <div className="text-primary">
                                        <hr />
                                    </div>

                                    <p className="h6">{task.feature.name}</p>

                                    <p className="text-end"><small className="text-end">{moment(task.createdAt).fromNow()}</small></p>

                                    <p><i className="bi bi-lightning-fill"></i> {task.mission.name}</p>
                                    <p><i className="bi bi-layers-half"></i> {task.feature.layer}</p>

                                    <Table>
                                        <thead>
                                            <tr className="table-secondary">
                                                <th scope="col"><i className="bi bi-hash"></i></th>
                                                <th className='text-end'><i className="bi bi-people-fill"></i> <FormattedMessage id="tasksInspector" defaultMessage="Inspector" /></th>
                                                <th className='text-end'><i className="bi bi-envelope-fill"></i> <FormattedMessage id="usersEmail" defaultMessage="E-mail" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">{index + 1}</th>
                                                <td className='text-end'>{task.user.name}</td>
                                                <td className='text-end'>{task.user.email}</td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    <div className="text-secondary">
                                        <hr />
                                    </div>

                                    <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                                        <Button type="button" className="btn btn-sm btn-light btn-outline-primary" onClick={() => handleEditTaskBtn(task)}><i className="bi bi-pencil-square"></i> <FormattedMessage id="tasksEdit" defaultMessage="Edit task" /></Button>
                                        <Button type="button" className="btn btn-sm btn-light btn-outline-danger" onClick={() => handleDeleteTaskBtn(task)}><i className="bi bi-trash"></i> <FormattedMessage id="tasksDelete" defaultMessage="Delete task?" /></Button>
                                    </div>

                                    <div className="text-primary">
                                        <hr />
                                    </div>
                                </Popup>
                            </GeoJSON>
                        )
                    })

                    }

                </LayerGroup>

                {modalTrigger && (
                    <InspectionModal show={modalTrigger} modalType={modalType} task={taskSelected} callBack={handleModalCallBack} />
                )}
            </>
        )
    )
}

export default UsersTasksButton
