import React from 'react'
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js'
import { Radar } from 'react-chartjs-2'

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
)

const exampleData = {
    labels: ['Thing 1', 'Thing 2', 'Thing 3', 'Thing 4', 'Thing 5', 'Thing 6'],
    datasets: [
        {
            label: '# of Votes',
            data: [2, 9, 3, 5, 2, 3],
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
        },
    ],
}

const RadarChart = ({ title = false, data = exampleData, displayLegend = false }) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: displayLegend,
                position: 'bottom',
            },
            title: {
                display: title ? true : false,
                text: title,
            },
            tooltip: {
                mode: 'index', // Agrupa los tooltips por etiquetas
                intersect: true,
                position: 'average', // average - nearest
            },
        },
    }

    return <Radar options={options} data={data} />
}

export default RadarChart
