// Objeto JSON plano a objeto JSON anidado
export const flattenToNested = (flatObject) => {
    const nestedObject = {}

    for (const property in flatObject) {
        const value = flatObject[property]
        const properties = property.split('.')

        let currentObject = nestedObject

        for (let i = 0; i < properties.length - 1; i++) {
            const currentProperty = properties[i]

            // Check if the current property should be treated as an array
            const isArrayElement = /\d+/.test(properties[i + 1])

            if (isArrayElement) {
                // Ensure the current property is initialized as an array
                currentObject[currentProperty] = currentObject[currentProperty] || []
            } else {
                // Ensure the current property is initialized as an object
                currentObject[currentProperty] = currentObject[currentProperty] || {}
            }

            // Move to the next level of the object or array
            currentObject = currentObject[currentProperty]
        }

        // Assign the value at the last property of the nested object or array
        const lastProperty = properties[properties.length - 1]
        currentObject[lastProperty] = value
    }

    return nestedObject
}

// Objeto JSON anidado a objeto JSON plano
export const nestedToFlatten = (nestedObject, prefix = '') => {
    const flattenObject = {}

    for (const key in nestedObject) {
        const value = nestedObject[key]
        const newKey = prefix ? `${prefix}.${key}` : key

        if (Array.isArray(value)) {
            // Si el valor es un array, asignar directamente al objeto plano sin aplanar
            flattenObject[newKey] = value
        } else if (typeof value === 'object' && value !== null) {
            // Si el valor es un objeto, llamar a la función recursivamente
            Object.assign(flattenObject, nestedToFlatten(value, newKey))
        } else {
            // Si no es un objeto o array, asignar directamente al objeto plano
            flattenObject[newKey] = value
        }
    }

    return flattenObject
}
