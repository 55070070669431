import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useMap } from 'react-leaflet'
import L from 'leaflet'

const GetCoordinates = () => {
  const intl = useIntl()

  const map = useMap()

  const copyToClipboard = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy)
    } catch (err) {
      console.error('Copy to clipboard error:', err)
    }
  }

  useEffect(() => {
    if (!map) return
    const info = L.DomUtil.create('div', 'legend')

    const positon = L.Control.extend({
      options: {
        position: 'bottomleft'
      },

      onAdd: function () {
        info.textContent = intl.formatMessage({ id: 'mapGetCoordinates', defaultMessage: 'Click to get coordinates' })
        return info
      }
    })

    map.on('click', (e) => {
      info.textContent = "Lat: " + e.latlng.lat.toFixed(4) + " Lng:" + e.latlng.lng.toFixed(4) + ' Zoom:' + map.getZoom()
      copyToClipboard(info.textContent)
    })

    map.addControl(new positon())

  }, [map])


  return null

}

export default GetCoordinates
