import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class ChatRoomsService {

    // Add new chatroom item to group :id
    addChatRoom = async (id, chatRoom) => {
        return await axios.post(API_URL + '/groups/' + id + '/chatroom', chatRoom, { headers: authHeader() })
    }

    // Update chatroom item :chatRoomId data from group :id
    updateChatRoom = async (id, chatRoomId, chatRoom) => {
        return await axios.patch(API_URL + '/groups/' + id + '/chatroom/' + chatRoomId, chatRoom, { headers: authHeader() })
    }

    // Get chatroom item :chatRoomId from group :id
    getChatRoom = async (id, chatRoomId) => {
        return await axios.get(API_URL + '/groups/' + id + '/chatroom/' + chatRoomId, { headers: authHeader() })
    }

    // Delete chatroom item :chatRoomId from group :id
    deleteChatRoom = async (id, chatRoomId) => {
        return await axios.delete(API_URL + '/groups/' + id + '/chatroom/' + chatRoomId, { headers: authHeader() })
    }

    // Get chatrooms items list from group :id filtering by user and search query 
    getChatRooms = async ({ groupId, userId = false, pageNum = 1, search = '', limit = false }) => {
        let url = API_URL + '/groups/' + groupId + '/chatroom?'

       if (userId !== false) {
            url += 'userId=' + userId + '&'
        }
        if (pageNum !== false) {
            url += 'page=' + pageNum + '&'
        }
        if (search !== false) {
            url += 'search=' + search + '&'
        }
        if (limit !== false) {
            url += 'limit=' + limit
        }

        return await axios.get(url, { headers: authHeader() });
    }

}

export default new ChatRoomsService()