const LocationEvents = (socket) => {

    const setEvents = (callBackSetData) => {

        // Send user position
        socket.on('userPosition', (data) => {
            callBackSetData(data)
        })

    }

    return {
        setEvents,
    }
}

export default LocationEvents