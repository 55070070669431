import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class UserLocationService {

    // Get latest users location
    getLatestUsersLocation = async ({ groupId, userId = false, search = '', timestamp = false, lonbl = false, latbl = false, lonur = false, latur = false }) => {
        let url = API_URL + '/usersLocation/' + groupId + '?'

        if (userId !== false) {
            url += 'userId=' + userId + '&'
        }
        if (search !== false) {
            url += 'search=' + search + '&'
        }
        if (timestamp !== false) {
            url += 'timestamp=' + timestamp + '&'
        }
        if (lonbl !== false) {
            url += 'lonbl=' + lonbl + '&'
        }
        if (latbl !== false) {
            url += 'latbl=' + latbl + '&'
        }
        if (lonur !== false) {
            url += 'lonur=' + lonur + '&'
        }
        if (latur !== false) {
            url += 'latur=' + latur + '&'
        }

        return await axios.get(url, { headers: authHeader() })
    }

}

export default new UserLocationService()