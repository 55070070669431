module.exports = {
    STATUS_LIST: ["PENDING", "IN PROGRESS", "CANCELLED", "FINISHED"],
    STATUS_PENDING: "PENDING",
    STATUS_IN_PROGRESS: "IN PROGRESS",
    STATUS_CANCELLED: "CANCELLED",
    STATUS_COMPLETED: "FINISHED",
    RESULT_LIST: ["NONE", "GREEN", "YELLOW", "RED", "BLACK"],
    RESULT_NONE: "NONE",
    RESULT_GREEN: "GREEN",
    RESULT_YELLOW: "YELLOW",
    RESULT_RED: "RED",
    RESULT_BLACK: "BLACK"
}