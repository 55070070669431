import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import GroupsService from 'services/GroupsService'

import DownloadTaskBtn from "components/DownloadTaskBtn"

import { AlertError, AlertLoading } from 'helpers/AlertHelper'

const Tools = ({ groupId, group }) => {

    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)

    const [geojsonData, setGeojsonData] = useState(null)

    const getGeoJSON = async () => {
        await GroupsService.getGroupGeoJSON(groupId).then(
            response => {
                setGeojsonData(JSON.stringify(response.data))
                setIsLoaded(true)
            },
            error => {
                setError(error)
            })
    }

    useEffect(() => {
        getGeoJSON()
    }, [])

    const ExportFormData = () => (
        <Card bg="light">
            <Card.Header className='p-1'>
                <h5>
                    <i className="bi bi-cloud-arrow-down-fill"></i> <FormattedMessage id="toolsExportTasks" defaultMessage="Export tasks results" />
                </h5>
            </Card.Header>
            <Card.Body className='p-1 text-center'>
                <DownloadTaskBtn
                    icon={<i className="bi bi-globe-europe-africa"></i>}
                    text={<FormattedMessage id="toolsDownloadGeoJson" defaultMessage="Download GeoJSON" />}
                    data={geojsonData.toString()}
                    filename={group.name + ' .geojson'}
                />
            </Card.Body>
            <Card.Footer className='p-1'>
            </Card.Footer>
        </Card >
    )

    if (error) {
        return (
            <AlertError error={error} />
        )
    } else if (!isLoaded) {
        return (
            <AlertLoading isLoaded={isLoaded} />
        )
    }

    return (
        <Row>
            <Col>
                {geojsonData &&
                    <ExportFormData />
                }
            </Col>
            <Col>
            </Col>
            <Col>
            </Col>
            <Col>
            </Col>
        </Row>
    )
}

export default Tools