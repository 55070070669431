import { useState, useEffect, useRef } from "react"
import Accordion from 'react-bootstrap/Accordion'
import ListGroup from 'react-bootstrap/ListGroup'
import Alert from 'react-bootstrap/Alert'

import moment from 'moment'

const GroupsList = ({ selectedGroupId, setSelectedGroupId, groupsList }) => {

    const handleSelectGroup = (val) => {
        setSelectedGroupId(val)
    }

    useEffect(() => {
        if (!selectedGroupId) return
    }, [selectedGroupId])

    return (
        <div style={{ marginBottom: 40 }}>
            {groupsList && groupsList.length > 0 ? (
                <>
                    <h5 className="bg-light p-1"><i className='bi bi-shield-fill'></i> Intervention Groups</h5>
                    <Accordion flush activeKey={selectedGroupId} onSelect={handleSelectGroup}>
                        {groupsList.map((group) => {
                            return (
                                <Accordion.Item key={'accordion-group-' + group._id} eventKey={group._id}>
                                    <Accordion.Header>
                                        <i className='bi bi-shield'></i>&nbsp;{group.name}
                                    </Accordion.Header>
                                    <Accordion.Body className="p-0">
                                        <ListGroup variant="flush">
                                            <ListGroup.Item variant="light"><i className="bi bi-calendar3"></i> {moment(group.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</ListGroup.Item>
                                            <ListGroup.Item variant="light"><i className="bi bi-calendar-event"></i> {group.event.name}</ListGroup.Item>
                                            <ListGroup.Item variant="light"><i className="bi bi-diagram-3-fill"></i> {group.name}</ListGroup.Item>
                                            <ListGroup.Item variant="light"><i className="bi bi-file-code-fill"></i> {group.form.title}</ListGroup.Item>
                                            <ListGroup.Item variant="light"><i className="bi bi-clipboard2-fill"></i> {group.numTasks}</ListGroup.Item>
                                        </ListGroup>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                    </Accordion >
                </>
            ) : (
                <div className="container p-1">
                    <Alert variant={'warning'}>
                        No groups to show!
                    </Alert>
                </div>
            )}

        </div>
    )
}

export default GroupsList