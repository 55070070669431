import React from 'react'
import Button from 'react-bootstrap/Button'

function DownloadTaskBtn({ icon, text, data, filename }) {
  const handleClick = () => {
    // Create a Blob object from the data
    const blob = new Blob([data], { type: 'text/plain;charset=utf-8' })

    // Create a URL for the blob
    const url = URL.createObjectURL(blob)

    // Create a download link and click it programmatically
    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = filename
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)

    // Release the URL object to free up memory
    URL.revokeObjectURL(url)
  }

  return (
    <Button variant="outline-primary" size="sm" onClick={handleClick}>{icon} {text} </Button>
  )
}

export default DownloadTaskBtn