import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import FormsService from '../services/FormsService'

import { ENTITY_SKILLS } from '../data/entities'

import { AlertMsg, AlertError, AlertLoading } from 'helpers/AlertHelper'

function FormsForm(props) {

    const [elementId, setElementId] = useState(props.elementId)
    const [action, setAction] = useState(props.action)
    const [skill, setSkill] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [schema, setSchema] = useState('')
    const [algorithm, setAlgorithm] = useState('')
    const [buttonTitle, setButtonTitle] = useState('')
    const [inputDisabled, setInputDisabled] = useState(false)
    const [formValid, setFormValid] = useState(false)

    const [showMsg, setShowMsg] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)

    const getData = async () => {
        await FormsService.getForm(elementId).then(
            response => {
                const schemaObj = response.data
                delete schemaObj._id
                delete schemaObj.createdAt
                delete schemaObj.__v

                setSkill(response.data.skill || '')
                setTitle(response.data.title || '')
                setSchema(JSON.stringify(schemaObj) || '')
                setDescription(response.data.description || '')
                setAlgorithm(response.data.algorithm || '')
                setIsLoaded(true)
            },
            error => {
                setError(error)
            })
    }

    useEffect(() => {

        if (elementId) {
            getData()
        }

        switch (action) {
            case 'edit':
                setButtonTitle(<FormattedMessage id="textSave" defaultMessage="Save" />)
                break
            case 'delete':
                setButtonTitle(<FormattedMessage id="textDelete" defaultMessage="Delete" />)
                setInputDisabled(true)
                break
            default:
                setButtonTitle(<FormattedMessage id="textAdd" defaultMessage="Add" />)
        }

    }, [])

    const onSubmit = () => {
        props.onSubmit()
    }

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFieldValue(name, value)
    }

    const setFieldValue = (fieldName, value) => {
        switch (fieldName) {
            case 'skill':
                setSkill(value)
                break
            case 'title':
                setTitle(value)
                break
            case 'schema':
                setSchema(value)
                break
            case 'description':
                setDescription(value)
                break
            case 'algorithm':
                setAlgorithm(value)
            default:
                break;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()
    
        const form = e.currentTarget
        if (!form.checkValidity()) {
            setFormValid(true)
            return
        }
    
        try {
            const formSchema = {
                ...JSON.parse(schema),
                skill,
                algorithm,
            }
    
            let response
    
            if (elementId && action === "edit") {
                response = await FormsService.updateForm(formSchema, elementId)
            } else if (action === "add") {
                response = await FormsService.addForm(formSchema)
            } else if (action === "delete") {
                response = await FormsService.deleteForm(elementId)
            }
    
            if (response) {
                setFormValid(false)
                onSubmit()
            }
        } catch (error) {
            setShowMsg({ text: error || "An error occurred", style: 'danger' });
            setShowAlert(true)
        }
    }

    if (error) {
        return (
            <AlertError error={error} />
        )
    } else if (elementId && !isLoaded) {
        return (
            <AlertLoading isLoaded={isLoaded} />
        )
    }

    return (
        <div>
            <Form noValidate validated={formValid} onSubmit={handleSubmit}>
                {elementId && (
                    <>
                        <Form.Group className="mb-3" controlId="id">
                            <Form.Label>Id:</Form.Label>
                            <Form.Control
                                type="text"
                                name="id"
                                value={elementId}
                                disabled={"disabled"}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="title">
                            <Form.Label><FormattedMessage id="formsTitle" defaultMessage="Title" />:</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={title}
                                disabled={"disabled"}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="description">
                            <Form.Label><FormattedMessage id="formsDescription" defaultMessage="Description" />:</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                value={description}
                                disabled={"disabled"}
                                as="textarea"
                                rows={1}
                            />
                        </Form.Group>
                    </>
                )}

                <Form.Group className="mb-3" controlId="skill">
                    <Form.Label><FormattedMessage id="formsSkill" defaultMessage="Skill" />:</Form.Label>
                    <Form.Select
                        name="skill"
                        value={skill}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    >
                        <option value="" key="select-fskill-0"><FormattedMessage id="textSelect" defaultMessage="Select" /></option>
                        {ENTITY_SKILLS.map(skill =>
                            <option value={skill} key={skill}>{skill}</option>
                        )}
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="formsSkillInvalid" defaultMessage="Select an entity skill" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="schema">
                    <Form.Label>JSON Schema:</Form.Label>
                    <Form.Control
                        name="schema"
                        value={schema}
                        as="textarea"
                        placeholder='JSON schema data'
                        disabled={inputDisabled && ("disabled")}
                        rows={5}
                        onChange={handleInputChange}
                        required="required"
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="formsSchemaInvalid" defaultMessage="Paste a JSON SCHEMA" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="algorithm">
                    <Form.Label>JSON Algorithm:</Form.Label>
                    <Form.Control
                        name="algorithm"
                        value={algorithm}
                        as="textarea"
                        placeholder='JSON Algorithm data'
                        disabled={inputDisabled && ("disabled")}
                        rows={5}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <AlertMsg message={showMsg.text} variant={showMsg.style} dismissible onCloseHandler={() => setShowAlert(false)} show={showAlert} />

                <div className="d-grid gap-2">
                    {action === "add" && (
                        <Button
                            className="btn btn-dark"
                            type="submit"
                        >
                            <i className="bi bi-plus-circle"></i> {buttonTitle}
                        </Button>
                    )}

                    {action === "edit" && (
                        <Button
                            className="btn btn-primary"
                            type="submit"
                        >
                            <i className="bi bi-save2"></i> {buttonTitle}
                        </Button>
                    )}

                    {action === "delete" && (
                        <Button
                            className="btn btn-danger"
                            type="submit"
                        >
                            <i className="bi bi-trash"></i> {buttonTitle}
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    )

}

export default FormsForm