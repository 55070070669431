import React, { useState, useRef, useEffect, useCallback } from "react"
import L from "leaflet"
import { useMap, TileLayer, Marker, Popup, Tooltip, FeatureGroup, Circle, LayersControl, LayerGroup } from "react-leaflet"
import { EditControl } from "react-leaflet-draw"

import LayersService from "services/LayersService"
import FeaturesService from "services/FeaturesService"

import { markerIcon } from "helpers/Markers"
import FeatureDrawPopup from "../popup/FeatureDrawPopup"

const DrawTools = ({ group, updateGroupData }) => {
    const map = useMap()

    const drawLayerRef = useRef()
    const drawFGref = useRef(null)

    const drawLayer = group.drawLayer
    //console.log(drawLayer)

    const [show, setShow] = useState(true)
    const [geojson, setGeojson] = useState(false)

    const getFeatures = useCallback(() => {
        const mapBounds = map.getBounds()
        const mapZoom = map.getZoom()

        if (mapZoom >= drawLayer.minZoom && mapZoom <= drawLayer.maxZoom) {
            LayersService.bbox(drawLayer._id, mapBounds._southWest.lng, mapBounds._southWest.lat, mapBounds._northEast.lng, mapBounds._northEast.lat).then(
                response => {
                    setGeojson(response.data)
                },
                error => {
                    setGeojson(false)
                }
            )
        } else {
            setGeojson(false)
        }
    }, [])

    const processGeojson = (geojson) => {
        // Add geojson to layer
        L.geoJSON(geojson).eachLayer((layer) => {
            if (
                layer instanceof L.Polyline ||
                layer instanceof L.Polygon ||
                layer instanceof L.Marker
            ) {
                if (layer?.feature?.properties.radius && drawFGref.current) {

                    const circleLayer = new L.Circle(layer.feature.geometry.coordinates.slice().reverse(), {
                        stroke: true,
                        color: drawLayer.featuresColor,
                        weight: 4,
                        opacity: 0.5,
                        fill: true,
                        fillColor: drawLayer.featuresColor,
                        fillOpacity: 0.2,
                        showArea: true,
                        clickable: true,
                        radius: layer.feature?.properties.radius,
                        id: layer.feature?.id,
                        ref: layer.feature?.properties.ref,
                        name: layer.feature?.properties.name,
                        description: layer.feature?.properties.description
                    })
                    //.addTo(drawFGref.current)
                    circleLayer.feature = layer?.feature
                    circleLayer.bindTooltip(layer.feature?.properties.name !== '' ? layer.feature?.properties.name : layer.feature?.id, { direction: "top", sticky: true })
                    drawFGref.current?.addLayer(circleLayer)
                } else {
                    //layer.bindPopup('A popup!')   
                    layer.bindTooltip(layer.feature?.properties.name !== '' ? layer.feature?.properties.name : layer.feature?.id, { direction: "top", sticky: true })
                    if (layer.feature?.geometry.type === 'Point') {
                        layer.setIcon(markerIcon(drawLayer.featuresColor))
                    } else if (layer.feature?.geometry.type === 'LineString') {
                        layer.setStyle({
                            guidelineDistance: 10,
                            color: drawLayer.featuresColor,
                            weight: 3
                        })
                    } else {
                        layer.setStyle({
                            stroke: true,
                            color: drawLayer.featuresColor,
                            weight: 4,
                            opacity: 0.5,
                            fill: true,
                            fillColor: drawLayer.featuresColor,
                            fillOpacity: 0.2,
                            showArea: true,
                            clickable: true
                        })
                    }
                    drawFGref.current?.addLayer(layer)
                }
            }
        })
    }

    useEffect(() => {
        if (!map) return
        if (!show) return

        // Remove the Clear all button from remove control buttoms        
        L.EditToolbar.Delete.include({
            removeAllLayers: false
        })

        getFeatures()
        const onMoveEnd = () => {
            getFeatures()
        }

        map.on('moveend', onMoveEnd)
        return () => {
            map.off('moveend', onMoveEnd)
        }
    }, [map, show, getFeatures])

    useEffect(() => {
        if (!drawFGref.current) return
        drawFGref.current?.clearLayers()

        if (drawFGref.current?.getLayers().length === 0 && geojson) {
            processGeojson(geojson)
        }
    }, [geojson])

    const handleChange = (e) => {
        const type = e.type
        switch (type) {
            case "draw:created":
                const createdLayer = e.layer
                const layerType = e.layerType
                addFeature(createdLayer, layerType)
                break
            case "draw:edited":
                const editedLayers = e.layers.getLayers()
                editedLayers.map(editedLayer => {
                    editFeature(editedLayer)
                })
                break
            case "draw:deleted":
                const deletedLayers = e.layers.getLayers()
                deletedLayers.map(deletedLayer => {
                    deleteFeature(deletedLayer)
                })
                break
        }
    }

    /*
    onEdited	function	hook to leaflet-draw's draw:edited event
    onCreated	function	hook to leaflet-draw's draw:created event
    onDeleted	function	hook to leaflet-draw's draw:deleted event
    onMounted	function	hook to leaflet-draw's draw:mounted event
    onEditStart	function	hook to leaflet-draw's draw:editstart event
    onEditStop	function	hook to leaflet-draw's draw:editstop event
    onDeleteStart	function	hook to leaflet-draw's draw:deletestart event
    onDeleteStop	function	hook to leaflet-draw's draw:deletestop event
    onDrawStart	function	hook to leaflet-draw's draw:drawstart event
    onDrawStop	function	hook to leaflet-draw's draw:drawstop event
    onDrawVertex	function	hook to leaflet-draw's draw:drawvertex event
    onEditMove	function	hook to leaflet-draw's draw:editmove event
    onEditResize	function	hook to leaflet-draw's draw:editresize event
    onEditVertex	function	hook to leaflet-draw's draw:editvertex event
    */

    const addFeature = (layer, layerType) => {
        const geoJsonFeature = layer.toGeoJSON()

        const featureAdd = {
            geometry: geoJsonFeature.geometry,
            properties: {
                radius: layerType === 'circle' ? layer.getRadius() : null,
                ref: '',
                name: '',
                description: ''
            }
        }
        FeaturesService.addFeature({ layerId: drawLayer._id, feature: featureAdd }).then(
            response => {
                drawFGref.current?.removeLayer(layer)
                processGeojson(response.data)
            },
            error => {

            }
        )
    }

    const editFeature = (editedLayer) => {
        const geoJsonFeature = editedLayer.toGeoJSON()
        const feature = {
            geometry: geoJsonFeature.geometry,
            properties: {
                ...geoJsonFeature.properties,
                radius: typeof editedLayer.getRadius === 'function' ? editedLayer.getRadius() : null
            }
        }
        FeaturesService.updateFeature({ layerId: drawLayer._id, featureId: editedLayer.feature.id, feature })
    }

    const deleteFeature = (deletedLayer) => {
        FeaturesService.deleteFeature({ layerId: drawLayer._id, featureId: deletedLayer.feature.id })
    }

    return (
        <LayersControl.Overlay
            key={"lc-draw-0"}
            checked={show}
            name={drawLayer.name}
        >
            <LayerGroup
                ref={drawLayerRef}
                attribution={"Civilio Draw control"}
                color="red"
                key={"lg-draw-1"}
                eventHandlers={{
                    add: (layerG) => {
                        setShow(true)
                    },
                    remove: (layerG) => {
                        setShow(false)
                    }
                }}
            >
                <FeatureGroup
                    ref={drawFGref}
                    eventHandlers={{
                        add: (featureGr) => {
                            //_onFeatureGroupReady(featureGr.target)
                        },
                        remove: (featureGr) => {
                        }
                    }}
                >
                    <EditControl
                        position="topleft"
                        onEdited={handleChange}
                        onCreated={handleChange}
                        onDeleted={handleChange}
                        edit={{
                            remove: true,
                            //featureGroup: drawFGref
                        }}
                        draw={{
                            polyline: {
                                icon: new L.DivIcon({
                                    iconSize: new L.Point(8, 8),
                                    className: "leaflet-div-icon leaflet-editing-icon"
                                }),
                                shapeOptions: {
                                    guidelineDistance: 10,
                                    color: drawLayer.featuresColor,
                                    weight: 3
                                }
                            },
                            rectangle: {
                                shapeOptions: {
                                    stroke: true,
                                    color: drawLayer.featuresColor,
                                    weight: 4,
                                    opacity: 0.5,
                                    fill: true,
                                    fillColor: drawLayer.featuresColor,
                                    fillOpacity: 0.2,
                                    showArea: true,
                                    clickable: true
                                },
                            },
                            circlemarker: false,
                            circle: {
                                shapeOptions: {
                                    stroke: true,
                                    color: drawLayer.featuresColor,
                                    weight: 4,
                                    opacity: 0.5,
                                    fill: true,
                                    fillColor: drawLayer.featuresColor,
                                    fillOpacity: 0.2,
                                    showArea: true,
                                    clickable: true
                                },
                            },
                            polygon: {
                                shapeOptions: {
                                    stroke: true,
                                    color: drawLayer.featuresColor,
                                    weight: 4,
                                    opacity: 0.5,
                                    fill: true,
                                    fillColor: drawLayer.featuresColor,
                                    fillOpacity: 0.2,
                                    showArea: true,
                                    clickable: true
                                },
                            },
                            marker: {
                                icon: markerIcon(drawLayer.featuresColor)
                            }
                        }}
                    />
                    <FeatureDrawPopup drawLayer={drawLayer} group={group} updateGroupData={updateGroupData} />
                </FeatureGroup>
            </LayerGroup>
        </LayersControl.Overlay>
    )
}

export default DrawTools