import { capitalizeText } from '../utils/StringFormat'

/*
*   Return task status properties to show status in table list and map
*/
export const taskStatusProperties = (status) => {
    const taskStatuses = {
        'PENDING': {
            textcolor: 'dark',
            bgcolor: 'warning',
            icon: 'bi bi-clock',
            fillcolor: 'orange'
        },
        'IN PROGRESS': {
            textcolor: 'dark',
            bgcolor: 'info',
            icon: 'bi bi-clock',
            fillcolor: 'aqua'
        },
        'CANCELLED': {
            textcolor: 'white',
            bgcolor: 'secondary',
            icon: 'bi bi-x-circle',
            fillcolor: 'lightslategray'
        },
        'FINISHED': {
            textcolor: 'white',
            bgcolor: 'primary',
            icon: 'bi bi-check-circle',
            fillcolor: 'blue'
        }
    }

    if (taskStatuses[status]) {
        return {
            text: capitalizeText(status),
            ...taskStatuses[status]
        }
    }

    return {
        text: capitalizeText(status),
        bgcolor: 'danger',
        icon: 'bi bi-exclamation-fill',
        fillcolor: 'danger'
    }
}

/*
*   Return task result properties to show result in table list and map
*/
export const taskResultProperties = (result = "NONE") => {
    const taskResults = {
        'NONE': {
            textcolor: 'dark',
            bgcolor: 'light',
            icon: 'bi bi-house-gear-fill',
            fillcolor: 'grey'
        },
        'GREEN': {
            textcolor: 'dark',
            bgcolor: 'success',
            icon: 'bi bi-house-check-fill',
            fillcolor: 'green'
        },
        'YELLOW': {
            textcolor: 'dark',
            bgcolor: 'warning',
            icon: 'bi bi-house-exclamation-fill',
            fillcolor: 'yellow'
        },
        'RED': {
            textcolor: 'white',
            bgcolor: 'danger',
            icon: 'bi bi-house-slash-fill',
            fillcolor: 'red'
        },
        'BLACK': {
            textcolor: 'white',
            bgcolor: 'dark',
            icon: 'bi bi-house-x-fill',
            fillcolor: 'black'
        }
    }

    if (taskResults[result]) {
        return {
            text: capitalizeText(result),
            ...taskResults[result]
        }
    }

    return {
        text: capitalizeText(result),
        bgcolor: 'secondary',
        icon: 'bi bi-exclamation-fill',
        fillcolor: 'grey'
    }
}

/*
*   Add map leaflet-search button properties to the task
*/
export const taskSearchProperties = (task, featureIdInProperty, featureNameInProperty) => {
    const { feature: { properties }, ...rest } = task
    const featureName = properties[featureNameInProperty]

    return {
        ...rest,
        feature: {
            ...task.feature,
            properties: {
                ...properties,
                _featureIdInProperty: featureIdInProperty,
                _featureNameInProperty: featureNameInProperty,
                _searchByName: featureName,
                _searchLayerName: "Tasks",
                _searchLayerIcon: '<i class="bi bi-clipboard2-heart-fill"></i>'
            }
        }
    }
}
