export const generateRandomColors = (numColors) => {
    const colors = [];

    for (let i = 0; i < numColors; i++) {
        // generate a random 24-bit color code
        const colorCode = Math.floor(Math.random() * 16777215).toString(16);
        // pad the code with zeros if necessary
        const paddedColorCode = '#' + ('000000' + colorCode).slice(-6);
        colors.push(paddedColorCode);
    }

    return colors
}

export const generateColors = (numColors) => {
    const colors = []

    for (let i = 0; i < numColors; i++) {
        // generate a random hue value between 0 and 360
        const hue = Math.floor(Math.random() * 360)
        // set the saturation and brightness values to 100%
        const saturation = 100
        const brightness = 100
        // convert HSB color to RGB color
        const rgbColor = hsbToRgb(hue, saturation, brightness)
        // convert RGB color to hex color
        const hexColor = rgbToHex(rgbColor.r, rgbColor.g, rgbColor.b)
        colors.push(hexColor)
    }

    return colors
}

const hsbToRgb = (hue, saturation, brightness) => {
    hue /= 60
    saturation /= 100
    brightness /= 100
    const i = Math.floor(hue)
    const f = hue - i
    const p = brightness * (1 - saturation)
    const q = brightness * (1 - saturation * f)
    const t = brightness * (1 - saturation * (1 - f))
    let r, g, b
    switch (i) {
        case 0:
            r = brightness
            g = t
            b = p
            break
        case 1:
            r = q
            g = brightness
            b = p
            break
        case 2:
            r = p
            g = brightness
            b = t
            break
        case 3:
            r = p
            g = q
            b = brightness
            break
        case 4:
            r = t
            g = p
            b = brightness
            break
        default:
            r = brightness
            g = p
            b = q
    }
    return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) }
}

const rgbToHex = (r, g, b) => {
    return '#' + [r, g, b].map(c => c.toString(16).padStart(2, '0')).join('')
}
