import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Accordion from 'react-bootstrap/Accordion'

import TasksService from 'services/TasksService'

import ChartCard from 'components/card/ChartCard'
import ChartColsCard from 'components/card/ChartColsCard'

import { AlertError, AlertLoading } from 'helpers/AlertHelper'
import { userContent, userTimeContent, missionContent, missionTimeContent, averageTimeContent, taskStatusContent, taskStatusTimesContent, taskResultContent } from 'helpers/TasksStatsHelper'

import taskStatus from 'config/tasks'

const Statistics = ({ groupId, missionId, userId, searchValue, callBack }) => {

    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)

    const [tasksStats, setTasksStats] = useState({
        missionStats: {},
        userStats: {},
        stateStats: {},
        resultStats: {},
        timeStats: {}
    })

    const getTasksStats = async () => {
        const options = {
            groupId: groupId,
            missionId: missionId,
            userId: userId,
            search: searchValue,
            pageNum: 1,
            limit: 100000
        }

        await TasksService.getGroupTasksStats(options).then(
            res => {
                setTasksStats(res.data.tasksStats)
                setIsLoaded(true)
            },
            error => {
                setIsLoaded(false)
                setError(error)
            })
    }

    useEffect(() => {
        getTasksStats()

        const interval = setInterval(() => {
            getTasksStats()
        }, 60000) // 60000ms = 1 minute

        return () => {
            clearInterval(interval)
        }

    }, [missionId, userId, searchValue])

    const renderStatsMissions = (title, stats, timestats) => (
        <Card bg="light">
            <Card.Header className='p-1'>
                <ChartCard
                    icon={<i className="bi bi-lightning-fill"></i>}
                    title={title}
                    text={Object.keys(stats).length}
                    border={'light'}
                />
            </Card.Header>
            <Card.Body className="p-1">
                <Accordion defaultActiveKey="accord-mission-0" flush>
                    {Object.keys(stats).map((missionId, num) => {
                        return (
                            <Accordion.Item eventKey={'accord-mission-' + num} key={'stat-mission-' + num}>
                                <Accordion.Header><i className="bi bi-lightning-fill"></i>&nbsp;{stats[missionId].mission.name}</Accordion.Header>
                                <Accordion.Body className='p-0'>
                                    {stats[missionId] &&
                                        <>
                                            <ChartColsCard
                                                key={'accord-mission-stats-users-'.missionId}
                                                content={missionContent(stats[missionId])}
                                                border={'light'}
                                            //className={'mb-2'}
                                            />

                                            <ChartColsCard
                                                key={'accord-mission-stats-times-'.missionId}
                                                header={<h6><i className="bi bi-hourglass"></i> <FormattedMessage id="statsTimeline" defaultMessage="Timeline" /></h6>}
                                                content={missionTimeContent(stats[missionId])}
                                                border={'light'}
                                            //className={'mb-2'}
                                            />

                                            <ChartColsCard
                                                key={'accord-mission-stats-states-'.missionId}
                                                header={<h6><i className="bi bi-clipboard2-fill"></i> <FormattedMessage id="statsTasksStates" defaultMessage="Tasks states" /></h6>}
                                                content={taskStatusContent(stats[missionId])}
                                                footer={renderProgressBarStatus(stats[missionId])}
                                                border={'light'}
                                            //className={'mb-2'}
                                            />

                                            <ChartColsCard
                                                key={'accord-users-stats-result-'.missionId}
                                                header={<h6><i className="bi bi-house-fill"></i> <FormattedMessage id="statsInspections" defaultMessage="Inspections" /></h6>}
                                                content={taskResultContent(stats[missionId])}
                                                footer={renderProgressBarResult(stats[missionId])}
                                                border={'light'}
                                            //className={'mb-2'}
                                            />
                                        </>
                                    }
                                    {timestats[missionId] &&
                                        <ChartColsCard
                                            key={'accord-mission-stats-times-'.missionId}
                                            header={<h6><i className="bi bi-stopwatch-fill"></i> <FormattedMessage id="statsExecutionTime" defaultMessage="Execution time" /></h6>}
                                            content={averageTimeContent(timestats[missionId])}
                                            border={'light'}
                                        />
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>
            </Card.Body>
        </Card>
    )

    const renderStatsUsers = (title, stats, timestats) => (
        <Card bg="light">
            <Card.Header className='p-1'>
                <ChartCard
                    icon={<i className="bi bi-people-fill"></i>}
                    title={title}
                    text={Object.keys(stats).length}
                    border={'light'}
                />
            </Card.Header>
            <Card.Body className="p-1">
                <Accordion defaultActiveKey="accord-user-0" flush>
                    {Object.keys(stats).map((userId, num) => {
                        return (
                            <Accordion.Item eventKey={'accord-user-' + num} key={'stat-user-' + num}>
                                <Accordion.Header><i className="bi bi-person-fill"></i>&nbsp;{stats[userId].user.name}</Accordion.Header>
                                <Accordion.Body className='p-0'>
                                    {stats[userId] &&
                                        <>
                                            <ChartColsCard
                                                key={'accord-users-stats-tasks-'.userId}
                                                content={userContent(stats[userId])}
                                                border={'light'}
                                            />

                                            <ChartColsCard
                                                key={'accord-users-stats-times-'.userId}
                                                header={<h6><i className="bi bi-hourglass"></i> <FormattedMessage id="statsTimeline" defaultMessage="Timeline" /></h6>}
                                                content={userTimeContent(stats[userId])}
                                                border={'light'}
                                            />

                                            <ChartColsCard
                                                key={'accord-users-stats-states-'.userId}
                                                header={<h6><i className="bi bi-clipboard2-fill"></i> <FormattedMessage id="statsTasksStates" defaultMessage="Tasks states" /></h6>}
                                                content={taskStatusContent(stats[userId])}
                                                footer={renderProgressBarStatus(stats[userId])}
                                                border={'light'}
                                            />

                                            <ChartColsCard
                                                key={'accord-users-stats-result-'.userId}
                                                header={<h6><i className="bi bi-house-fill"></i> <FormattedMessage id="statsInspections" defaultMessage="Inspections" /></h6>}
                                                content={taskResultContent(stats[userId])}
                                                footer={renderProgressBarResult(stats[userId])}
                                                border={'light'}
                                            />
                                        </>
                                    }
                                    {timestats[userId] &&
                                        <ChartColsCard
                                            key={'accord-users-stats-times-'.userId}
                                            header={<h6><i className="bi bi-stopwatch-fill"></i> <FormattedMessage id="statsExecutionTime" defaultMessage="Execution time" /></h6>}
                                            content={averageTimeContent(timestats[userId])}
                                            border={'light'}
                                        />
                                    }
                                    <pre><i className="bi bi-envelope-at-fill"></i> <FormattedMessage id="usersEmail" defaultMessage="E-mail" />: {stats[userId].user.email}</pre>
                                    <pre><i className="bi bi-phone-fill"></i> <FormattedMessage id="usersPhoneNumber" defaultMessage="Phone N." />: {stats[userId].user.phone}</pre>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>
            </Card.Body>
        </Card>
    )

    const renderProgressBarStatus = (stats) => (
        <div>
            <ProgressBar>
                <ProgressBar variant="primary" now={Math.round((stats[taskStatus.STATUS_COMPLETED] ? stats[taskStatus.STATUS_COMPLETED] : 0) * 100 / stats.total, 0)} />
                <ProgressBar animated variant="info" now={Math.round((stats[taskStatus.STATUS_IN_PROGRESS] ? stats[taskStatus.STATUS_IN_PROGRESS] : 0) * 100 / stats.total, 0)} />
                <ProgressBar variant="black" now={Math.round((stats[taskStatus.STATUS_CANCELLED] ? stats[taskStatus.STATUS_CANCELLED] : 0) * 100 / stats.total, 0)} />
                <ProgressBar animated variant="warning" now={Math.round((stats[taskStatus.STATUS_PENDING] ? stats[taskStatus.STATUS_PENDING] : 0) * 100 / stats.total, 0)} />
            </ProgressBar>
            <pre><FormattedMessage id="statsTotalTasks" defaultMessage="Total tasks" />: {stats.total}</pre>
        </div>
    )

    const renderProgressBarResult = (stats) => (
        <div>
            <ProgressBar>
                <ProgressBar variant="success" now={Math.round((stats[taskStatus.RESULT_GREEN] ? stats[taskStatus.RESULT_GREEN] : 0) * 100 / stats.total, 0)} />
                <ProgressBar variant="warning" now={Math.round((stats[taskStatus.RESULT_YELLOW] ? stats[taskStatus.RESULT_YELLOW] : 0) * 100 / stats.total, 0)} />
                <ProgressBar variant="danger" now={Math.round((stats[taskStatus.RESULT_RED] ? stats[taskStatus.RESULT_RED] : 0) * 100 / stats.total, 0)} />
                <ProgressBar variant="black" now={Math.round((stats[taskStatus.RESULT_BLACK] ? stats[taskStatus.RESULT_BLACK] : 0) * 100 / stats.total, 0)} />
                <ProgressBar animated variant="info" now={Math.round((stats[taskStatus.RESULT_NONE] ? stats[taskStatus.RESULT_NONE] : 0) * 100 / stats.total, 0)} />
            </ProgressBar>
            <pre>
                <FormattedMessage id="statsInspections" defaultMessage="Inspections" />: {stats.inspections}
                &nbsp;
                <FormattedMessage id="statsTotalTasks" defaultMessage="Total tasks" />: {stats.total}
            </pre>
        </div>
    )

    const renderStatsStatus = (title, stats) => (
        <Card bg="light">
            <Card.Header className='p-1'>
                <ChartCard
                    icon={<i className="bi bi-clipboard2-fill"></i>}
                    title={title}
                    text={stats.total ? stats.total : 0}
                    border={'light'}
                />
            </Card.Header>
            <Card.Body className="p-1">
                {stats &&
                    <ChartColsCard
                        header={<h6><i className="bi bi-clipboard2-fill"></i> <FormattedMessage id="statsTasksStates" defaultMessage="Tasks states" /></h6>}
                        content={taskStatusContent(stats)}
                        border={'light'}
                    />
                }
                <ProgressBar>
                    <ProgressBar variant="primary" now={Math.round((stats[taskStatus.STATUS_COMPLETED] ? stats[taskStatus.STATUS_COMPLETED] : 0) * 100 / stats.total, 0)} />
                    <ProgressBar animated variant="info" now={Math.round((stats[taskStatus.STATUS_IN_PROGRESS] ? stats[taskStatus.STATUS_IN_PROGRESS] : 0) * 100 / stats.total, 0)} />
                    <ProgressBar variant="black" now={Math.round((stats[taskStatus.STATUS_CANCELLED] ? stats[taskStatus.STATUS_CANCELLED] : 0) * 100 / stats.total, 0)} />
                    <ProgressBar animated variant="warning" now={Math.round((stats[taskStatus.STATUS_PENDING] ? stats[taskStatus.STATUS_PENDING] : 0) * 100 / stats.total, 0)} />
                </ProgressBar>
                <pre><FormattedMessage id="statsTotalTasks" defaultMessage="Total tasks" />: {stats.total}</pre>
            </Card.Body>
            <Card.Footer className='p-1'>
                {stats &&
                    <ChartColsCard
                        header={<h6><i className="bi bi-hourglass"></i> <FormattedMessage id="statsTimeline" defaultMessage="Timeline" /></h6>}
                        content={taskStatusTimesContent(stats)}
                        border={'light'}
                    />
                }
            </Card.Footer>
        </Card>
    )

    const renderStatsResults = (title, stats, timestats) => (
        <Card bg="light">
            <Card.Header className='p-1'>
                {stats &&
                    <ChartCard
                        icon={<i className="bi bi-house-fill"></i>}
                        title={title}
                        text={stats.inspections ? stats.inspections : 0}
                        border={'light'}
                    />
                }
            </Card.Header>
            <Card.Body className='p-1'>
                <ChartColsCard
                    header={<h6><i className="bi bi-house-fill"></i> <FormattedMessage id="statsInspections" defaultMessage="Inspections" /></h6>}
                    content={taskResultContent(stats)}
                    border={'light'}
                />
                <ProgressBar>
                    <ProgressBar variant="success" now={Math.round((stats[taskStatus.RESULT_GREEN] ? stats[taskStatus.RESULT_GREEN] : 0) * 100 / stats.total, 0)} />
                    <ProgressBar variant="warning" now={Math.round((stats[taskStatus.RESULT_YELLOW] ? stats[taskStatus.RESULT_YELLOW] : 0) * 100 / stats.total, 0)} />
                    <ProgressBar variant="danger" now={Math.round((stats[taskStatus.RESULT_RED] ? stats[taskStatus.RESULT_RED] : 0) * 100 / stats.total, 0)} />
                    <ProgressBar variant="black" now={Math.round((stats[taskStatus.RESULT_BLACK] ? stats[taskStatus.RESULT_BLACK] : 0) * 100 / stats.total, 0)} />
                    <ProgressBar animated variant="info" now={Math.round((stats[taskStatus.RESULT_NONE] ? stats[taskStatus.RESULT_NONE] : 0) * 100 / stats.total, 0)} />
                </ProgressBar>
                <pre>
                    <FormattedMessage id="statsInspections" defaultMessage="Inspections" />: {stats.inspections}
                    &nbsp;
                    <FormattedMessage id="statsTotalTasks" defaultMessage="Total tasks" />: {stats.total}
                </pre>
            </Card.Body>
            <Card.Footer className='p-1'>
                {timestats &&
                    <ChartColsCard
                        header={<h6><i className="bi bi-stopwatch-fill"></i> <FormattedMessage id="statsExecutionTime" defaultMessage="Execution Time" /></h6>}
                        content={averageTimeContent(timestats)}
                        border={'light'}
                    />
                }
            </Card.Footer>
        </Card >
    )

    if (error) {
        return (
            <AlertError error={error} />
        )
    } else if (!isLoaded) {
        return (
            <AlertLoading isLoaded={isLoaded} />
        )
    }

    return (
        <Row>
            <Col>
                {renderStatsMissions(<FormattedMessage id="missions" defaultMessage="Missions" />, tasksStats.missionStats, tasksStats.timeStats.missions)}
            </Col>
            <Col>
                {renderStatsUsers(<FormattedMessage id="users" defaultMessage="Users" />, tasksStats.userStats, tasksStats.timeStats.users)}
            </Col>
            <Col>
                {renderStatsStatus(<FormattedMessage id="statsTasksStates" defaultMessage="Tasks states" />, tasksStats.stateStats)}
            </Col>
            <Col>
                {renderStatsResults(<FormattedMessage id="statsInspections" defaultMessage="Inspections results" />, tasksStats.resultStats, tasksStats.timeStats)}
            </Col>
        </Row>
    )
}

export default Statistics