import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import moment from 'moment'

import TasksService from 'services/TasksService'

/*
*   modalType: 'edit' or 'delete'
*/
const InspectionModal = ({ show, modalType, task, callBack }) => {
    const [confirmModalShow, setConfirmModalShow] = useState(false)
    const handleConfirmModalClose = (update) => {
        // update true for callback
        setConfirmModalShow(false)
        callBack(update)
    }
    const handleConfirmModalShow = () => setConfirmModalShow(true)

    const [missionUsers, setMissionUsers] = useState(task.mission.usersInfo)
    const [userId, setUserId] = useState(task.userId._id)
    const [status, setStatus] = useState(task.status)
    const [result, setResult] = useState(task.result)
    const [resultDescription, setResultDescription] = useState(task.resultDescription)

    const handleUserSelect = (e) => {
        setUserId(e.target.value)
        setStatus('PENDING')  // By default change the task status if user change
    }

    const handleStatusRadioBtn = (e) => {
        setStatus(e.target.value)
    }

    const handleResultRadioBtn = (e) => {
        setResult(e.target.value)
    }

    const handleResultDescriptionInput = (e) => {
        setResultDescription(e.target.value)
    }

    const handleUpdateBtn = async () => {
        await updateTask(task.mission._id, task._id)
        handleConfirmModalClose(true)
    }

    const updateTask = async (missionId, taskId) => {
        const update = { userId: userId, status: status, result: result, resultDescription: resultDescription }
        await TasksService.updateTask(missionId, taskId, update)
    }

    const handleDeleteBtn = async () => {
        await deleteTask(task.mission._id, task._id)
        handleConfirmModalClose(true)
    }

    const deleteTask = async (missionId, taskId) => {
        await TasksService.deleteTask(missionId, taskId)
    }

    const sortUsers = (users) => {
        const usersArray = users.sort((a, b) => {
            const nameA = a.name.toLowerCase()
            const nameB = b.name.toLowerCase()
            return nameA === nameB ? 0 : nameA.localeCompare(nameB, undefined, { numeric: true })
        })
        setMissionUsers(usersArray)
    }

    const getModalTitle = (type) => {
        switch (type) {
            case 'edit':
                return <><i className="bi bi-pencil-square"></i> <FormattedMessage id="tasksEdit" defaultMessage="Edit task" /></>
            case 'delete':
                return <><i className="bi bi-trash"></i> <FormattedMessage id="tasksDelete" defaultMessage="Delete task?" /></>
            default:
                return <><i className="bi bi-pencil-square"></i> <FormattedMessage id="tasksEdit" defaultMessage="Edit task" /></>
        }
    }

    useEffect(() => {
        if (show) {
            handleConfirmModalShow()
            sortUsers(task.mission.usersInfo)
        } else {
            handleConfirmModalClose(false)
        }
    }, [show])

    return (
        <Modal
            show={confirmModalShow}
            onHide={() => handleConfirmModalClose(false)}
            animation={false}
            backdrop={"static"}
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{getModalTitle(modalType)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>
                    <b><FormattedMessage id="tasksName" defaultMessage="Task name" />: </b> {task.feature.name}
                </h5>

                {modalType === 'edit' && (
                    <>
                        <Form.Group className="mb-3" controlId="taskform.task.user">
                            <Form.Label><FormattedMessage id="tasksInspector" defaultMessage="Inspector" />:</Form.Label>
                            <Form.Select name="selectUser" className='form-select' size="sm" value={userId} onChange={handleUserSelect}>
                                {missionUsers && missionUsers.map((user, index) => {
                                    return <option key={"task-modal-select-user-" + user._id} value={user._id}>{user.name}</option>
                                })
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="taskform.task.status">
                            <Form.Label><FormattedMessage id="tasksStatus" defaultMessage="Status" />:</Form.Label>
                            <div className="btn-group btn-group-sm" role="group">
                                <input type="radio" className="btn-check" name="status" value="PENDING" id="statusRadio1" autoComplete="off" defaultChecked={status === "PENDING"} onChange={handleStatusRadioBtn} />
                                <label className="btn btn-outline-warning" htmlFor="statusRadio1"><i className="bi bi-clock"></i> <FormattedMessage id="tasksStatusPending" defaultMessage="Pending" /></label>

                                <input type="radio" className="btn-check" name="status" value="IN PROGRESS" id="statusRadio2" autoComplete="off" defaultChecked={status === "IN PROGRESS"} onChange={handleStatusRadioBtn} />
                                <label className="btn btn-outline-info" htmlFor="statusRadio2"><i className="bi bi-clock"></i> <FormattedMessage id="tasksStatusInProgress" defaultMessage="In Progress" /></label>

                                <input type="radio" className="btn-check" name="status" value="FINISHED" id="statusRadio3" autoComplete="off" defaultChecked={status === "FINISHED"} onChange={handleStatusRadioBtn} />
                                <label className="btn btn-outline-primary" htmlFor="statusRadio3"><i className="bi bi-check-circle"></i> <FormattedMessage id="tasksStatusFinished" defaultMessage="Finished" /></label>

                                <input type="radio" className="btn-check" name="status" value="CANCELLED" id="statusRadio4" autoComplete="off" defaultChecked={status === "CANCELLED"} onChange={handleStatusRadioBtn} />
                                <label className="btn btn-outline-secondary" htmlFor="statusRadio4"><i className="bi bi-x-circle"></i> <FormattedMessage id="tasksStatusCancelled" defaultMessage="Cancelled" /></label>
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="taskform.task.result">
                            <Form.Label><FormattedMessage id="tasksInspectionDescription" defaultMessage="Inspection description" />:</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="resultDescription"
                                value={resultDescription}
                                onChange={handleResultDescriptionInput}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="taskform.task.result">
                            <Form.Label><FormattedMessage id="tasksResult" defaultMessage="Inspection Result" />:</Form.Label>
                            <div className="btn-group btn-group-sm" role="group">
                                <input type="radio" className="btn-check" name="result" value="NONE" id="resultRadio0" autoComplete="off" defaultChecked={result === "NONE"} onChange={handleResultRadioBtn} />
                                <label className="btn btn-outline-secondary" htmlFor="resultRadio0"><i className="bi bi-house-gear-fill"></i> <FormattedMessage id="tasksResultNone" defaultMessage="None" /></label>

                                <input type="radio" className="btn-check" name="result" value="GREEN" id="resultRadio1" autoComplete="off" defaultChecked={result === "GREEN"} onChange={handleResultRadioBtn} />
                                <label className="btn btn-outline-success" htmlFor="resultRadio1"><i className="bi bi-house-check-fill"></i> <FormattedMessage id="tasksResultGreen" defaultMessage="Green" /></label>

                                <input type="radio" className="btn-check" name="result" value="YELLOW" id="resultRadio2" autoComplete="off" defaultChecked={result === "YELLOW"} onChange={handleResultRadioBtn} />
                                <label className="btn btn-outline-warning" htmlFor="resultRadio2"><i className="bi bi-house-exclamation-fill"></i> <FormattedMessage id="tasksResultYellow" defaultMessage="Yellow" /></label>

                                <input type="radio" className="btn-check" name="result" value="RED" id="resultRadio3" autoComplete="off" defaultChecked={result === "RED"} onChange={handleResultRadioBtn} />
                                <label className="btn btn-outline-danger" htmlFor="resultRadio3"><i className="bi bi-house-slash-fill"></i> <FormattedMessage id="tasksResultRed" defaultMessage="Red" /></label>

                                <input type="radio" className="btn-check" name="result" value="BLACK" id="resultRadio4" autoComplete="off" defaultChecked={result === "BLACK"} onChange={handleResultRadioBtn} />
                                <label className="btn btn-outline-dark" htmlFor="resultRadio4"><i className="bi bi-house-x-fill"></i> <FormattedMessage id="tasksResultBlack" defaultMessage="Black" /></label>
                            </div>
                        </Form.Group>
                    </>
                )}

            </Modal.Body>
            <Modal.Footer>
                {modalType === 'edit' && (
                    <>
                        <Button variant="primary" onClick={handleUpdateBtn}>
                            <i className="bi bi-pencil-square"></i> <FormattedMessage id="textUpdate" defaultMessage="Update" />
                        </Button>
                        <Button variant="secondary" onClick={() => handleConfirmModalClose(false)}>
                            <i className="bi bi-x-square"></i> <FormattedMessage id="textClose" defaultMessage="Close" />
                        </Button>
                    </>
                )}
                {modalType === 'delete' && (
                    <>
                        <Button variant="danger" onClick={handleDeleteBtn}>
                            <i className="bi bi-trash"></i> <FormattedMessage id="textYes" defaultMessage="Yes" />
                        </Button>
                        <Button variant="secondary" onClick={() => handleConfirmModalClose(false)}>
                            <i className="bi bi-x-square"></i> <FormattedMessage id="textNo" defaultMessage="No" />
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default InspectionModal