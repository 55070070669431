import React, { Component } from "react"

export default class NotFound extends Component {
  constructor(props) {
    super(props)

    this.state = {
      content: ""
    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>404 Not found</h3>
        </header>
        <div>
            Page don't exist!
        </div>
      </div>
    )
  }
}