// SocketService.js

import io from 'socket.io-client'
import socketConfig from 'config/socket.config'

const SocketService = (token) => {
    const socket = io(socketConfig.SOCKET_URL, {
        auth: {
            token: token
        }
    })

    // Get the Socket.IO socket instance
    const connect = (callBackConnectionStatus) => {
        // Evento de conexión
        socket.on('connect', () => {
            callBackConnectionStatus(false)
            console.log('Connected to the socket server')
        })
        // Evento de desconexión
        socket.on('disconnect', () => {
            callBackConnectionStatus(true)
            console.log('Disconnected to the socket server')
        })
        return socket
    }

    return {
        connect,
    }
}

export default SocketService