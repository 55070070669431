import React, { useState, useEffect, useRef } from "react"
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Badge from 'react-bootstrap/Badge'

import SocketService from "socket/SocketService"
import ChatRoomEvents from "socket/ChatRoomEvents.socket"
import LocationEvents from "socket/LocationEvents.socket"

import GroupsService from 'services/GroupsService'

import MissionsService from "services/MissionsService"
import MessagesToast from "components/toast/MessagesToast"

import { AlertError, AlertLoading } from 'helpers/AlertHelper'

import notificationSound from 'components/audio/notification.mp3'

import MapGroup from 'pages/missions/MapGroup'
import TeamList from "pages/missions/TeamList"
import MissionsList from "pages/missions/MissionsList"
import TasksList from "pages/missions/TasksList"
import LogbookList from "pages/missions/LogbookList"
import StatisticsGroup from "pages/missions/StatisticsGroup"
import ChatGroup from "pages/missions/ChatGroup"
import IoTList from "pages/missions/IoTList"
import Tools from "pages/missions/Tools"
import GroupInfo from "components/offcanvas/GroupInfo.offcanvas"
import GroupBar from "components/bars/GroupBar"

const Missions = (props) => {

    const authUser = JSON.parse(localStorage.getItem("user"))

    const { groupId } = useParams()  // :groupId param

    const audioRef = useRef(null)

    const playNotificationSound = () => {
        if (audioRef.current) {
            audioRef.current.play()
        }
    }

    const [showGroupInfo, setShowGroupInfo] = useState(false)
    const handleCloseGroupInfo = () => setShowGroupInfo(false)
    const handleShowGroupInfo = () => setShowGroupInfo((prevShow) => !prevShow)

    const [tabKey, setTabKey] = useState('map')
    const [group, setGroup] = useState(false)
    const [missions, setMissions] = useState([])
    const [users, setUsers] = useState([])
    const [updateGroupData, setUpdateGroupData] = useState(false)

    const [missionId, setMissionId] = useState(false)
    const [userId, setUserId] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [numTasks, setNumTasks] = useState(0)

    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)

    const handleTabKeySelect = (selectedTabKey) => {
        setTabKey(selectedTabKey)
    }

    const [toasts, setToasts] = useState([])

    const [sendButtonDisabled, setSendButtonDisabled] = useState(false)

    const handleToasts = ({ id, group, chatRoom, sender, createdAt, moment, content }) => {
        setToasts([
            ...toasts,
            { id, group, chatRoom, sender, createdAt, moment, content, show: true }
        ])
        playNotificationSound()
    }

    const toggleToastShow = (id) => {
        const updatedToasts = toasts.filter((toast) => toast.id !== id)
        setToasts(updatedToasts)
    }

    const [chatMessagesNum, setChatMessagesNum] = useState(0)
    const handleChatMessagesNum = (num) => {
        setChatMessagesNum(num)
    }

    const onChangeMission = (e) => {
        if (e.target.value === 'false') {
            setMissionId(false)
            setUserId(false)
        } else {
            setMissionId(e.target.value)
            setUserId(false)
        }
    }

    const onChangeUser = (e) => {
        if (e.target.value === 'false') {
            setUserId(false)
        } else {
            setUserId(e.target.value)
        }
    }

    const onChangeSearch = (e) => {
        setSearchValue(e.target.value)
    }

    const onClickClearBtn = (e) => {
        setMissionId(false)
        setUserId(false)
        setSearchValue('')
    }

    const updateData = async (groupId) => {
        await getGroup(groupId)
        await getMissions(groupId)
        //onClickClearBtn()
    }

    const getGroup = async (groupId) => {
        await GroupsService.getGroup(groupId, true).then(
            response => {
                setGroup(response.data)
                setIsLoaded(true)
            },
            error => {
                setError(error)
            })
    }

    const getMissions = async (groupId) => {
        const options = {
            groupId: groupId,
            limit: 1000
        }
        await MissionsService.getGroupMissions(options).then(
            response => {
                const missionsArray = response.data.missions.sort((a, b) => {
                    const nameA = a.name.toLowerCase()
                    const nameB = b.name.toLowerCase()
                    return nameA === nameB ? 0 : nameA.localeCompare(nameB, undefined, { numeric: true })
                })
                setMissions(missionsArray)
                //console.log(res.data.missions)
            },
            error => {
                setError(error)
            })
    }

    // Websocket chat connection
    const [socket, setSocket] = useState(null)
    const [socketData, setSocketData] = useState(null)
    const handleSocketData = (data) => {
        setSocketData(data)
    }
    const handleLocationSocketData = (data) => {
        // Nothing to do!!!!
    }
    useEffect(() => {
        const newSocket = SocketService(authUser.accessToken).connect(setSendButtonDisabled)

        setSocket(newSocket)

        // Clean socket to unmount the chat component
        return () => {
            // Disconnect from the socket server
            setSendButtonDisabled(true)
            newSocket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (!socket) return
        LocationEvents(socket).setEvents(handleLocationSocketData)
        ChatRoomEvents(socket).setEvents(handleSocketData)
    }, [socket])

    useEffect(() => {
        if (!updateGroupData) return
        if (!groupId) return

        updateData(groupId)
        setUpdateGroupData(false)
    }, [updateGroupData])

    useEffect(() => {
        if (!groupId) return

        setTabKey('map')
        setIsLoaded(false)
        setGroup(false)
        setMissions(false)
        setMissionId(false)
        setUserId(false)
        setSearchValue('')
        updateData(groupId)
    }, [groupId])

    useEffect(() => {
        if (!groupId) return
        if (!missions) return

        const missionsFiltered = missionId ? missions.filter(mission => mission._id === missionId) : [...missions]

        setNumTasks(missionsFiltered.reduce((acc, mission) => {
            return acc + mission.features.length
        }, 0))

        setUsers(Array.from(
            new Map(
                missionsFiltered.flatMap((mission) =>
                    mission.usersInfo.map((user) => [user._id, user])
                )
            ).values()
        )
            .sort((a, b) => {
                const nameA = a.name.toLowerCase()
                const nameB = b.name.toLowerCase()
                return nameA === nameB ? 0 : nameA.localeCompare(nameB, undefined, { numeric: true })
            }))

    }, [missions, missionId, userId, searchValue])

    if (error) {
        return (
            <AlertError error={error} />
        )
    } else if (groupId && !isLoaded) {
        return (
            <AlertLoading isLoaded={isLoaded} />
        )
    }

    return (
        <div className="p-1">

            <GroupBar
                group={group}
                missions={missions}
                users={users}
                searchValue={searchValue}
                missionId={missionId}
                userId={userId}
                numTasks={numTasks}
                onChangeMission={onChangeMission}
                onChangeUser={onChangeUser}
                onChangeSearch={onChangeSearch}
                onClickClearBtn={onClickClearBtn}
                handleShowGroupInfo={handleShowGroupInfo}
            />

            <GroupInfo group={group} show={showGroupInfo} onClose={handleCloseGroupInfo} />

            {toasts && toasts.length > 0 &&
                <MessagesToast
                    toasts={toasts}
                    setToasts={setToasts}
                    toggle={toggleToastShow}
                    position={'bottom-end'}
                    className="position-fixed p-2" />
            }

            <audio ref={audioRef}>
                <source src={notificationSound} type="audio/mp3" />
                Tu navegador no soporta la etiqueta de audio.
            </audio>

            <Tabs
                id="missions-tab-panel"
                activeKey={tabKey}
                onSelect={(k) => handleTabKeySelect(k)}
                className="mb-3 p-0"
                transition={false}
                fill
            >
                <Tab eventKey="map" title={<span><i className='bi bi-map-fill'></i> <FormattedMessage id="navMap" defaultMessage="Map" /></span>} className="p-1">
                    <MapGroup group={group} groupId={groupId} missionId={missionId} userId={userId} searchValue={searchValue} updateGroupData={setUpdateGroupData} />
                </Tab>
                <Tab eventKey="teamlist" title={<span><i className='bi bi-people-fill'></i> <FormattedMessage id="navResponseTeam" defaultMessage="Response Team" /></span>} className="p-1">
                    {tabKey === 'teamlist' &&
                        <TeamList groupId={groupId} userId={userId} searchValue={searchValue} />
                    }
                </Tab>
                <Tab eventKey="missionlist" title={<span><i className='bi bi-lightning-fill'></i> <FormattedMessage id="navMissions" defaultMessage="Missions" /></span>} className="p-1">
                    {tabKey === 'missionlist' &&
                        <MissionsList groupId={groupId} missionId={missionId} userId={userId} searchValue={searchValue} callBack={setUpdateGroupData} />
                    }
                </Tab>
                <Tab eventKey="tasklist" title={<span><i className='bi bi-clipboard2-fill'></i> <FormattedMessage id="navTasks" defaultMessage="Tasks" /></span>} className="p-1">
                    {tabKey === 'tasklist' &&
                        <TasksList groupId={groupId} missionId={missionId} userId={userId} searchValue={searchValue} callBack={setUpdateGroupData} />
                    }
                </Tab>
                <Tab eventKey="logbook" title={<span><i className="bi bi-book-half"></i> <FormattedMessage id="navLogbook" defaultMessage="Logbook" /></span>} className="p-1">
                    {tabKey === 'logbook' &&
                        <LogbookList groupId={groupId} missionId={missionId} userId={userId} searchValue={searchValue} />
                    }
                </Tab>
                <Tab eventKey="chart" title={<span><i className="bi bi-bar-chart-line-fill"></i> <FormattedMessage id="navStatistics" defaultMessage="Statistics" /></span>} className="p-1">
                    {tabKey === 'chart' &&
                        <StatisticsGroup groupId={groupId} missionId={missionId} userId={userId} searchValue={searchValue} callBack={setUpdateGroupData} />
                    }
                </Tab>
                <Tab eventKey="chat" title={<span><i className="bi bi-chat-square-fill"></i> <FormattedMessage id="navChat" defaultMessage="Chat" /> {chatMessagesNum > 0 && <Badge bg="warning">{chatMessagesNum}</Badge>}</span>} className="p-1">
                    <ChatGroup activeKey={tabKey} groupId={groupId} authUserId={authUser._id} socket={socket} socketData={socketData} handleToasts={handleToasts} handleChatMessagesNum={handleChatMessagesNum} sendButtonDisabled={sendButtonDisabled} />
                </Tab>
                <Tab eventKey="iot" title={<span><i className="bi bi-activity"></i> <FormattedMessage id="navIoT" defaultMessage="IoT Activity" /></span>} className="p-1">
                    {tabKey === 'iot' &&
                        <IoTList groupId={groupId} missionId={missionId} userId={userId} searchValue={searchValue} />
                    }
                </Tab>
                <Tab eventKey="tools" title={<span><i className='bi bi-tools'></i> <FormattedMessage id="navTools" defaultMessage="Tools" /></span>} className="p-1">
                    {tabKey === 'tools' &&
                        <Tools groupId={groupId} group={group} />
                    }
                </Tab>
            </Tabs>
        </div >
    )
}

export default Missions
