import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import Alert from 'react-bootstrap/Alert'

import moment from 'moment'

import AuthService from "../services/AuthService"
import ProfileService from "../services/ProfileService"

import { AlertError, AlertLoading, AlertMsg } from 'helpers/AlertHelper'

const Profile = (props) => {

    const [showMsg, setShowMsg] = useState(false)
    const [showAlert, setShowAlert] = useState(false)

    const [user, setUser] = useState(false)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [formValid, setFormValid] = useState(false)

    const [error, setError] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    const getUser = async () => {
        await ProfileService.getProfile().then(
            res => {
                setName(res.data.name)
                setPhone(res.data.phone)
                setEmail(res.data.email)

                // Setting Local stored data to user var
                setUser(AuthService.getCurrentUser())
                setIsLoaded(true)
            },
            error => {
                setError(error)
            })
    }

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFieldValue(name, value)
    }

    const setFieldValue = (fieldName, value) => {
        switch (fieldName) {
            case 'name':
                setName(value)
                break
            case 'phone':
                setPhone(value)
                break
            case 'email':
                setEmail(value)
                break
            case 'password':
                setPassword(value)
                break
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget
        if (form.checkValidity()) {

            const userUpdateData = {
                name: name,
                phone: phone,
                email: email
            }

            if (password.length !== 0) {
                userUpdateData.password = password
            }
            await ProfileService.updateProfile(userUpdateData).then(
                res => {
                    setFormValid(false)
                    setShowMsg({ text: 'Profile update success!', style: 'success' })
                    setShowAlert(true)
                },
                error => {
                    setFormValid(true)
                    setShowMsg({ text: error, style: 'danger' })
                    setShowAlert(true)
                })
        }
        setFormValid(true)
    }

    useEffect(() => {
        if (user) return
        getUser()
    }, [user])

    if (error) {
        return (
            <AlertError error={error} />
        )
    } else if (!isLoaded) {
        return (
            <AlertLoading isLoaded={isLoaded} />
        )
    }

    return (
        <Container className='p-1' fluid>
            <Row>
                <Col>
                    <h5>
                        <i className="bi bi-person-square"></i> <FormattedMessage id="usersMyProfile" defaultMessage="My profile" />
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <Form noValidate validated={formValid} onSubmit={handleSubmit}>
                        <AlertMsg message={showMsg.text} variant={showMsg.style} dismissible onCloseHandler={() => setShowAlert(false)} show={showAlert} />

                        <Form.Group className="mb-3" controlId="roles">
                            <Form.Label><FormattedMessage id="usersRoles" defaultMessage="Roles" />:</Form.Label>
                            <ListGroup horizontal>
                                {user.roles &&
                                    user.roles.map((role, index) => <ListGroup.Item variant="info" key={"role" + index}>{role}</ListGroup.Item>)}
                            </ListGroup>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label><FormattedMessage id="usersName" defaultMessage="Name" />:</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={name}
                                onChange={handleInputChange}
                                required="required"
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="usersNameInvalid" defaultMessage="Provide an user name" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="phone">
                            <Form.Label><FormattedMessage id="usersPhoneNumber" defaultMessage="Phone number" />:</Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                value={phone}
                                onChange={handleInputChange}
                                required="required"
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="usersPhoneNumberInvalid" defaultMessage="Provide a phone number" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label><FormattedMessage id="usersEmail" defaultMessage="E-mail" />:</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={email}
                                onChange={handleInputChange}
                                required="required"
                                pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="usersEmailInvalid" defaultMessage="Provide an Email address" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label><FormattedMessage id="usersPassword" defaultMessage="Password" />:</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                value={password}
                                placeholder='User password'
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="usersPasswordInvalid" defaultMessage="Provide an user password" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Button
                            className="btn btn-primary"
                            type="submit"
                        >
                            <i className="bi bi-save2"></i> <FormattedMessage id="textSave" defaultMessage="Save profile" />
                        </Button>

                    </Form>
                </Col>
                <Col className='bg-light'>
                    <p>
                        <strong><FormattedMessage id="usersEntityName" defaultMessage="Entity name" />:</strong>{" "}
                        {user.entity.name}
                    </p>
                    <p>
                        <strong><FormattedMessage id="usersEntityEmail" defaultMessage="Entity e-mail" />:</strong>{" "}
                        {user.entity.email}
                    </p>
                    <p>
                        <strong><FormattedMessage id="usersEntityPhoneNumber" defaultMessage="Entity phone number" />:</strong>{" "}
                        {user.entity.phone}
                    </p>
                    <p>
                        <strong><FormattedMessage id="usersLastLogin" defaultMessage="Last login" />:</strong>{" "}
                        {moment(user.lastLoginAt).fromNow()}
                    </p>
                    <p>
                        <strong><FormattedMessage id="usersCreatedAt" defaultMessage="Created at" />:</strong>{" "}
                        {moment(user.createdAt).fromNow()}
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default Profile