import { useEffect } from "react"
import { useIntl } from 'react-intl'
import { useMap } from "react-leaflet"
import L, { geoJSON } from "leaflet"
import "leaflet.browser.print/dist/leaflet.browser.print.js"

const PrintMapButton = () => {
    const intl = useIntl()

    const map = useMap()

    useEffect(() => {
        /*  https://github.com/Igor-Vladyka/leaflet.browser.print
        *   printModes: ["Portrait", "Landscape", "Auto", "Custom"
        */
        const browserPrintControl = L.control.browserPrint({ 
            position: 'topleft', 
            title: intl.formatMessage({ id: 'mapPrintMap', defaultMessage: 'Print this map' }), 
            printModes: ["Portrait", "Landscape", "Auto"] 
        }).addTo(map)

    }, [map])

    return null
}

export default PrintMapButton
