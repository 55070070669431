import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import ListGroup from 'react-bootstrap/ListGroup'


import Pagination from "../pagination/TablePagination";
import FormModal from "../modals/FormModal";
import moment from 'moment'

const TableList = (props) => {
    const intl = useIntl()

    const caption = props.caption
    const icon = props.icon
    const elements = props.data
    const elementKeys = props.keys
    const pagination = props.pagination || false
    const paginationData = props.paginationData || null
    const paginationSkip = props.paginationData.skipItems || 0
    const titleAdd = props.titleAdd
    const titleEdit = props.titleEdit
    const titleDelete = props.titleDelete
    const searchValue = props.searchValue
    const setSearchValue = props.setSearchValue

    const colKeys = []
    const colValues = []
    Object.entries(elementKeys).map(([key, value]) => {
        colKeys.push(key)
        colValues.push(value)
    });

    // Open modal form control to add/edit element
    const [modalTrigger, setModalTrigger] = useState(false);

    // Setting modal title
    const [modalTitle, setModalTitle] = useState(false);

    // Setting modal icon
    const [modalIcon, setModalIcon] = useState(false);

    // Setting modal action enum[add,edit,delete]
    const [modalAction, setModalAction] = useState(false);

    // Setting element id
    const [elementId, setElementId] = useState(false);

    // Format value depending typeof and return the correct value/s
    const formatValue = (value) => {
        if (typeof value === "boolean") {
            return value ? "Yes" : "No"
        }

        if (Array.isArray(value)) {
            return (
                <OverlayTrigger
                    trigger={["hover", "focus"]}
                    key={`tr-trigger-array`}
                    placement="right"
                    overlay={
                        <Popover id={`tr-popover`}>
                            <Popover.Body>
                                <ListGroup variant="flush">
                                    {value.map((val, index) => (
                                        <ListGroup.Item key={`tr-popover-${index}-${typeof val === 'object' && val.name ? val.name : val}`}>{typeof val === 'object' && val.name ? val.name : val}</ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <span>{value.length} <i className="bi bi-three-dots-vertical"></i></span>
                </OverlayTrigger>
            )
        }

        const momentDate = moment.utc(value, "YYYY-MM-DDTHH:mm:ss.SSSZ", true)
        if (momentDate.isValid()) {
            return momentDate.fromNow()
        }

        if (typeof value === "object") {
            return value.name
        }

        return value
    }

    const setSearch = (e) => {
        e.preventDefault()
        setSearchValue(e.target.value)
    }

    return (
        <div>
            <table className="table table-hover caption-top">
                <caption>
                    <span className="h5"><i className={"bi " + icon}></i> {caption}: {paginationData.totalItems}</span>
                    <input className="form-control form-control-sm" name="search" type="text" placeholder={intl.formatMessage({ id: 'textSearch', defaultMessage: 'Search' })+'...'} value={searchValue} onChange={setSearch} autoFocus aria-autocomplete='false' autoComplete='false' />
                </caption>
                <thead>
                    <tr className="table-secondary">
                        <th scope="col">#</th>
                        {colValues.map(value =>
                            <th key={value.props.id}><FormattedMessage id={value.props.id} defaultMessage={value.props.defaultMessage} /></th>
                        )}
                        <th>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <Button type="button" className="btn btn-sm btn-light btn-outline-dark" onClick={() => props.refreshData(paginationData.page)}><i className="bi bi-arrow-clockwise"></i> <FormattedMessage id="tableRefresh" defaultMessage="Refresh" /></Button>
                                <Button type="button" className="btn btn-sm btn-light btn-outline-dark" onClick={() => {
                                    setModalTrigger(true)
                                    setModalTitle(titleAdd)
                                    setModalAction('add')
                                    setModalIcon('bi-plus-circle')
                                }}><i className="bi bi-plus-circle"></i> <FormattedMessage id="tableAdd" defaultMessage="Add" /></Button>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        elements
                            .map((element, num) =>
                                <tr key={num + 1}>
                                    <th scope="row">{num + 1 + paginationSkip}</th>
                                    {colKeys
                                        .map(key =>
                                            <td key={key + "-row-" + num}>{formatValue(element[key])}</td>
                                        )
                                    }
                                    <td>
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <Button type="button" className="btn btn-sm btn-light btn-outline-primary" onClick={() => {
                                                setModalTrigger(true)
                                                setModalTitle(titleEdit)
                                                setModalAction('edit')
                                                setModalIcon('bi-pencil-square')
                                                setElementId(element._id)
                                            }}><i className="bi bi-pencil-square"></i> <FormattedMessage id="tableEdit" defaultMessage="Edit" /></Button>
                                            <Button type="button" className="btn btn-sm btn-light btn-outline-danger" onClick={() => {
                                                setModalTrigger(true)
                                                setModalTitle(titleDelete)
                                                setModalAction('delete')
                                                setModalIcon('bi-trash')
                                                setElementId(element._id)
                                            }}><i className="bi bi-trash"></i> <FormattedMessage id="tableDelete" defaultMessage="Delete" /></Button>
                                        </div>
                                    </td>
                                </tr>
                            )
                    }
                </tbody>
            </table>

            {pagination && paginationData.totalPages > 1 && (
                <Pagination data={paginationData} goToPageFunction={props.goToPageFunction} />
            )}

            <FormModal
                title={modalTitle}
                icon={modalIcon}
                body={props.ElementForm}
                refreshData={props.refreshData}
                modalTrigger={modalTrigger}
                elementId={elementId}
                action={modalAction} />

        </div>
    )

}

export default TableList