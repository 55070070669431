import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import moment from 'moment'

import TeamsService from 'services/TeamsService'
import GroupsService from 'services/GroupsService'
import EntitiesService from 'services/EntitiesService'

import { STATUS } from '../../config/team'

function TeamForm({ groupId, elementId, actionType, callBack }) {

    const [group, setGroup] = useState(false)
    const [team, setTeam] = useState(false)
    const [users, setUsers] = useState([])
    const [entityCheckId, setEntityCheckId] = useState(null)
    const [userId, setUserId] = useState('')
    const [status, setStatus] = useState('AVAILABLE')
    const [description, setDescription] = useState('')
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
    const [buttonTitle, setButtonTitle] = useState('')
    const [inputDisabled, setInputDisabled] = useState(false)
    const [formValid, setFormValid] = useState(false)
    const [showMsg, setShowMsg] = useState(false)
    const [message, setMessage] = useState(false)

    const handleErrorMsg = (data) => {
        setShowMsg(true)
        setMessage(`Error (${data.error.code}): ${data.msg}`)
    }

    const getGroup = async () => {
        await GroupsService.getGroup(groupId, true)
            .then(res => {
                setGroup(res.data)
            },
                error => {
                    handleErrorMsg(error.response.data)
                })
    }

    const getEntityUsers = async (entityId) => {
        if (entityId) {
            await EntitiesService.getEntityUsers(entityId)
                .then(res => {
                    const usersArray = res.data.users.sort((a, b) => {
                        const nameA = a.name.toLowerCase()
                        const nameB = b.name.toLowerCase()
                        return nameA === nameB ? 0 : nameA.localeCompare(nameB, undefined, { numeric: true })
                    })
                    setUsers(usersArray)
                },
                    error => {
                        handleErrorMsg(error.response.data)
                    })
        }
    }

    useEffect(() => {
        getGroup()

        if (elementId) {
            TeamsService.getTeam(groupId, elementId)
                .then(res => {
                    setTeam(res.data)
                    setEntityCheckId(res.data.entityId)
                    setUserId(res.data.userId)
                    setStatus(res.data.status)
                    setDescription(res.data.description)
                    setStartDate(res.data.startDate ? moment(res.data.startDate).format('YYYY-MM-DD') : new Date())
                    setEndDate(res.data.endDate ? moment(res.data.endDate).format('YYYY-MM-DD') : new Date())
                },
                    error => {
                        handleErrorMsg(error.response.data)
                    })
        }

        switch (actionType) {
            case 'edit':
                setButtonTitle(<FormattedMessage id="textSave" defaultMessage="Save" />)
                break
            case 'delete':
                setButtonTitle(<FormattedMessage id="textDelete" defaultMessage="Delete" />)
                setInputDisabled(true)
                break
            default:
                setButtonTitle(<FormattedMessage id="textAdd" defaultMessage="Add" />)
        }

    }, [])

    const handleCallBack = () => {
        callBack(true)
    }

    useEffect(() => {
        if (!entityCheckId) return
        getEntityUsers(entityCheckId)

    }, [entityCheckId])

    const handleEntityRadioButton = (entityCheckId) => {
        setEntityCheckId(entityCheckId)
    }

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFieldValue(name, value)
    }

    const setFieldValue = (fieldName, value) => {
        switch (fieldName) {
            case 'userId':
                setUserId(value)
                break
            case 'status':
                setStatus(value)
                break
            case 'description':
                setDescription(value)
                break
            case 'startDate':
                setStartDate(value)
                break
            case 'endDate':
                setEndDate(value)
                break
            default:
                break
        }
    }

    const handleOnSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget
        if (form.checkValidity()) {
            const teamUpdate = {
                status: status,
                startDate: startDate,
                endDate: endDate,
                description: description
            }
            const teamAdd = {
                entityId: entityCheckId,
                userId: userId,
                ...teamUpdate
            }

            if (elementId && actionType === "edit") {
                TeamsService.updateTeam(groupId, elementId, teamUpdate)
                    .then(res => {
                        setFormValid(false)
                        handleCallBack()
                    },
                        error => {
                            setFormValid(false)
                            handleErrorMsg(error.response.data)
                        })
            } else if (actionType === "add") {
                TeamsService.addTeam(groupId, teamAdd)
                    .then(res => {
                        setFormValid(false)
                        handleCallBack()
                    },
                        error => {
                            setFormValid(false)
                            handleErrorMsg(error.response.data)
                        })
            } else if (actionType === "delete") {
                TeamsService.deleteTeam(groupId, elementId)
                    .then(res => {
                        setFormValid(false)
                        handleCallBack()
                    },
                        error => {
                            setFormValid(false)
                            handleErrorMsg(error.response.data)
                        })
            }
        } else {
            setFormValid(true)
        }
    }

    return (
        <div>
            <Alert show={showMsg} variant={'danger'} onClose={() => setShowMsg(false)} dismissible>
                {message}
            </Alert>

            <Form noValidate validated={formValid} onSubmit={handleOnSubmit}>
                {elementId && (
                    <Form.Group className="mb-3" controlId="id">
                        <Form.Label>Id:</Form.Label>
                        <Form.Control
                            type="text"
                            name="id"
                            value={elementId}
                            disabled={"disabled"}
                        />
                    </Form.Group>
                )}

                <Form.Group className="mb-3" controlId="status">
                    <Form.Label><FormattedMessage id="teamStatus" defaultMessage="Status" />:</Form.Label>
                    {STATUS.map((statusElm) => (
                        <Form.Check
                            inline
                            label={statusElm}
                            name="status"
                            type="radio"
                            id={statusElm}
                            key={statusElm}
                            value={status}
                            checked={status === statusElm ? true : false}
                            onChange={() => setStatus(statusElm)}
                            required
                            disabled={inputDisabled}
                        />
                    ))}
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="teamStatusInvalid" defaultMessage="Select the user status" />
                    </Form.Control.Feedback>
                </Form.Group>

                {actionType === "add" && (
                    <>
                        <Form.Group className="mb-3" controlId="entityCheckId">
                            <Form.Label><FormattedMessage id="teamEntity" defaultMessage="Entity" />:</Form.Label>
                            <div key="inline-radio-entities" className="mb-3">
                                {group && group.entities && group.entities.map((entity, index) => {
                                    return (
                                        <Form.Check
                                            inline
                                            label={entity.name}
                                            name={"entityCheckId"}
                                            type="radio"
                                            id={`teamEntity-${entity._id}`}
                                            key={`teamEntity-${entity._id}`}
                                            checked={entityCheckId === entity._id ? true : false}
                                            onChange={() => handleEntityRadioButton(entity._id)}
                                            required
                                            disabled={inputDisabled}
                                            feedback={<FormattedMessage id="teamEntityInvalid" defaultMessage="Select an entity to view the users" />}
                                            feedbackType='invalid'
                                        />
                                    )
                                }
                                )}
                            </div>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="userId">
                            <Form.Label><FormattedMessage id="teamUser" defaultMessage="User" />:</Form.Label>
                            <Form.Select
                                name="userId"
                                value={userId}
                                onChange={handleInputChange}
                                disabled={inputDisabled && ("disabled")}
                                required
                            >
                                <option value={""} key={'select-user-sel'}><FormattedMessage id="textSelect" defaultMessage="Select" /></option>
                                {users && users.map(user => {
                                    return (<option value={user._id} key={'select-user-' + user._id}>{user.name}</option>)
                                }
                                )}
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="teamUserInvalid" defaultMessage="Select an user" />
                            </Form.Control.Feedback>
                        </Form.Group>
                    </>
                )}

                {(actionType === "edit" || actionType === "delete") && team && (
                    <>
                        <Form.Group className="mb-3" controlId="entityName">
                            <Form.Label><FormattedMessage id="teamEntity" defaultMessage="Entity" />:</Form.Label>
                            <Form.Control
                                type="text"
                                name="entityName"
                                value={team.user.entity.name}
                                disabled={"disabled"}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="userName">
                            <Form.Label><FormattedMessage id="teamUser" defaultMessage="User" />:</Form.Label>
                            <Form.Control
                                type="text"
                                name="userName"
                                value={team.user.name}
                                disabled={"disabled"}
                            />
                        </Form.Group>
                    </>

                )}

                <Form.Group className="mb-3" controlId="description">
                    <Form.Label><FormattedMessage id="teamDescription" defaultMessage="Description" />:</Form.Label>
                    <Form.Control
                        name="description"
                        value={description}
                        as="textarea"
                        disabled={inputDisabled && ("disabled")}
                        rows={3}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="startDate">
                    <Form.Label><FormattedMessage id="teamStartDate" defaultMessage="Start date" />:</Form.Label>
                    <Form.Control
                        type="date"
                        name="startDate"
                        value={startDate}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="teamStartDateInvalid" defaultMessage="Provide a start date" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="endDate">
                    <Form.Label><FormattedMessage id="teamEndDate" defaultMessage="End date" />:</Form.Label>
                    <Form.Control
                        type="date"
                        name="endDate"
                        value={endDate}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="teamEndDateInvalid" defaultMessage="Provide an end date" />
                    </Form.Control.Feedback>
                </Form.Group>

                <div className="d-grid gap-2">
                    {actionType === "add" && (
                        <Button
                            className="btn btn-dark"
                            type="submit"
                        >
                            <i className="bi bi-plus-circle"></i> {buttonTitle}
                        </Button>
                    )}

                    {actionType === "edit" && (
                        <Button
                            className="btn btn-primary"
                            type="submit"
                        >
                            <i className="bi bi-save2"></i> {buttonTitle}
                        </Button>
                    )}

                    {actionType === "delete" && (
                        <Button
                            className="btn btn-danger"
                            type="submit"
                        >
                            <i className="bi bi-trash"></i> {buttonTitle}
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    )

}

export default TeamForm