import React, { useState, useEffect, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { Popup } from "react-leaflet"
import Form from 'react-bootstrap/Form'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'

import FeaturesService from "services/FeaturesService"

import TaskActionModal from "components/modals/TaskActionModal"
import MissionActionModal from "components/modals/MissionActionModal"

const FeatureDrawPopup = ({ drawLayer, group, updateGroupData }) => {

    const [selectedFeature, setSelectedFeature] = useState(false)

    const [modalInfo, setModalInfo] = useState(false)
    const [modalTask, setModalTask] = useState(false)
    const [modalMission, setModalMission] = useState(false)

    const toggleModalInfo = useCallback(() => setModalInfo(prev => !prev), [modalInfo])
    const toggleModalTask = useCallback(() => setModalTask(prev => !prev), [modalTask])
    const toggleModalMission = useCallback(() => setModalMission(prev => !prev), [modalMission])

    const [ref, setRef] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    const [formValid, setFormValid] = useState(false)
    const [inputDisabled, setInputDisabled] = useState(false)

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFieldValue(name, value)
    }

    const setFieldValue = (fieldName, value) => {

        switch (fieldName) {
            case 'ref':
                setRef(value)
                break
            case 'name':
                setName(value)
                break
            case 'description':
                setDescription(value)
                break
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget
        if (form.checkValidity()) {
            const feature = {
                properties: {
                    ...selectedFeature.properties,
                    ref: ref,
                    name: name,
                    description: description
                }
            }
            FeaturesService.updateFeature({ layerId: drawLayer._id, featureId: selectedFeature.id, feature })
                .then(res => {
                    setFormValid(false)
                    //onSubmit()
                })
        }
        setFormValid(true)

    }


    useEffect(() => {
        if (!selectedFeature) return
        setRef(selectedFeature.properties.ref)
        setName(selectedFeature.properties.name)
        setDescription(selectedFeature.properties.description)
    }, [selectedFeature])

    return (
        <Popup
            maxWidth={500}
            eventHandlers={{
                add: (e) => {
                    // Handle the 'open' event
                    let feature = e.target._source.toGeoJSON()
                    feature = { ...feature, layerId: drawLayer._id }
                    setSelectedFeature(feature)
                },
                remove: (e) => {
                    // Handle the 'close' event
                    setSelectedFeature(false)
                    setRef('')
                    setName('')
                    setDescription('')
                }
            }}
        >
            {selectedFeature && (
                <>
                    <p><i className="bi bi-layers-half"></i> {drawLayer.name} {selectedFeature.id}</p>
                    <div className="text-primary">
                        <hr />
                    </div>
                    <Form noValidate validated={formValid} onSubmit={handleSubmit} encType="multipart/form-data">
                        <Form.Group className="mb-3" controlId="id">
                            <Form.Label>Id:</Form.Label>
                            <Form.Control
                                type="text"
                                name="id"
                                value={selectedFeature.id}
                                disabled={"disabled"}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="ref">
                            <Form.Label><FormattedMessage id="featureReference" defaultMessage="Reference" />:</Form.Label>
                            <Form.Control
                                type="text"
                                name="ref"
                                value={ref}
                                onChange={handleInputChange}
                                disabled={inputDisabled && ("disabled")}
                                required="required"
                            />
                            <Form.Control.Feedback type="invalid">
                                <FormattedMessage id="featureReferenceInvalid" defaultMessage="Provide a reference id" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label><FormattedMessage id="featureName" defaultMessage="Name" />:</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={name}
                                onChange={handleInputChange}
                                disabled={inputDisabled && ("disabled")}
                                required="required"
                            />
                            <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="featureNameInvalid" defaultMessage="Provide a feature name" />
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="description">
                            <Form.Label><FormattedMessage id="featureDescription" defaultMessage="Description" />:</Form.Label>
                            <Form.Control
                                name="description"
                                value={description}
                                as="textarea"
                                disabled={inputDisabled && ("disabled")}
                                rows={3}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <div className="text-center">
                            <ButtonGroup size="sm">
                                <Button
                                    className="btn btn-dark"
                                    type="submit"
                                >
                                    <i className="bi bi-save2"></i> <FormattedMessage id="featureUpdateBtn" defaultMessage="Update" />
                                </Button>
                                <DropdownButton
                                    key={"lg-drawlayer-fg-dropdown-actions-" + selectedFeature.id}
                                    as={ButtonGroup}
                                    title={<span><small><i className="bi bi-lightning-fill"></i> <FormattedMessage id="featureActionsBtn" defaultMessage="Actions" /></small></span>}
                                    variant="btn btn-outline-primary"
                                    id="bg-nested-dropdown">
                                    <Dropdown.Item
                                        eventKey="lg-drawlayer-fg-dropdown-actions-item-task"
                                        onClick={() => {
                                            toggleModalTask(true)
                                        }}>
                                        <i className="bi bi-clipboard2"></i> <FormattedMessage id="featureCreateTaskBtn" defaultMessage="Create task" />
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        eventKey="lg-geojson-fg-dropdown-actions-item-mission"
                                        onClick={() => {
                                            toggleModalMission(true)
                                        }}
                                    >
                                        <i className="bi bi-grid-1x2"></i> <FormattedMessage id="featureCreateMissionBtn" defaultMessage="Create mission" />
                                    </Dropdown.Item>
                                </DropdownButton>
                            </ButtonGroup>
                        </div>

                    </Form>

                    <div className="text-primary">
                        <hr />
                    </div>
                    <TaskActionModal
                        modal={modalTask}
                        setModal={setModalTask}
                        toggle={toggleModalTask}
                        selectedFeature={selectedFeature}
                        group={group}
                        updateGroupData={updateGroupData}
                    />
                    <MissionActionModal
                        modal={modalMission}
                        setModal={setModalMission}
                        toggle={toggleModalMission}
                        selectedFeature={selectedFeature}
                        group={group}
                        updateGroupData={updateGroupData}
                    />
                </>
            )}
        </Popup>
    )
}

export default FeatureDrawPopup