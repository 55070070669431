import React from "react"
import { FormattedMessage } from 'react-intl'
import { Link } from "react-router-dom"

const Navbar = ({ state }) => {

  const { currentUser, showModeratorBoard, showAdminBoard } = state

  return (
    <header className="navbar navbar-expand navbar-light sticky-top bg-brand flex-md-nowrap p-0 shadow">
      <Link to={"/"} className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6">
        <img
          src="/logo_civilio_trans.png"
          height="25"
          className="d-inline-block align-top"
          alt="CIVILIO"
        />
      </Link>
      <div className="navbar-nav ms-auto text-right">
        <div className="nav-item text-nowrap">
          <Link to={"/home"} className="nav-link">
            <i className="bi bi-house-fill"></i> <FormattedMessage id="navHome" defaultMessage="Home" />
          </Link>
        </div>

        {showAdminBoard && (
          <div className="nav-item text-nowrap">
            <Link to={"/config"} className="nav-link">
              <i className="bi bi-gear-fill"></i> <FormattedMessage id="navSysConfig" defaultMessage="System Config" />
            </Link>
          </div>
        )}

        {currentUser ? (
          <><div className="nav-item text-nowrap">
            <Link to={"/profile"} className="nav-link">
              <i className="bi bi-person-square"></i> {currentUser.name}
            </Link>
          </div><div className="nav-item text-nowrap">
              <Link to={"/logout"} className="nav-link">
                <i className="bi bi-door-closed-fill"></i> <FormattedMessage id="navLogout" defaultMessage="Logout" />
              </Link>
            </div></>
        ) : (
          <><div className="nav-item text-nowrap">
            <Link to={"/login"} className="nav-link">
              <i className="bi bi-door-open-fill"></i> <FormattedMessage id="navLogin" defaultMessage="Login" />
            </Link>
          </div><div className="nav-item text-nowrap">
              <Link to={"/register"} className="nav-link">
                <FormattedMessage id="navSignup" defaultMessage="Sign Up" />
              </Link>
            </div></>
        )}
      </div>
    </header>
  )
}

export default Navbar