import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class UsersService {

  // Get users list
  getUsers = async (pageNum, search = '', limit = false) => {
    return await axios.get(API_URL + '/users?page=' + pageNum + '&search=' + search + '&limit=' + limit, { headers: authHeader() })
  }

  // Add new user
  addUser = async (user) => {
    return await axios.post(API_URL + '/users', user, { headers: authHeader() })
  }

  // Update user data
  updateUser = async (user, id) => {
    return await axios.patch(API_URL + '/users/' + id, user, { headers: authHeader() })
  }

  // Update user roles
  updateUserRoles = async (roles, id) => {
    return await axios.patch(API_URL + '/users/' + id + '/roles', roles, { headers: authHeader() })
  }

  // Get user data
  getUser = async (id) => {
    return await axios.get(API_URL + '/users/' + id, { headers: authHeader() })
  }

  // Delete user
  deleteUser = async (id) => {
    return await axios.delete(API_URL + '/users/' + id, { headers: authHeader() })
  }

  /*
    updateUser = (username, password) => {
      return axios
        .post(API_URL + "/auth/login", {
          email: username,
          password: password
        })
        .then(response => {
          if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
          }
  
          return response.data;
        });
    }
  */

  getModeratorBoard = () => {
    return axios.get(API_URL + '/profile', { headers: authHeader() })
  }

  getAdminBoard = () => {
    return axios.get(API_URL + '/profile', { headers: authHeader() })
  }
}

export default new UsersService()