import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import ListGroup from 'react-bootstrap/ListGroup'

import moment from 'moment'

import ConfigService from 'services/ConfigService'
import TeamsService from 'services/TeamsService'

import Spinner from "components/spinner/Spinner"
import Pagination from "components/pagination/TablePagination";
import TeamForm from 'components/forms/TeamForm'
import FormDataModal from 'components/modals/FormDataModal'

const TeamList = ({ groupId, entityId, userId, searchValue }) => {

    const fronConfig = ConfigService.getFrontConfig()

    const [modal, setModal] = useState({
        trigger: false,
        title: false,
        action: false,
        icon: false,
        elementId: false,
        groupId: groupId
    })

    const [teams, setTeams] = useState(false)
    const [pagination, setPagination] = useState(false)

    const getTeams = async (pageNum = 1) => {
        const options = {
            groupId: groupId,
            entityId: entityId,
            userId: userId,
            search: searchValue,
            pageNum: pageNum,
            limit: fronConfig.app.itemsListLimit
        }

        await TeamsService.getTeams(options)
            .then(res => {
                setTeams(res.data.team)
                setPagination(res.data.pagination)
            },
                error => {
                    setTeams(false)
                })

    }

    const goToPage = (pageNum) => {
        getTeams(pageNum)
    }

    const handleAddTeamBtn = () => {
        setModal({
            trigger: true,
            title: <FormattedMessage id="teamAdd" defaultMessage="Add response team item" />,
            action: 'add',
            icon: 'bi-plus-circle',
            elementId: false,
            groupId: groupId
        })
    }

    const handleEditTeamBtn = (teamId) => {
        setModal({
            trigger: true,
            title: <FormattedMessage id="teamEdit" defaultMessage="Edit response team item" />,
            action: 'edit',
            icon: 'bi-pencil-square',
            elementId: teamId,
            groupId: groupId
        })
    }

    const handleDeleteTeamBtn = (teamId) => {
        setModal({
            trigger: true,
            title: <FormattedMessage id="teamDelete" defaultMessage="Delete response team item" />,
            action: 'delete',
            icon: 'bi-trash',
            elementId: teamId,
            groupId: groupId
        })
    }

    const handleModalCallBack = (update) => {
        setModal({
            trigger: false,
            title: false,
            action: false,
            icon: false,
            elementId: false,
            groupId: groupId
        })
        if (update) {
            getTeams()
        }
    }

    useEffect(() => {
        getTeams()

        const interval = setInterval(() => {
            getTeams()
        }, 60000) // 60000ms = 1 minute

        return () => {
            clearInterval(interval)
        }
    }, [userId, searchValue])

    if (!teams) {
        return (
            <Spinner />
        )
    }
    return (
        <div>
            <Table hover>
                <thead>
                    <tr className="table-secondary">
                        <th scope="col">#</th>
                        <th><i className="bi bi-clock-fill"></i> <FormattedMessage id="teamStatus" defaultMessage="Status" /></th>
                        <th><i className="bi bi-people-fill"></i> <FormattedMessage id="teamUser" defaultMessage="User" /></th>
                        <th><i className="bi bi-envelope-fill"></i> <FormattedMessage id="usersEmail" defaultMessage="E-mail" /></th>
                        <th><i className="bi bi-phone-fill"></i> <FormattedMessage id="usersPhoneNumber" defaultMessage="Phone number" /></th>
                        <th><i className="bi bi-briefcase-fill"></i> <FormattedMessage id="usersEntityName" defaultMessage="Entity name" /></th>
                        <th className='text-end'><i className="bi bi-hourglass-top"></i> <FormattedMessage id="teamStartDate" defaultMessage="Start date" /></th>
                        <th className='text-end'><i className="bi bi-hourglass-bottom"></i> <FormattedMessage id="teamEndDate" defaultMessage="End date" /></th>
                        <th className='text-end'><i className="bi bi-calendar-event-fill"></i> <FormattedMessage id="teamUpdated" defaultMessage="Updated" /></th>
                        <th className='text-end'>
                            <Button type="button" className="btn btn-sm btn-light btn-outline-dark" onClick={handleAddTeamBtn}><i className="bi bi-plus-circle"></i> <FormattedMessage id="textAdd" defaultMessage="Add" /></Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {teams && teams.map((team, index) => {
                        return (
                            <tr key={"tr-team-" + team._id}>
                                <th scope="row">{index + 1 + pagination.skipItems}</th>
                                <td>{team.status}</td>
                                <td>{team.user.name}</td>
                                <td>{team.user.email}</td>
                                <td>{team.user.phone}</td>
                                <td>{team.user.entity.name}</td>
                                <td className='text-end'>
                                    {moment(team.startDate).fromNow()}
                                </td>
                                <td className='text-end'>
                                    {moment(team.endDate).fromNow()}
                                </td>
                                <td className='text-end'>
                                    {moment(team.updatedAt).fromNow()}
                                </td>
                                <td>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <Button type="button" className="btn btn-sm btn-light btn-outline-primary" onClick={() => handleEditTeamBtn(team._id)}><i className="bi bi-pencil-square"></i> <FormattedMessage id="textEdit" defaultMessage="Edit" /></Button>
                                        <Button type="button" className="btn btn-sm btn-light btn-outline-danger" onClick={() => handleDeleteTeamBtn(team._id)}><i className="bi bi-trash"></i> <FormattedMessage id="textDelete" defaultMessage="Delete" /></Button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                    }
                </tbody>
            </Table>

            {pagination && pagination.totalPages > 1 && (
                <Pagination data={pagination} goToPageFunction={goToPage} />
            )}

            {modal.trigger && (
                <FormDataModal
                    title={modal.title}
                    icon={modal.icon}
                    BodyContent={TeamForm}
                    callBack={handleModalCallBack}
                    trigger={modal.trigger}
                    elementId={modal.elementId}
                    action={modal.action}
                    groupId={modal.groupId} />
            )}

        </div >
    )
}

export default TeamList