import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import L from 'leaflet'
import styles from "./custom-button.css"

const CustomButton = ({ callBack }) => {
  const map = useMap()

  useEffect(() => {
    if (!map) return

    const customControler = L.Control.extend({

      options: {
        position: "topright",
        className: `${styles.customButtonFoo} leaflet-bar`,
        html: '<i class="bi bi-arrow-clockwise"></i>',
        style:
          "width: 34px; height: 34px; left: 0; margin-top: 0; display: flex; cursor: pointer; justify-content: center; font-size: 2rem;",
      },

      onAdd: function () {
        const btn = L.DomUtil.create("button")
        btn.title = "Testing rotation"
        btn.innerHTML = this.options.html
        //btn.textContent = "";
        //btn.className = `${styles.customButtonFoo}`;

        btn.onmouseover = function () {
          this.style.transform = "scale(1.3)"
        }

        btn.onmouseout = function () {
          this.style.transform = "scale(1)"
        }

        btn.onclick = function () {
          document.body.classList.add("rotate")
          callBack(true) // Reload Update data
          setTimeout(() => {
            document.body.classList.remove("rotate")
          }, 4000)
        }

        return btn
      },
    })

    map.addControl(new customControler())

  }, [map])

  return null
}

export default CustomButton