import React, { useState, useEffect } from "react"
import { useIntl, FormattedMessage } from 'react-intl'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { PDFDownloadLink } from '@react-pdf/renderer'

import PicturesService from 'services/PicturesService'
import AuthService from "services/AuthService"

import { AlertError, AlertLoading } from 'helpers/AlertHelper'

import TaskReportPDF from 'components/pdf/TaskReport.pdf'

const ReportModal = ({ task, callback, mission }) => {
    const intl = useIntl()

    const [show, setShow] = useState(false)
    const [fullscreen, setFullscreen] = useState(true)

    const [currentUser, setCurrentUser] = useState(false)

    const handleShow = () => setShow(true)
    const handleClose = () => {
        setShow(false)
        callback(false)
    }

    const [imagesLoaded, setImagesLoaded] = useState(false)
    const [images, setImages] = useState([])

    const [error, setError] = useState(false)

    const getPictures = async () => {
        await PicturesService.getPictures(task._id).then(
            response => {
                const loadedImages = response.data
                setImages(loadedImages.map(obj => ({ ...obj, saved: true })))
                setImagesLoaded(true)
            },
            error => {
                setError(error)
            }
        )
    }

    const getUser = async () => {
        const authUser = await AuthService.getCurrentUser()
        setCurrentUser(authUser)
    }

    useEffect(() => {
        handleShow()
        getUser()
        getPictures()
    }, [])

    return (
        <Modal show={show} fullscreen={fullscreen} onHide={handleClose}>
            <Modal.Header closeButton={false} className={'p-2 bg-' + task.resultProperties.bgcolor} style={{ color: task.resultProperties.textcolor }}>
                <Button size={'sm'} onClick={handleClose} variant={task.resultProperties.bgcolor}><i className="bi bi-arrow-left h4"></i></Button>
                <Modal.Title style={{ color: task.resultProperties.textcolor }} className="h6 d-flex justify-content-between align-items-center">
                    <i className="bi bi-file-pdf-fill"></i>&nbsp;{task.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    {imagesLoaded ? (
                        <PDFDownloadLink
                            document={
                                <TaskReportPDF task={task} images={images} group={task.group} user={currentUser} intl={intl} />
                            }
                            fileName={"task_report_" + task.feature.ref + ".pdf"}>
                            {({ blob, url, loading, error }) =>
                                loading ? <Button variant={task.resultProperties.bgcolor}>PDF generating ...</Button> : <Button variant={task.resultProperties.bgcolor}>Download PDF Report</Button>
                            }
                        </PDFDownloadLink>
                    ) : (
                        <AlertLoading isLoaded={(imagesLoaded)} />
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {error &&
                    <AlertError error={error} />
                }
            </Modal.Footer>
        </Modal>
    )
}

export default ReportModal