import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

const ChartColsCard = ({ key, header = false, content = [], border = false, footer = false, className = '', color = '#f9cf35', numberAlign = 'right' }) => {
    return (
        <Card
            border={border ? border : ''}
            bg="light"
            text="black"
            className={className}
        >
            {header &&
                <Card.Header>{header}</Card.Header>
            }
            <Card.Body className='p-1'>
                {content.map((row, rowIndex) => {
                    return (
                        <Row key={key + '-row-' + rowIndex}>
                            {row.map((col, colIndex) => {
                                return (
                                    <Col key={key + '-row-' + rowIndex + '-col-' + colIndex} xs={Math.floor(12 / row.length)} className="align-items-center justify-content-center text-center">
                                        <span style={{ color: col.color ? col.color : color, fontSize: 35 }}>{col.icon}</span>
                                        <pre style={{ marginBottom: 0, textAlign: 'left' }}>{col.title}</pre>
                                        <h5 style={{ textAlign: {numberAlign} }}>{col.text}</h5>
                                    </Col>
                                )
                            })}
                        </Row>
                    )
                })}
            </Card.Body>
            {footer &&
                <Card.Footer>{footer}</Card.Footer>
            }

        </Card>
    )
}

export default ChartColsCard