import React, { useState, useEffect } from "react"
import { FormattedMessage } from 'react-intl'
import { Link } from "react-router-dom"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import AuthService from "../services/AuthService"

import { withRouter } from '../utils/withRouter'

import { passwordsMatch } from "../utils/ValidationForm"
import { AlertError } from "helpers/AlertHelper"

const Signup = (props) => {

    const [linkCode, setLinkCode] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [rPassword, setRPassword] = useState('')
    const [formValid, setFormValid] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const [gdpr, setGdpr] = useState(null)
    const [gdprAccept, setGdprAccept] = useState(false)

    const getGdprInfo = async () => {
        await AuthService.getGdpr().then(
            response => {
                if (response.data.app.title && response.data.gdpr) {
                    setGdpr(response.data.gdpr)
                }
            }
        )
    }

    useEffect(() => {
        getGdprInfo()
    }, [])

    const handleCheckBoxChange = (e) => {
        switch (e.target.name) {
            case 'gdprAccept':
                setGdprAccept(e.target.checked)
                break
        }
    }

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFieldValue(name, value)
    }

    const setFieldValue = (fieldName, value) => {
        switch (fieldName) {
            case 'linkCode':
                setLinkCode(value.toUpperCase())
                break
            case 'name':
                setName(value)
                break
            case 'phone':
                setPhone(value)
                break
            case 'email':
                setEmail(value)
                break
            case 'password':
                setPassword(value)
                break
            case 'rPassword':
                setRPassword(value)
                break
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget
        if (form.checkValidity()) {
            setLoading(true)
            const user = {
                linkCode: linkCode,
                name: name,
                phone: phone,
                email: email,
                password: password
            }
            await AuthService.signup(user).then(
                () => {
                    setFormValid(false)
                    props.router.navigate("/login")
                    //window.location.reload()                    
                    return
                },
                error => {
                    setLoading(false)
                    setError(error)
                }
            )
        }

        setFormValid(true)
    }

    if (!gdpr) {
        return null
    }

    return (
        <div className="col-md-12">
            <div className="login login-container">
                <p><img
                    src="/logo_civilio_trans.png"
                    alt="Civilio.eu Information Systems"
                    className="login-img"
                /></p>
                <h1 className="h3 mb-3 fw-normal"><FormattedMessage id="signupTitle" defaultMessage="Register your account" /></h1>
                <Form
                    noValidate
                    validated={formValid}
                    onSubmit={handleSubmit}
                    autoComplete={"off"}
                >

                    <Form.Group className="mb-3" controlId="linkCode">
                        <Form.Label><FormattedMessage id="signupEntityCode" defaultMessage="Entity code" />:</Form.Label>
                        <Form.Control
                            type="text"
                            name="linkCode"
                            value={linkCode}
                            onChange={handleInputChange}
                            required="required"
                            autoComplete={"off"}
                        />
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="signupEntityCodeInvalid" defaultMessage="Provide an entity code" />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label><FormattedMessage id="signupName" defaultMessage="Your full name" />:</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={name}
                            onChange={handleInputChange}
                            required="required"
                            autoComplete={"off"}
                        />
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="signupNameInvalid" defaultMessage="Provide your name and surname" />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="phone">
                        <Form.Label><FormattedMessage id="signupPhoneNumber" defaultMessage="Phone number" />:</Form.Label>
                        <Form.Control
                            type="text"
                            name="phone"
                            value={phone}
                            onChange={handleInputChange}
                            required="required"
                            autoComplete={"off"}
                        />
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="signupPhoneNumberInvalid" defaultMessage="Provide your phone number" />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label><FormattedMessage id="signupEmail" defaultMessage="E-mail" />:</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={email}
                            onChange={handleInputChange}
                            required="required"
                            autoComplete={"off"}
                            pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                        />
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="signupEmailInvalid" defaultMessage="Provide your email address" />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="password">
                        <Form.Label><FormattedMessage id="signupPassword" defaultMessage="Password" />:</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={password}
                            onChange={handleInputChange}
                            required="required"
                            autoComplete={"off"}
                        />
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="signupPasswordInvalid" defaultMessage="Provide a password" />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="rPassword">
                        <Form.Label><FormattedMessage id="signupRepeatPassword" defaultMessage="Repeat Password" />:</Form.Label>
                        <Form.Control
                            type="password"
                            name="rPassword"
                            value={rPassword}
                            onChange={handleInputChange}
                            onKeyUp={handleInputChange}
                            required="required"
                            autoComplete={"off"}
                            isInvalid={!passwordsMatch(password, rPassword)}
                        />
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="signupRepeatPasswordInvalid" defaultMessage="Repeat the password" />
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Check isInvalid>
                        <Form.Check.Input type={'checkbox'}
                            name="gdprAccept"
                            required="required"
                            onChange={handleCheckBoxChange}
                        />
                        <Form.Check.Label><a href={gdpr.url} target="_blank">{gdpr.textAdvise}</a></Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                            <FormattedMessage id="signupGDPRInvalid" defaultMessage="You must accept our GDPR!" />
                        </Form.Control.Feedback>
                    </Form.Check>

                    <hr />
                    <div className="form-group">
                        <Button
                            type="submit"
                            className="w-100 btn btn-lg btn-primary btn-block"
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span> <FormattedMessage id="signupRegister" defaultMessage="Register" /></span>
                        </Button>
                    </div>

                    <div className="form-group">
                        <p className="text-center">
                            <FormattedMessage id="signupHaveAccount" defaultMessage="Do you have an account?" /> <Link to={"/"}><FormattedMessage id="signupLogin" defaultMessage="Login" /></Link>
                        </p>
                    </div>

                    <AlertError error={error} />

                </Form>
            </div>
            <div className="text-center">
                &copy; {new Date().getFullYear()} Civilio Information Systems
            </div>
        </div>
    )
}

export default withRouter(Signup)