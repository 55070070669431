import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Table from 'react-bootstrap/Table'

import moment from 'moment'

import ConfigService from 'services/ConfigService'
import TeamsService from 'services/TeamsService'

import Spinner from "components/spinner/Spinner"
import Pagination from "components/pagination/TablePagination";

const IoTList = ({ groupId, entityId, userId, searchValue }) => {

    const fronConfig = ConfigService.getFrontConfig()

    const [teams, setTeams] = useState(false)
    const [pagination, setPagination] = useState(false)

    const getTeams = async (pageNum = 1) => {
        const options = {
            groupId: groupId,
            entityId: entityId,
            userId: userId,
            search: searchValue,
            pageNum: pageNum,
            limit: fronConfig.app.itemsListLimit
        }

        await TeamsService.getTeams(options)
            .then(res => {
                setTeams(res.data.team)
                setPagination(res.data.pagination)
            },
                error => {
                    setTeams(false)
                })

    }

    const goToPage = (pageNum) => {
        getTeams(pageNum)
    }

    useEffect(() => {
        getTeams()

        const interval = setInterval(() => {
            getTeams()
        }, 60000) // 60000ms = 1 minute

        return () => {
            clearInterval(interval)
        }
    }, [userId, searchValue])

    if (!teams) {
        return (
            <Spinner />
        )
    }
    return (
        <div>
            <Table hover>
                <thead>
                    <tr className="table-secondary">
                        <th scope="col">#</th>
                        <th><i className="bi bi-wifi"></i> <FormattedMessage id="iotOnline" defaultMessage="Online" /></th>
                        <th><i className="bi bi-battery-full"></i> <FormattedMessage id="iotBattery" defaultMessage="Battery" /></th>
                        <th><i className="bi bi-geo-alt-fill"></i> <FormattedMessage id="iotGPS" defaultMessage="GPS" /></th>
                        <th><i className="bi bi-bluetooth"></i> <FormattedMessage id="iotBluetooth" defaultMessage="Bluetooth" /></th>
                        <th><i className="bi bi-activity"></i> <FormattedMessage id="iotActivity" defaultMessage="Activity" /></th>
                        <th><i className="bi bi-clock-fill"></i> <FormattedMessage id="iotStatus" defaultMessage="Status" /></th>
                        <th><i className="bi bi-people-fill"></i> <FormattedMessage id="iotUser" defaultMessage="User" /></th>
                        <th><i className="bi bi-phone-fill"></i> <FormattedMessage id="iotPhoneNumber" defaultMessage="Phone number" /></th>
                        <th><i className="bi bi-briefcase-fill"></i> <FormattedMessage id="iotEntity" defaultMessage="Entity" /></th>
                        <th className='text-end'><i className="bi bi-hourglass-top"></i> <FormattedMessage id="iotStartDate" defaultMessage="Start" /></th>
                        <th className='text-end'><i className="bi bi-hourglass-bottom"></i> <FormattedMessage id="iotEndDate" defaultMessage="End" /></th>
                        <th className='text-end'><i className="bi bi-calendar-event-fill"></i> <FormattedMessage id="iotUpdated" defaultMessage="Updated" /></th>
                    </tr>
                </thead>
                <tbody>
                    {teams && teams.map((team, index) => {
                        return (
                            <tr key={"tr-team-" + team._id}>
                                <th scope="row">{index + 1 + pagination.skipItems}</th>
                                <td>{team.user.socketId ? <i className="bi bi-wifi"></i> : <i className="bi bi-wifi-off"></i>}</td>
                                <td>{team.user.socketId ? <i className="bi bi-battery-full"></i> : <i className="bi bi-battery-half"></i>}</td>
                                <td>{team.user.socketId ? <i className="bi bi-geo-alt-fill"></i> : <i className="bi bi-geo-alt"></i>}</td>
                                <td><i className="bi bi-bluetooth"></i></td>
                                <td>
                                    <i className="bi bi-smartwatch"></i>&nbsp;
                                    <i className="bi-heart-pulse"></i>&nbsp;
                                    <i className="bi bi-lungs"></i>
                                </td>
                                <td>{team.status}</td>
                                <td>{team.user.name}</td>
                                <td>{team.user.phone}</td>
                                <td>{team.user.entity.name}</td>
                                <td className='text-end'>
                                    {moment(team.startDate).fromNow()}
                                </td>
                                <td className='text-end'>
                                    {moment(team.endDate).fromNow()}
                                </td>
                                <td className='text-end'>
                                    {moment(team.updatedAt).fromNow()}
                                </td>
                            </tr>
                        )
                    })
                    }
                </tbody>
            </Table>

            {pagination && pagination.totalPages > 1 && (
                <Pagination data={pagination} goToPageFunction={goToPage} />
            )}

        </div >
    )
}

export default IoTList