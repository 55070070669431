import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

const exampleLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const exampleData = {
    labels: exampleLabels,
    datasets: [
        {
            label: 'Dataset 1',
            data: exampleLabels.map(() => 800),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Dataset 2',
            data: exampleLabels.map(() => 900),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
}

const BarChart = ({ title = false, data = exampleData }) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: title ? true : false,
                text: title,
            },
            tooltip: {
                mode: 'index', // Agrupa los tooltips por etiquetas
                intersect: true,
                position: 'average', // average - nearest
                /*
                callbacks: {
                    label: function (tooltipItem, data) {
                    },
                    title: function (tooltipItems) {
                    },
                },*/
            },
        },
    }

    return <Bar options={options} data={data} />
}

export default BarChart