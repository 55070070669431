import React from 'react'
import { Link, useLocation, useSearchParams } from "react-router-dom";

const TablePagination = (props) => {
  const {
    page,
    //limit,
    //totalItems,
    totalPages,
    //    skipItems,
    prevPage,
    nextPage,
    hasPrevPage,
    hasNextPage,
    //    prevUrl,  // api URL
    //    nextUrl,  // api URL
    //    pageUrl   // api URL
  } = props.data

  //const goToPage = props.goToPageFunction

  const urlPages = Array.from({ length: totalPages }, (_, i) => '?page=' + i)

  return (
    <ul className="pagination justify-content-center">
      <li className={hasPrevPage ? ("page-item") : ("page-item disabled")}>
        {hasPrevPage ? (
          <Link to="#" className="page-link" onClick={() => props.goToPageFunction(prevPage)}>
            Previous
          </Link>
        ) : (
          <span className="page-link">
            Previous
          </span>
        )}
      </li>

      {urlPages.map((url, i) =>
        <li className={i + 1 === page ? ("page-item active") : ("")} aria-current="page" key={i + 1}>
          {i + 1 === page ? (
            <span className="page-link">{i + 1}</span>

          ) : (
            <Link to="#" className="page-link" onClick={() => props.goToPageFunction(i + 1)}>
              {i + 1}
            </Link>
          )
          }
        </li>
      )}

      <li className={hasNextPage ? ("page-item") : ("page-item disabled")}>
        {hasNextPage ? (
          <Link to="#" className="page-link" onClick={() => props.goToPageFunction(nextPage)}>
            Next
          </Link>
        ) : (
          <span className="page-link">
            Next
          </span>
        )}
      </li>
    </ul>
  )

}

export default TablePagination