import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import LogbookService from '../../services/LogbookService'
import MissionsService from '../../services/MissionsService'

import { REG_TYPE_LIST } from '../../config/logbook'

function LogbookForm({ groupId, elementId, actionType, callBack }) {

    const [type, setType] = useState('')
    const [missionsId, setMissionsId] = useState([])
    const [missions, setMissions] = useState([])
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [buttonTitle, setButtonTitle] = useState('')
    const [inputDisabled, setInputDisabled] = useState(false)
    const [formValid, setFormValid] = useState(false)
    const [showMsg, setShowMsg] = useState(false)
    const [message, setMessage] = useState(false)

    const handleErrorMsg = (data) => {
        setShowMsg(true)
        setMessage(`Error (${data.error.code}): ${data.msg}`)
    }

    const getMissions = () => {
        const options = {
            groupId: groupId,
            limit: 1000
        }
        MissionsService.getGroupMissions(options)
            .then(res => {
                const missionsArray = res.data.missions.sort((a, b) => {
                    const nameA = a.name.toLowerCase()
                    const nameB = b.name.toLowerCase()
                    return nameA === nameB ? 0 : nameA.localeCompare(nameB, undefined, { numeric: true })
                })
                setMissions(missionsArray)
            },
                error => {
                    handleErrorMsg(error.response.data)
                })
    }

    useEffect(() => {

        getMissions()

        if (elementId) {
            LogbookService.getLogBook(groupId, elementId)
                .then(res => {
                    setType(res.data.type || '')
                    setTitle(res.data.title || '')
                    setDescription(res.data.description || '')
                    setMissionsId(res.data.missionsId || '')
                },
                    error => {
                        handleErrorMsg(error.response.data)
                    })
        }

        switch (actionType) {
            case 'edit':
                setButtonTitle(<FormattedMessage id="textSave" defaultMessage="Save" />)
                break
            case 'delete':
                setButtonTitle(<FormattedMessage id="textDelete" defaultMessage="Delete" />)
                setInputDisabled(true)
                break
            default:
                setButtonTitle(<FormattedMessage id="textAdd" defaultMessage="Add" />)

        }

    }, [])

    const handleCallBack = () => {
        callBack(true)
    }

    const handleSelectMissionsChange = (e) => {
        setMissionsId([].slice.call(e.target.selectedOptions).map(item => item.value))
    }

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFieldValue(name, value)
    }

    const setFieldValue = (fieldName, value) => {
        switch (fieldName) {
            case 'type':
                setType(value)
                break
            case 'title':
                setTitle(value)
                break
            case 'description':
                setDescription(value)
                break
            default:
                break
        }
    }

    const handleOnSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget
        if (form.checkValidity()) {
            const logbook = {
                type: type,
                missionsId: missionsId,
                title: title,
                description: description
            }

            if (elementId && actionType === "edit") {
                LogbookService.updateLogBook(groupId, elementId, logbook)
                    .then(res => {
                        setFormValid(false)
                        handleCallBack()
                    },
                        error => {
                            handleErrorMsg(error.response.data)
                        })
            } else if (actionType === "add") {
                LogbookService.addLogBook(groupId, logbook)
                    .then(res => {
                        setFormValid(false)
                        handleCallBack()
                    },
                        error => {
                            handleErrorMsg(error.response.data)
                        })
            } else if (actionType === "delete") {
                LogbookService.deleteLogBook(groupId, elementId)
                    .then(res => {
                        setFormValid(false)
                        handleCallBack()
                    },
                        error => {
                            handleErrorMsg(error.response.data)
                        })
            }
        }

        setFormValid(true)
    }

    return (
        <div>
            <Alert show={showMsg} variant={'danger'} onClose={() => setShowMsg(false)} dismissible>
                {message}
            </Alert>

            <Form noValidate validated={formValid} onSubmit={handleOnSubmit}>
                {elementId && (
                    <Form.Group className="mb-3" controlId="id">
                        <Form.Label>Id:</Form.Label>
                        <Form.Control
                            type="text"
                            name="id"
                            value={elementId}
                            disabled={"disabled"}
                        />
                    </Form.Group>
                )}

                <Form.Group className="mb-3" controlId="type">
                    <Form.Label><FormattedMessage id="logbookType" defaultMessage="Type" />:</Form.Label>
                    <Form.Select
                        name="type"
                        value={type}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    >
                        <option value="" key="select-type-0">Select item type</option>
                        {REG_TYPE_LIST.map(rType =>
                            <option value={rType} key={rType}>{rType}</option>
                        )}
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="logbookTypeInvalid" defaultMessage="Select logbook item type" />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="title">
                    <Form.Label><FormattedMessage id="logbookTitle" defaultMessage="Title" />:</Form.Label>
                    <Form.Control
                        type="text"
                        name="title"
                        value={title}
                        onChange={handleInputChange}
                        disabled={inputDisabled && ("disabled")}
                        required="required"
                    />
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="logbookTitleInvalid" defaultMessage="Provide a title for the item logbook" />
                    </Form.Control.Feedback>
                </Form.Group>


                <Form.Group className="mb-3" controlId="description">
                    <Form.Label><FormattedMessage id="logbookDescription" defaultMessage="Description" />:</Form.Label>
                    <Form.Control
                        name="description"
                        value={description}
                        as="textarea"
                        disabled={inputDisabled && ("disabled")}
                        rows={3}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="missionsId">
                    <Form.Label><FormattedMessage id="logbookMissions" defaultMessage="Missions" />:</Form.Label>
                    <Form.Select
                        name="missionsId"
                        value={missionsId}
                        onChange={handleSelectMissionsChange}
                        disabled={inputDisabled && ("disabled")}
                        multiple
                    >
                        {missions && missions.map(mission =>
                            <option value={mission._id} key={'select-mission-' + mission._id}>{mission.name}</option>
                        )}
                    </Form.Select>
                </Form.Group>

                <div className="d-grid gap-2">
                    {actionType === "add" && (
                        <Button
                            className="btn btn-dark"
                            type="submit"
                        >
                            <i className="bi bi-plus-circle"></i> {buttonTitle}
                        </Button>
                    )}

                    {actionType === "edit" && (
                        <Button
                            className="btn btn-primary"
                            type="submit"
                        >
                            <i className="bi bi-save2"></i> {buttonTitle}
                        </Button>
                    )}

                    {actionType === "delete" && (
                        <Button
                            className="btn btn-danger"
                            type="submit"
                        >
                            <i className="bi bi-trash"></i> {buttonTitle}
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    )

}

export default LogbookForm