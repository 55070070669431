import React from "react"
import { FormattedMessage } from 'react-intl'
import { Popup } from "react-leaflet"
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'

const FeaturePopup = (props) => {

    const index = props.index
    const title = props.title
    const name = props.name
    const layerId = props.layerId
    const feature = props.feature
    const toggleModalInfo = props.toggleModalInfo
    const toggleModalTask = props.toggleModalTask
    const toggleModalMission = props.toggleModalMission
    const setSelectedFeature = props.setSelectedFeature
    const featureIdInProperty = props.featureIdInProperty
    const featureNameInProperty = props.featureNameInProperty

    return (
        <Popup
            key={"lg-geojson-fg-popup" + index}
            maxWidth={500}
        >
            <p><i className="bi bi-layers-half"></i> {title}</p>
            <div className="text-primary">
                <hr />
            </div>
            <p>Id: {feature.properties[featureIdInProperty]}</p>
            <p className="h6">{name}</p>
            {feature.properties && feature.properties['_message_'] &&
            <p>{feature.properties['_message_']}</p>
            }
            <div className="text-center">
                <ButtonGroup
                    size="sm">
                    <Button
                        key={"lg-geojson-fg-btnInfo-" + index}
                        id="button"
                        variant="btn btn-outline-primary"
                        //className="btn btn-secondary"
                        onClick={() => {
                            if (!feature.properties.id) {
                                feature.properties.id = feature.properties[featureIdInProperty]

                            }
                            if (!feature.properties.name) {
                                feature.properties.name = feature.properties[featureNameInProperty]
                            }
                            feature.layerId = layerId
                            toggleModalInfo(true)
                            setSelectedFeature(feature)
                        }}
                    >
                        <i className="bi bi-info-circle-fill"></i> <FormattedMessage id="featureMoreInfoBtn" defaultMessage="More Info" />
                    </Button>
                    <DropdownButton
                        key={"lg-geojson-fg-dropdown-actions-" + index}
                        as={ButtonGroup}
                        title={<span><small><i className="bi bi-lightning-fill"></i> <FormattedMessage id="featureActionsBtn" defaultMessage="Actions" /></small></span>}
                        variant="btn btn-outline-primary"
                        id="bg-nested-dropdown">
                        <Dropdown.Item
                            eventKey="lg-geojson-fg-dropdown-actions-item-task"
                            onClick={() => {
                                if (!feature.properties.id) {
                                    feature.properties.id = feature.properties[featureIdInProperty]

                                }
                                if (!feature.properties.name) {
                                    feature.properties.name = feature.properties[featureNameInProperty]
                                }
                                feature.layerId = layerId
                                toggleModalTask(true)
                                setSelectedFeature(feature)
                            }}>
                            <i className="bi bi-clipboard2"></i> <FormattedMessage id="featureCreateTaskBtn" defaultMessage="Create task" />
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey="lg-geojson-fg-dropdown-actions-item-mission"
                            onClick={() => {
                                if (!feature.properties.id) {
                                    feature.properties.id = feature.properties[featureIdInProperty]

                                }
                                if (!feature.properties.name) {
                                    feature.properties.name = feature.properties[featureNameInProperty]
                                }
                                feature.layerId = layerId
                                toggleModalMission(true)
                                setSelectedFeature(feature)
                            }}
                        >
                            <i className="bi bi-grid-1x2"></i> <FormattedMessage id="featureCreateMissionBtn" defaultMessage="Create mission" />
                        </Dropdown.Item>
                    </DropdownButton>
                </ButtonGroup>
            </div>
            <div className="text-primary">
                <hr />
            </div>
        </Popup>
    )
}

export default FeaturePopup