import React, { useEffect } from "react"
import { FormattedMessage } from 'react-intl'
import EventBus from "../utils/EventBus"
import { withRouter } from '../utils/withRouter'

import AuthService from "../services/AuthService"
import { AlertMsg } from "helpers/AlertHelper"

const Logout = ({ router }) => {

  const closeSession = async () => {
    await AuthService.logout().then(
      response => {
        EventBus.dispatch("logout")
        router.navigate("/login")
      },
      error => {
        EventBus.dispatch("logout")
        router.navigate("/login")
      }
    )
  }

  useEffect(() => {
    closeSession()
    //console.log("logout ...")
  }, [])

  return (
    <AlertMsg message={<FormattedMessage id="textClosingSession" defaultMessage="Closing session" />} variant="warning" />
  )
}

export default withRouter(Logout)