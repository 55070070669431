import { useEffect, useState } from 'react'
import { TileLayer, LayersControl, WMSTileLayer, useMap } from 'react-leaflet'
import { MAPBOX_TOKEN } from '../../../config/map'

import GeoJSONLayer from '../geojson/GeoJSONLayer'
import DrawTools from "../controls/DrawTools"
import 'leaflet-draw/dist/leaflet.draw.css'

import LayersService from 'services/LayersService'

import { AlertError } from 'helpers/AlertHelper'

const urlCats = () => {
    const random = Math.ceil(Math.random() * 40)
    return `https://loremflickr.com/250/250?lock=${random}`
}

const Layers = ({ group, updateGroupData }) => {
    const map = useMap()

    const openStreetMapLayer = {
        type: "base",
        name: "OpenStreetMap",
        attribution: '&copy; <a target="_blank" href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        maxZoom: 19
    }

    const catsLayer = {
        type: "personalized",
        name: "Cats",
        attribution: '&copy; <a target="_blank" href="https://babakfakhamzadeh.com/project/loremflickr-com/">loremflickr</a> contributors',
        url: { urlCats }
    }

    const basicLayersArray = [
        {
            type: "base",
            name: "IGN España",
            attribution: '&copy; <a target="_blank" href="https://www.ign.es">IGN España</a> contributors',
            url: 'https://tms-ign-base.idee.es/1.0.0/IGNBaseTodo/{z}/{x}/{-y}.jpeg',
            maxZoom: 17
        },
        {
            type: "base",
            name: "Sentinel2 PNOA MA",
            attribution: '&copy; <a target="_blank" href="https://www.scne.es/">Sistema Cartográfico Nacional de España</a> contributors',
            url: 'https://tms-pnoa-ma.idee.es/1.0.0/pnoa-ma/{z}/{x}/{-y}.jpeg',
            maxZoom: 19
        },
        {
            type: "base",
            name: "CartoDB Dark",
            attribution: '&copy; <a target="_blank" href="http://cartodb.com/attributions">CartoDB</a> contributors',
            url: 'https://{s}.basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png'
        },
        {
            type: "base",
            name: "Mapbox",
            url: "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=" + MAPBOX_TOKEN,
            attribution: '&copy; <a target="_blank" href="https://www.mapbox.com/feedback/">Mapbox</a> contributors'
        },
        {
            type: "base",
            name: "Esri WorldImagery",
            url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png",
            attribution: '&copy; <a target="_blank" href="Esri &mdash">Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors'
        },
        {
            type: "base",
            name: "Google maps satellite",
            url: "https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}",
            attribution: '&copy; <a target="_blank" href="https://maps.google.com">Google maps</a> contributors'
        },
        {
            type: "personalized",
            name: "Cats",
            attribution: '&copy; <a target="_blank" href="https://babakfakhamzadeh.com/project/loremflickr-com/">loremflickr</a> contributors',
            url: { urlCats }
        },
        /*
        {
            //PROBA-V 10-daily Synthesis Top of Canopy (Atmospherically corrected) temporal resolution: 10-daily Resolution: 333M (pixel size)
            type: "wms",
            name: "COPERNICUS",
            attribution: '&copy; <a href="https://land.copernicus.eu/global/web-services" target="_blank">Copernicus</a>',
            url: 'https://viewer.globalland.vgt.vito.be/geoserver/ows?service=WMS&',
            layers: 'PV_MEP:PROBAV_S10_TOC_333M',
            format: 'image/png',
            transparent: true
        },
        */
        {
            type: "wms",
            name: "SEDE Catastro",
            attribution: '&copy; <a href="https://www.sedecatastro.gob.es/" target="_blank">Dirección General de Catastro</a>',
            url: 'https://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx',
            layers: 'CATASTRO',
            //url: 'https://ovc.catastro.meh.es/cartografia/INSPIRE/spadgcwms.aspx?',
            //layers: ['CP.CadastralParcel', 'CP.CadastralZoning', 'AD.Address', 'BU.Building', 'BU.BuildingPart'],
            //layers: ['CP.CadastralParcel', 'BU.Building'],
            format: 'image/png',
            transparent: true,
            maxZoom: 20
        },
        {
            type: "wms",
            name: "CARTOCIUDAD",
            attribution: '&copy; <a href="https://www.cartociudad.es/" target="_blank">Cartociudad</a>',
            url: 'https://www.cartociudad.es/wms-inspire/direcciones-ccpp?SERVICE=WMS&',
            layers: ['AD.Address', 'codigo-postal'],
            format: 'image/png',
            transparent: true,
            maxZoom: 20
        },
        /*
        {
            type: "wms",
            name: "IGN Peligrosidad",
            attribution: '&copy; <a href="https://www.ign.es/" target="_blank">IGN</a>',
            url: 'https://www.ign.es/wms-inspire/geofisica?SERVICE=WMS&',
            layers: ['NZ.HazardArea', 'Ultimos10dias'],
            format: 'image/png',
            transparent: true
        }
        */
    ]

    const [baseLayers, setBaseLayers] = useState([])
    const [wmsLayers, setWmsLayers] = useState([])
    const [geoJSONLayers, setGeoJSONLayers] = useState([])

    const [layersDownloaded, setLayersDownloaded] = useState(false)
    const [error, setError] = useState(false)
    
    const getLayers = async () => {
        await LayersService.getLayers({ pageNum: 1, search: '', limit: 100 }).then(
            response => {
                const layers = response.data.layers
                layers.map((layer) => {
                    if (layer.active) {
                        switch (layer.type) {
                            case 'BASE':
                                baseLayers.push({
                                    _id: layer._id,
                                    name: layer.name,
                                    url: layer.url,
                                    attribution: layer.attribution,
                                    minZoom: layer.minZoom,
                                    maxZoom: layer.maxZoom,
                                })
                                break
                            case 'WMS':
                                wmsLayers.push({
                                    _id: layer._id,
                                    name: layer.name,
                                    url: layer.url,
                                    wmsLayers: layer.wmsLayers,
                                    wmsFormat: layer.wmsFormat,
                                    transparent: layer.transparent,
                                    attribution: layer.attribution,
                                    minZoom: layer.minZoom,
                                    maxZoom: layer.maxZoom,
                                })
                                break
                            case 'GEOJSON':
                                geoJSONLayers.push({
                                    _id: layer._id,
                                    featureIdInProperty: layer.featureIdInProperty,
                                    featureNameInProperty: layer.featureNameInProperty,
                                    name: layer.name,
                                    minZoom: layer.minZoom,
                                    maxZoom: layer.maxZoom,
                                    attribution: layer.attribution || '&copy; <a target="_blank" href="https://civilio.com">Civilio</a> contributors',
                                    data: "",
                                    color: layer.featuresColor,
                                    fillColor: layer.featuresColor
                                })
                                break
                        }
                    }
                })
                setLayersDownloaded(true)
            },
            error => {
                setError(error)
            })
    }

    useEffect(() => {
        if (!map) return
        // Download layers information 1ST TIME LOADING
        if (!layersDownloaded) {
            getLayers()
        }
    }, [map])

    if (error) {
        return (
            <AlertError error={error} />
        )    
    }

    return (
        <LayersControl position="topright" collapsed={true}>
            {layersDownloaded &&
                <>
                    <DrawTools group={group} updateGroupData={updateGroupData} />

                    <LayersControl.BaseLayer
                        key={"lc-baselayer-OSM"}
                        checked={true}
                        name={openStreetMapLayer.name}
                    >
                        <TileLayer
                            attribution={openStreetMapLayer.attribution}
                            url={openStreetMapLayer.url}
                            maxZoom={!openStreetMapLayer.maxZoom ? 18 : openStreetMapLayer.maxZoom}
                            minZoom={!openStreetMapLayer.minZoom ? 1 : openStreetMapLayer.minZoom}
                        />
                    </LayersControl.BaseLayer>

                    {baseLayers.map(baseLayer => {
                        return (
                            <LayersControl.BaseLayer
                                key={"lc-baselayer-" + baseLayer._id}
                                checked={false}
                                name={baseLayer.name}
                            >
                                <TileLayer
                                    attribution={baseLayer.attribution}
                                    url={baseLayer.url}
                                    maxZoom={!baseLayer.maxZoom ? 18 : baseLayer.maxZoom}
                                    minZoom={!baseLayer.minZoom ? 1 : baseLayer.minZoom}
                                />
                            </LayersControl.BaseLayer>
                        )
                    })}

                    <LayersControl.BaseLayer
                        key={"lc-personalized-cats"}
                        checked={false}
                        name={catsLayer.name}
                    >
                        <TileLayer
                            attribution={catsLayer.attribution}
                            url={urlCats()}
                            maxZoom={!catsLayer.maxZoom ? 18 : catsLayer.maxZoom}
                            minZoom={!catsLayer.minZoom ? 1 : catsLayer.minZoom}
                            eventHandlers={{
                                tileloadstart: (tile) => {
                                    const random = Math.ceil(Math.random() * 40)
                                    tile.target._url = `https://loremflickr.com/250/250?lock=${random}`
                                }
                            }}
                        />
                    </LayersControl.BaseLayer>

                    {wmsLayers.map(wmsLayer => {
                        return (
                            <LayersControl.Overlay
                                key={"lc-overlay-" + wmsLayer._id}
                                checked={false}
                                name={wmsLayer.name}
                            >
                                <WMSTileLayer
                                    attribution={wmsLayer.attribution}
                                    url={wmsLayer.url}
                                    transparent={wmsLayer.transparent}
                                    format={wmsLayer.wmsFormat}
                                    layers={wmsLayer.wmsLayers}
                                    maxZoom={!wmsLayer.maxZoom ? 18 : wmsLayer.maxZoom}
                                    minZoom={!wmsLayer.minZoom ? 1 : wmsLayer.minZoom}
                                    eventHandlers={{
                                        add: (tile) => {
                                            //console.log("Added WMS")
                                        },
                                        remove: (tile) => {
                                            //console.log("Removed WMS")
                                        }
                                    }}
                                />
                            </LayersControl.Overlay>
                        )
                    })}

                    {geoJSONLayers.map(geoJSONLayer => {
                        return (
                            <LayersControl.Overlay
                                key={"lc-geojson-" + geoJSONLayer._id}
                                checked={false}
                                name={geoJSONLayer.name}
                            >
                                <GeoJSONLayer
                                    attribution={geoJSONLayer.attribution}
                                    key={"lc-geojson-layer-" + geoJSONLayer._id}
                                    id={geoJSONLayer._id}
                                    name={geoJSONLayer.name}
                                    minZoom={geoJSONLayer.minZoom}
                                    maxZoom={geoJSONLayer.maxZoom}
                                    featureIdInProperty={geoJSONLayer.featureIdInProperty}
                                    featureNameInProperty={geoJSONLayer.featureNameInProperty}
                                    weight={2}
                                    opacity={1}
                                    color={geoJSONLayer.color}
                                    //dashArray={3}
                                    fillOpacity={0.3}
                                    fillColor={geoJSONLayer.fillColor}
                                    group={group}
                                    updateGroupData={updateGroupData}
                                />
                            </LayersControl.Overlay>
                        )
                    })}
                </>
            }
        </LayersControl>
    )
}

export default Layers