module.exports = {
    ENTITY_SKILLS: [
        "ASSESSMENT",
        "COMMUNICATIONS",
        "COORDINATION",
        "ESSENTIAL_SERVICES",
        "EVACUATION",
        "FIREFIGHTING",
        "FIRST_AID",
        "HEALTH",
        "INTERVENTION",
        "LOGISTICS",
        "RESCUE",
        "SAFETY",
        "SECURITY",
        "SOCIAL_ACTION",
        "TECHNICAL_SUPPORT",
        "OTHER"
    ]
}