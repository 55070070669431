import axios from 'axios'
import authHeader from './AuthHeader'
import { API_URL } from '../config/api'

class ConfigService {

  // Get config params data
  getConfig = async () => {
    return await axios.get(API_URL + '/config', { headers: authHeader() })
  }

  // Update config params data
  updateConfig = async (configParams) => {
    return await axios.patch(API_URL + '/config', configParams, { headers: authHeader() })
  }

  getFrontConfig = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    return user.frontConfig
  }

}

export default new ConfigService()