import React, { useState, useEffect, useRef, useCallback } from "react"
import "leaflet/dist/leaflet.css"
import { useMap, LayerGroup, FeatureGroup, GeoJSON, Tooltip } from "react-leaflet"
import L from "leaflet"

import FeatureInfoModal from "components/modals/FeatureInfoModal"
import TaskActionModal from "components/modals/TaskActionModal"
import MissionActionModal from "components/modals/MissionActionModal"
import FeaturePopup from "../popup/FeaturePopup"
import { markerIcon } from "helpers/Markers"

import LayersService from 'services/LayersService'

const GeoJSONLayer = ({ id, name, attribution, group, updateGroupData, minZoom, maxZoom, color, fillColor, fillOpacity, featureIdInProperty, featureNameInProperty }) => {
    const map = useMap()
    const geoJsonRef = useRef()
    const layerGroupRef = useRef()

    const [show, setShow] = useState(false)
    const [data, setData] = useState(false)
    const [modalInfo, setModalInfo] = useState(false)
    const [modalTask, setModalTask] = useState(false)
    const [modalMission, setModalMission] = useState(false)
    const [selectedFeature, setSelectedFeature] = useState({})

    const toggleModalInfo = useCallback(() => setModalInfo(prev => !prev), [modalInfo])
    const toggleModalTask = useCallback(() => setModalTask(prev => !prev), [modalTask])
    const toggleModalMission = useCallback(() => setModalMission(prev => !prev), [modalMission])

    const handleFeatureClick = useCallback((geoJ) => {
        if (!geoJsonRef.current) return

        if (geoJ.layer.feature.geometry.type !== "Point") {
            geoJ.layer.setStyle({ color: "black", fillColor: "black", fillOpacity: 0.5 })
        } else {
            geoJ.layer.getElement().querySelector('path').style.fill = "black"
        }
    }, [])

    const handleFeatureMouseOver = useCallback((geoJ) => {
        if (!geoJsonRef.current) return

        if (geoJ.layer.feature.geometry.type !== "Point") {
            geoJ.layer.setStyle({ color: "black", fillColor: "black", fillOpacity: 0.5 })
        } else {
            geoJ.layer.getElement().querySelector('path').style.fill = "black"
        }
    }, [])

    const handleFeatureMouseOut = useCallback((geoJ) => {
        if (!geoJsonRef.current) return

        if (geoJ.layer.feature.geometry.type !== "Point") {
            geoJ.layer.setStyle({ color: color, fillColor: fillColor, fillOpacity: fillOpacity })
        } else {
            geoJ.layer.getElement().querySelector('path').style.fill = fillColor
        }
    }, [])

    const getData = useCallback(() => {

        const mapBounds = map.getBounds()
        const mapZoom = map.getZoom()
        if (mapZoom >= minZoom && mapZoom <= maxZoom) {
            LayersService.bbox(id, mapBounds._southWest.lng, mapBounds._southWest.lat, mapBounds._northEast.lng, mapBounds._northEast.lat).then(
                //LayersService.geojson(id, mapBounds._southWest.lng, mapBounds._southWest.lat, mapBounds._northEast.lng, mapBounds._northEast.lat).then(
                response => {
                    setData(response.data)
                },
                error => {
                    setData(false)
                }
            )
        } else {
            setData(false)
        }
    }, [])

    useEffect(() => {
        if (!map) return
        if (!show) return

        getData()
        const onMoveEnd = () => {
            getData()
        }

        map.on('moveend', onMoveEnd)

        return () => {
            map.off('moveend', onMoveEnd)
        }
    }, [map, show, getData])

    const pointToLayer = useCallback((geoJsonPoint, latlng) => {
        return L.marker(latlng, { icon: markerIcon(fillColor) })
    }, [fillColor])

    return (
        <>
            <LayerGroup
                ref={layerGroupRef}
                attribution={attribution}
                color="red"
                key={"lg-geojson-" + id}
                eventHandlers={{
                    add: (layerG) => {
                        setShow(true)
                    },
                    remove: (layerG) => {
                        setShow(false)
                    }
                }}
            >
                {show && data && data.features.map((feature, index) => {
                    feature.properties._featureIdInProperty = featureIdInProperty
                    feature.properties._featureNameInProperty = featureNameInProperty
                    feature.properties._searchLayerName = name
                    feature.properties._searchLayerIcon = '<i class="bi bi-layers-half"></i>'
                    feature.properties._searchByName = feature.properties[featureNameInProperty]
                    return (
                        <GeoJSON
                            ref={geoJsonRef}
                            key={"lg-geojson-" + id + "-fg-gjfeature-" + feature.id}
                            data={feature}
                            weight={2}
                            opacity={1}
                            color={color}
                            //dashArray="3"
                            fillOpacity={0.3}
                            fillColor={fillColor}
                            eventHandlers={{
                                click: (geoJ) => {
                                    handleFeatureClick(geoJ)
                                },
                                mouseover: (geoJ) => {
                                    handleFeatureMouseOver(geoJ)
                                },
                                mouseout: (geoJ) => {
                                    handleFeatureMouseOut(geoJ)
                                },
                                add: (geoJ) => {
                                },
                                remove: (geoJ) => {
                                }
                            }}
                            pointToLayer={pointToLayer}
                        >
                            <Tooltip direction="top" offset={[0, 0]} opacity={1} sticky={true} permanent={false}>
                                {feature.properties[featureNameInProperty]}
                            </Tooltip>
                            <FeaturePopup
                                key={"lg-geojson-" + id + "-fg-popup-" + feature.id}
                                index={feature._id}
                                title={name}
                                name={feature.properties[featureNameInProperty]}
                                layerId={id}
                                feature={feature}
                                toggleModalInfo={toggleModalInfo}
                                toggleModalTask={toggleModalTask}
                                toggleModalMission={toggleModalMission}
                                setSelectedFeature={setSelectedFeature}
                                featureIdInProperty={featureIdInProperty}
                                featureNameInProperty={featureNameInProperty}
                            />

                        </GeoJSON>
                    )
                })}
            </LayerGroup>
            <FeatureInfoModal
                key={'lg-geojson-modal-info-' + id}
                modal={modalInfo}
                setModal={setModalInfo}
                toggle={toggleModalInfo}
                selectedFeature={selectedFeature}
            />
            <TaskActionModal
                key={'lg-geojson-modal-task-' + id}
                modal={modalTask}
                setModal={setModalTask}
                toggle={toggleModalTask}
                selectedFeature={selectedFeature}
                group={group}
                updateGroupData={updateGroupData}
            />
            <MissionActionModal
                key={'lg-geojson-modal-mission-' + id}
                modal={modalMission}
                setModal={setModalMission}
                toggle={toggleModalMission}
                selectedFeature={selectedFeature}
                group={group}
                updateGroupData={updateGroupData}
            />
        </>
    )
}

export default GeoJSONLayer