import React, { useState, useEffect } from "react"
import { useIntl, FormattedMessage } from 'react-intl'
import Modal from 'react-bootstrap/Modal'
import { PDFViewer } from '@react-pdf/renderer'

import GroupsService from 'services/GroupsService'
import PicturesService from 'services/PicturesService'

import { AlertError, AlertLoading } from 'helpers/AlertHelper'

import TaskReportPDF from "components/pdf/TaskReport.pdf"

const ReportModal = ({ show, task, callBack }) => {
    const intl = useIntl()

    const [confirmModalShow, setConfirmModalShow] = useState(false)
    const handleConfirmModalClose = (update) => {
        // update true for callback
        setConfirmModalShow(false)
        callBack(update)
    }
    const handleConfirmModalShow = () => setConfirmModalShow(true)

    const [groupLoaded, setGroupLoaded] = useState(false)
    const [group, setGroup] = useState([])
    const [imagesLoaded, setImagesLoaded] = useState(false)
    const [images, setImages] = useState([])

    const [error, setError] = useState(false)

    const getGroup = async (groupId) => {
        await GroupsService.getGroup(groupId, true).then(
            response => {
                setGroup(response.data)
                setGroupLoaded(true)
            },
            error => {
                setError(error)
            }
        )
    }

    const getPictures = async () => {
        await PicturesService.getPictures(task._id).then(
            response => {
                const loadedImages = response.data
                setImages(loadedImages.map(obj => ({ ...obj, saved: true })))
                setImagesLoaded(true)
            },
            error => {
                setError(error)
            }
        )
    }

    useEffect(() => {
        if (show) {
            handleConfirmModalShow()
            getGroup(task.groupId)
            getPictures()
        } else {
            handleConfirmModalClose(false)
        }
    }, [show])

    return (
        <Modal
            show={confirmModalShow}
            onHide={() => handleConfirmModalClose(false)}
            animation={false}
            backdrop={"static"}
            keyboard={false}
            size={'lg'}
        >
            <Modal.Header closeButton>
                <Modal.Title><FormattedMessage id="reportTaskReport" defaultMessage="Task report" />: {task.feature.name} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {imagesLoaded && groupLoaded ? (
                    <PDFViewer width={'100%'} height={600} showToolbar={true}>
                        <TaskReportPDF task={task} images={images} group={group} user={task.user} intl={intl} />
                    </PDFViewer>
                ) : (
                    <AlertLoading isLoaded={(imagesLoaded && groupLoaded)} />
                )}
            </Modal.Body>
            <Modal.Footer>
                {error &&
                    <AlertError error={error} />
                }
            </Modal.Footer>
        </Modal>
    )
}

export default ReportModal