import React, { useState } from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

const TimestampSlider = ({ onRangeChange, value, defaultValues = 0, disabled = true }) => {
  const [sliderValue, setSliderValue] = useState(value)
  const handleSliderOnChangeComplete = (value) => {
    setSliderValue(value)
    onRangeChange(value)
  }

  const handleSliderOnChange = (value) => {
    setSliderValue(value)
  }

  return (
    <>
      <div style={{ marginLeft:5, marginRight: 15 }}>{sliderValue === 0 ? 'Now' : sliderValue + 'h'}</div>
      <Slider
        min={-100}
        max={0}
        defaultValue={defaultValues}
        onChangeComplete={handleSliderOnChangeComplete}
        onChange={handleSliderOnChange}
        disabled={disabled ? true : false}
        style={{marginRight: 10}}
      />
    </>
  )
}

export default TimestampSlider