import React from 'react'
import Form from '@rjsf/core'
import validator from '@rjsf/validator-ajv8'

import RJSFormText from 'components/rjsf/RJSFormText'
import RJSFormBoolean from 'components/rjsf/RJSFormBoolean'
import RJSFormSelect from 'components/rjsf/RJSFormSelect'
import RJSFormNumber from 'components/rjsf/RJSFormNumber'
import RJSFormPosition from 'components/rjsf/RJSFormPosition'
import RJSFormDateTime from 'components/rjsf/RJSFormDateTime'

const RJSForm = ({ name, schema, formData, onChange, helpInfo }) => {

    let templates = {
        CustomFieldTemplate: (props) => {
            switch (schema.type) {
                case 'string':
                    return schema.enum ? (
                        <RJSFormSelect  {...props} />
                    ) : (
                        <RJSFormText {...props} />
                    )
                case 'array':
                    return name.includes('coordinates') ? (
                        <RJSFormPosition {...props} />
                    ) : null; // Puedes manejar otros casos de arrays aquí
                case 'integer':
                    return <RJSFormNumber {...props} />
                case 'boolean':
                    return <RJSFormBoolean  {...props} />
                default:
                    return <RJSFormText  {...props} />
            }
        }
    }

    let uiSchema = {
        "ui:options": {
            "label": false,
            "submitButtonOptions": {
                "norender": true,
            }
        },
        "ui:FieldTemplate": (props) => {
            switch (schema.type) {
                case 'string':
                    return schema.enum ? (
                        <RJSFormSelect {...props} helpInfo={helpInfo} />
                    ) : schema.format === 'date-time' ? (
                        <RJSFormDateTime {...props} helpInfo={helpInfo} />
                    ) : (
                        <RJSFormText {...props} helpInfo={helpInfo} />
                    )
                case 'array':
                    return name.includes('coordinates') ? (
                        <RJSFormPosition {...props} helpInfo={helpInfo} />
                    ) : null; // Puedes manejar otros casos de arrays aquí
                case 'integer':
                    return <RJSFormNumber {...props} helpInfo={helpInfo} />
                case 'boolean':
                    return <RJSFormBoolean  {...props} helpInfo={helpInfo} />
                default:
                    return <RJSFormText  {...props} helpInfo={helpInfo} />
            }
        }
    }

    return (
        <Form
            idPrefix={name}
            schema={schema}
            formData={formData}
            uiSchema={uiSchema}
            onChange={onChange}
            validator={validator}
            liveOmit={true}
            omitExtraData={true}
        //templates={templates}
        />
    )
}

export default RJSForm
