import React, { useState, useEffect, useRef } from "react"
import Button from 'react-bootstrap/Button'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import TabContent from 'react-bootstrap/TabContent'
import Badge from 'react-bootstrap/Badge'
import Container from 'react-bootstrap/Container'

import SocketService from "socket/SocketService"
import ChatRoomEvents from "socket/ChatRoomEvents.socket"
import LocationEvents from "socket/LocationEvents.socket"

import MessagesToast from "components/toast/MessagesToast"

import TasksService from 'services/TasksService'

import TasksList from "./TasksList"
import GroupsList from "./GroupsList"
import ChatRoomsList from "./ChatRoomsList"

import { AlertError, AlertLoading } from 'helpers/AlertHelper'

import notificationSound from 'components/audio/notification.mp3'

const InspectorTasks = ({ mapCenter, mapZoom }) => {
    const authUser = JSON.parse(localStorage.getItem("user"))

    const [viewSelected, setViewSelected] = useState('viewTasks')  // viewTasks - viewGroups - viewChatrooms
    const handleViewSelectedChange = (val) => {
        setViewSelected(val)
    }

    const audioRef = useRef(null)

    const playNotificationSound = () => {
        if (audioRef.current) {
            audioRef.current.play()
        }
    }

    const [selectedGroupId, setSelectedGroupId] = useState(null)
    const [groups, setGroups] = useState([])
    const [groupsList, setGroupsList] = useState([])
    const [tasks, setTasks] = useState([])
    const [tasksList, setTasksList] = useState([])
    const [searchValue, setSearchValue] = useState('')

    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)

    const [chatNewMessagesNum, setNewChatMessagesNum] = useState(0)
    const handleChatMessagesNum = (num) => {
        setNewChatMessagesNum(num)
    }

    const [toasts, setToasts] = useState([])
    //const toastsRef = useRef(toasts)
    const [sendButtonDisabled, setSendButtonDisabled] = useState(false)

    const handleToasts = ({ id, group, chatRoom, sender, createdAt, moment, content }) => {
        setToasts([
            ...toasts,
            { id, group, chatRoom, sender, createdAt, moment, content, show: true }
        ])
        playNotificationSound()
    }

    const toggleToastShow = (id) => {
        const updatedToasts = toasts.filter((toast) => toast.id !== id)
        setToasts(updatedToasts)
    }

    const filterTaskList = (tasksArray) => {
        setTasksList(tasksArray.filter(task => {
            if (searchValue && searchValue !== "false" &&
                !task.feature.name
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) &&
                !task.group.name
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
            ) {
                return false
            }
            return true
        }))
    }

    const filterGroups = (groups) => {
        setGroupsList(groups.filter(group => {
            if (searchValue && searchValue !== "false" && !group.name
                .toLowerCase()
                .includes(searchValue.toLowerCase())) {
                return false
            }
            return true
        }))
    }

    const onClickClearBtn = (e) => {
        setSearchValue('')
    }

    const onChangeSearch = (e) => {
        setSearchValue(e.target.value)
    }

    const updateTasksList = (taskId, taskUpdate) => {
        const updatedTasks = tasks.map((task) => {
            if (task._id === taskId) {
                task = Object.assign(task, taskUpdate, {
                    resultChangedAt: Date.now(),
                })
            }
            return task
        })
        setTasks(updatedTasks)
    }

    const getTasks = async () => {
        await TasksService.getUserTasks().then(
            response => {
                setTasks(response.data)
                getGroups(response.data)
                setIsLoaded(true)
                setError(false)
            },
            error => {
                setError(error)
            }
        )
    }

    const getGroups = (tasks) => {
        const groupsArray = []

        tasks.forEach(item => {
            const groupId = item.group?._id

            const index = groupsArray.findIndex(grupo => grupo._id === groupId)

            if (index !== -1) {
                groupsArray[index] = {
                    ...groupsArray[index],
                    numTasks: groupsArray[index].numTasks + 1
                }
            } else {
                groupsArray.push({
                    ...item.group,
                    numTasks: 1
                })
            }
        })

        setGroups(groupsArray)
    }

    useEffect(() => {
        filterGroups(groups)
        filterTaskList(tasks.sort((a, b) => {
            const nameA = a.feature.name.toLowerCase()
            const nameB = b.feature.name.toLowerCase()
            return nameA === nameB ? 0 : nameA.localeCompare(nameB, undefined, { numeric: true })
        }))
    }, [tasks, searchValue])

    // Websocket chat connection
    const [socket, setSocket] = useState(null)
    const [socketData, setSocketData] = useState(null)
    const handleSocketData = (data) => {
        setSocketData(data)
    }
    const handleLocationSocketData = (data) => {
        // Nothing to do!!!!
    }
    useEffect(() => {
        //window.addEventListener("beforeunload", handleBeforeUnload)
        getTasks()

        const newSocket = SocketService(authUser.accessToken).connect(setSendButtonDisabled)
        setSocket(newSocket)

        // Clean socket to unmount the chat component
        return () => {
            // Disconnect from the socket server
            setSendButtonDisabled(true)
            newSocket.disconnect()
        }
    }, [])

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault()
            // Included for legacy support, e.g. Chrome/Edge < 119
            event.returnValue = true
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    useEffect(() => {
        if (!socket) return
        LocationEvents(socket).setEvents(handleLocationSocketData)
        ChatRoomEvents(socket).setEvents(handleSocketData)
    }, [socket])

    if (error) {
        return (
            <AlertError error={error} />
        )
    } else if (!isLoaded) {
        return (
            <AlertLoading isLoaded={isLoaded} />
        )
    }

    return (
        <Container className="p-0">

            {toasts && toasts.length > 0 &&
                <MessagesToast
                    toasts={toasts}
                    setToasts={setToasts}
                    toggle={toggleToastShow}
                    position={'top-center'}
                    className="position-fixed p-2" />
            }
            
            <audio ref={audioRef}>
                <source src={notificationSound} type="audio/mp3" />
                Tu navegador no soporta la etiqueta de audio.
            </audio>

            <Tabs
                id="missions-tab-panel"
                activeKey={viewSelected}
                onSelect={(k) => handleViewSelectedChange(k)}
                className="fixed-bottom bg-light p-0"
                transition={false}
                justify
                variant="underline"
            >
                <Tab
                    eventKey="viewGroups"
                    title={<h6><i className="bi bi-shield-fill"></i> {`${groupsList.length}`}</h6>}
                >
                    <TabContent>
                        <GroupsList groupsList={groupsList} selectedGroupId={selectedGroupId} setSelectedGroupId={setSelectedGroupId} />
                    </TabContent>
                </Tab>
                <Tab
                    eventKey="viewTasks"
                    title={<h6><i className="bi bi-clipboard2-fill"></i> {`${tasksList.length}`}</h6>}
                >
                    <TabContent>
                        <h5 className="bg-light p-1"><i className="bi bi-clipboard2-fill"></i> Tasks</h5>
                        <div className="input-group input-group-sm mb-0 p-1">
                            <span className="input-group-text"><i className="bi bi-search"></i></span>
                            <input className="form-control form-control-sm" name="search" type="text" placeholder="Search ..." value={searchValue} onChange={onChangeSearch} />
                            <Button variant="outline-secondary" className="btn btn-outline-secondary" type="button" onClick={onClickClearBtn}><i className="bi bi-x-square-fill"></i> Clear</Button>
                        </div>
                        <TasksList tasksList={tasksList} mapCenter={mapCenter} mapZoom={mapZoom} updateTasksList={updateTasksList} />
                    </TabContent>
                </Tab>
                <Tab
                    eventKey="viewChatrooms"
                    title={<h6><i className="bi bi-chat-square-fill"></i> {chatNewMessagesNum ? <Badge bg='warning'><i className="bi bi-envelope"></i> {`${chatNewMessagesNum}`}</Badge> : ''}</h6>}
                >
                    <TabContent>
                        <ChatRoomsList
                            groupsList={groupsList}
                            selectedGroupId={selectedGroupId}
                            setSelectedGroupId={setSelectedGroupId}
                            authUserId={authUser._id}
                            socket={socket}
                            socketData={socketData}
                            handleToasts={handleToasts}
                            handleChatMessagesNum={handleChatMessagesNum}
                            sendButtonDisabled={sendButtonDisabled} />
                    </TabContent>
                </Tab>
            </Tabs>
        </Container>
    )
}

export default InspectorTasks
