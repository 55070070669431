class ChatRoomService {
    /**
     * Creates an instance of ChatRoomSocketServices.
     * @param {Object} options - Options for the service.
     * @param {Object} options.socketService - The socket service.
     * @param {string} options.authUserId - The authenticated user ID.
     * @param {string} options.groupId - The group ID.
     */

    constructor({ socketService, authUserId, groupId }) {
        this.authUserId = authUserId
        this.groupId = groupId
        this.socketService = socketService
    }

    // Get the socket
    getSocket = () => {
        return this.socketService
    }

    // Get the authUserId
    getAuthUserId = () => {
        return this.authUserId
    }

    // Get the groupId
    getGroupId = () => {
        return this.groupId
    }

    // User position
    userPosition = (position) => {
        if (this.socketService) {
            this.socketService.emit('userPosition', {
                ...position,
                socketId: this.socketService.id,
                event: 'userPosition'
            })
        } else {
            console.error('Socket service not available')
        }
    }

    // Join to chatRoom._id
    joinChatRoom = (chatRoom) => {
        if (this.socketService) {
            this.socketService.emit('joinChatRoom', {
                ...chatRoom,
                socketId: this.socketService.id,
                event: 'joinChatRoom'
            })
        } else {
            console.error('Socket service not available')
        }
    }

    /**
    * Add a new chat room to groupId.
    */
    addChatRoom = (chatRoom) => {
        if (this.socketService) {
            this.socketService.emit('addChatRoom', {
                ...chatRoom,
                socketId: this.socketService.id,
                event: 'addChatRoom'
            })
        } else {
            console.error('Socket service not available')
        }
    }

    /**
    * Update chat room 
    */
    updateChatRoom = (chatRoom) => {
        if (this.socketService) {
            this.socketService.emit('updateChatRoom', {
                ...chatRoom,
                socketId: this.socketService.id,
                event: 'updateChatRoom'
            })
        } else {
            console.error('Socket service not available')
        }
    }

    /**
    * Delete chat room all all messages
    */
    deleteChatRoom = (chatRoom) => {
        if (this.socketService) {
            this.socketService.emit('deleteChatRoom', {
                ...chatRoom,
                userId: this.authUserId,
                socketId: this.socketService.id,
                event: 'deleteChatRoom'
            })
        } else {
            console.error('Socket service not available')
        }
    }

    /**
     * Get all group chat rooms.
     */
    getChatRooms = () => {
        if (this.socketService) {
            this.socketService.emit('getChatRooms', {
                groupId: this.groupId,
                userId: this.authUserId,
                socketId: this.socketService.id,
                event: 'getChatRooms'
            })
        } else {
            console.error('Socket service not available')
        }
    }

    /**
     * Get chat room messages.
     * @param {string} chatRoomId - The ID of the chat room.
     */
    getChatRoomMessages = (chatRoomId) => {
        if (this.socketService) {
            this.socketService.emit('getChatRoomMessages', {
                groupId: this.groupId,
                userId: this.authUserId,
                chatRoomId: chatRoomId,
                socketId: this.socketService.id,
                event: 'getChatRoomMessages'
            })
        } else {
            console.error('Socket service not available')
        }
    }

    /**
     * Add chat room message.
     * @param {string} chatRoomId - The ID of the chat room.
     * @param {Array} recipients - The recipients of the message.
     * @param {string} content - The content of the message.
     */
    addChatRoomMessage = (chatRoomId, recipients, content) => {
        if (this.socketService) {
            this.socketService.emit('addChatRoomMessage', {
                groupId: this.groupId,
                userId: this.authUserId,
                chatRoomId: chatRoomId,
                message: { content: content, recipients: recipients },
                socketId: this.socketService.id,
                event: 'addChatRoomMessage'
            })
        } else {
            console.error('Socket service not available')
        }
    }
}

export default ChatRoomService