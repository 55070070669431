import React from 'react'
import Toast from 'react-bootstrap/Toast'

const MessageToast = ({ toast, toggle }) => {
    return (
        <Toast bg="light" show={toast.show} onClose={() => toggle(toast.id)} key={"toast-" + toast.id}>
            <Toast.Header>
                <i className="bi bi-chat-square-fill"></i>&nbsp;<strong className="me-auto">{toast.chatRoom.name}</strong>
                <small>{toast.moment}</small>
            </Toast.Header>
            <Toast.Body style={{ maxHeight: '200px', overflowY: 'auto' }}>
                <p><i className="bi bi-shield"></i> {toast.group.name}</p>
                <p><strong>{toast.sender.name}:</strong> {toast.content}</p>
            </Toast.Body>
        </Toast>
    )
}

export default MessageToast