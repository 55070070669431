import moment from "moment"
import { FormattedMessage } from 'react-intl'

const formatPercent = (num, total) => {
    if (typeof num !== 'number' || typeof total !== 'number' || total === 0) {
        return <>0 <small>(0%)</small></>
    }

    const percent = ((num / total) * 100).toFixed(2)

    return <>{num} <small>({percent}%)</small></>
}


const formatTime = (timeInMillis) => {
    const duration = moment.duration(timeInMillis)

    const days = duration.days()
    const hours = duration.hours()
    const minutes = duration.minutes()
    const seconds = duration.seconds()

    let formattedTime = ''

    if (days <= 0) {
        if (hours > 0) {
            formattedTime += `${hours}h `
        }

        if (minutes > 0 || formattedTime !== '') {
            formattedTime += `${minutes}' `
        }

        if (hours === 0) {
            formattedTime += `${seconds}''`
        }
    } else {
        formattedTime += `${days}d `

        if (hours > 0) {
            formattedTime += `${hours}h `
        }
    }

    return formattedTime
}

const averageTime = (time, num = false) => {
    if (time === Infinity) return formatTime(0)
    if (num) return formatTime((time / num))  // Return average time in minutues
    return formatTime(time)
}

export const averageTimeContent = (stats) => {
    return ([
        [{
            icon: <i className="bi bi-stopwatch"></i>,
            title: <FormattedMessage id="statsMinTime" defaultMessage="Minimun" />,
            text: averageTime(stats.resultMinTime)
        }, {
            icon: <>/<i className="bi bi-stopwatch"></i>/</>,
            title: <FormattedMessage id="statsAveTime" defaultMessage="Average" />,
            text: averageTime(stats.totalTime, stats.taskCount)
        },
        {
            icon: <i className="bi bi-stopwatch-fill"></i>,
            title: <FormattedMessage id="statsMaxTime" defaultMessage="Maximum" />,
            text: averageTime(stats.resultMaxTime)
        }]
    ])
}

export const taskStatusContent = (stats) => {
    return (
        [
            [{
                icon: <i className="bi bi-clipboard2-check-fill"></i>,
                title: <FormattedMessage id="statsStatusFinished" defaultMessage="Finished" />,
                text: stats['FINISHED'] ? formatPercent(stats['FINISHED'], stats.total) : '0 (0%)',
                color: 'blue',
                numberAlign: 'center'
            },
            {
                icon: <i className="bi bi-clipboard2-pulse-fill"></i>,
                title: <FormattedMessage id="statsStatusInProgress" defaultMessage="In progress" />,
                text: stats['IN PROGRESS'] ? formatPercent(stats['IN PROGRESS'], stats.total) : '0 (0%)',
                color: 'aqua',
                numberAlign: 'center'
            }],
            [{
                icon: <i className="bi bi-clipboard2-heart-fill"></i>,
                title: <FormattedMessage id="statsStatusPending" defaultMessage="Pending" />,
                text: stats['PENDING'] ? formatPercent(stats['PENDING'], stats.total) : '0 (0%)',
                color: 'orange',
                numberAlign: 'center'
            },
            {
                icon: <i className="bi bi-clipboard2-x-fill"></i>,
                title: <FormattedMessage id="statsStatusCancelled" defaultMessage="Cancelled" />,
                text: stats['CANCELLED'] ? formatPercent(stats['CANCELLED'], stats.total) : '0 (0%)',
                color: 'lightslategray',
                numberAlign: 'center'
            }]
        ]
    )
}

export const taskStatusTimesContent = (stats) => {
    return (
        [
            [{
                icon: <i className="bi bi-hourglass-top"></i>,
                title: <FormattedMessage id="statsFirstCreated" defaultMessage="First created" />,
                text: <small>{moment(stats.firstCreated).fromNow(true)}</small>,
                numberAlign: 'center'
            },
            {
                icon: <i className="bi bi-hourglass-bottom"></i>,
                title: <FormattedMessage id="statsLastCreated" defaultMessage="Last created" />,
                text: <small>{moment(stats.lastCreated).fromNow(true)}</small>,
                numberAlign: 'center'
            },
            {
                icon: <i className="bi bi-hourglass-split"></i>,
                title: <FormattedMessage id="statsLastUpdated" defaultMessage="Last updated" />,
                text: <small>{moment(stats.lastUpdated).fromNow(true)}</small>,
                numberAlign: 'center'
            }]
        ]
    )
}

export const taskResultContent = (stats) => {
    return (
        [
            [{
                icon: <i className="bi bi-house-check-fill"></i>,
                title: <FormattedMessage id="tasksResultGreen" defaultMessage="Green" />,
                text: stats['GREEN'] ? formatPercent(stats['GREEN'], stats.inspections) : '0 (0%)',
                color: 'green',
                numberAlign: 'center'
            },
            {
                icon: <i className="bi bi-house-exclamation-fill"></i>,
                title: <FormattedMessage id="tasksResultYellow" defaultMessage="Yellow" />,
                text: stats['YELLOW'] ? formatPercent(stats['YELLOW'], stats.inspections) : '0 (0%)',
                color: 'yellow',
                numberAlign: 'center'
            }],
            [{
                icon: <i className="bi bi-house-dash-fill"></i>,
                title: <FormattedMessage id="tasksResultRed" defaultMessage="Red" />,
                text: stats['RED'] ? formatPercent(stats['RED'], stats.inspections) : '0 (0%)',
                color: 'red',
                numberAlign: 'center'
            },
            {
                icon: <i className="bi bi-house-x-fill"></i>,
                title: <FormattedMessage id="tasksResultBlack" defaultMessage="Black" />,
                text: stats['BLACK'] ? formatPercent(stats['BLACK'], stats.inspections) : '0 (0%)',
                color: 'black',
                numberAlign: 'center'
            }]
        ]
    )
}

export const missionContent = (stats) => {
    return (
        [
            [{
                icon: <i className="bi bi-people-fill"></i>,
                title: <FormattedMessage id="users" defaultMessage="Users" />,
                text: stats.mission.users.length,
                numberAlign: 'center'
            },
            {
                icon: <i className="bi bi-clipboard2-fill"></i>,
                title: <FormattedMessage id="statsTotalTasks" defaultMessage="Total tasks" />,
                text: stats.total,
                numberAlign: 'center'
            }]
        ]
    )
}

export const missionTimeContent = (stats) => {
    return (
        [
            [{
                icon: <i className="bi bi-hourglass-top"></i>,
                title: <FormattedMessage id="statsCreated" defaultMessage="Created" />,
                text: <small>{moment(stats.firstCreated).fromNow(true)}</small>,
                numberAlign: 'center'
            },
            {
                icon: <i className="bi bi-hourglass-split"></i>,
                title: <FormattedMessage id="statsLastUpdated" defaultMessage="Last updated" />,
                text: <small>{moment(stats.lastUpdated).fromNow(true)}</small>,
                numberAlign: 'center'
            }]
        ]
    )
}

export const userContent = (stats) => {
    return (
        [
            [{
                icon: <i className="bi bi-clipboard2-fill"></i>,
                title: <FormattedMessage id="statsTotalTasks" defaultMessage="Total tasks" />,
                text: stats.total,
                numberAlign: 'center'
            },
            {
                icon: <i className="bi bi-house-fill"></i>,
                title: <FormattedMessage id="statsInspections" defaultMessage="Inspections" />,
                text: stats.inspections,
                numberAlign: 'center'
            }]
        ]
    )
}

export const userTimeContent = (stats) => {
    return (
        [
            [{
                icon: <i className="bi bi-hourglass-top"></i>,
                title: <FormattedMessage id="statsFirstCreated" defaultMessage="First created" />,
                text: <small>{moment(stats.firstCreated).fromNow(true)}</small>,
                numberAlign: 'center'
            },
            {
                icon: <i className="bi bi-hourglass-bottom"></i>,
                title: <FormattedMessage id="statsLastCreated" defaultMessage="Last created" />,
                text: <small>{moment(stats.lastCreated).fromNow(true)}</small>,
                numberAlign: 'center'
            },
            {
                icon: <i className="bi bi-hourglass-split"></i>,
                title: <FormattedMessage id="statsLastUpdated" defaultMessage="Last updated" />,
                text: <small>{moment(stats.lastUpdated).fromNow(true)}</small>,
                numberAlign: 'center'
            }]
        ]
    )
}
