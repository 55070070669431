import React, { useEffect, useRef, useCallback } from "react"
import ToastContainer from 'react-bootstrap/ToastContainer'

import moment from 'moment'

import MessageToast from './MessageToast'

const MessagesToast = ({ toasts, setToasts, toggle, position, className }) => {

    const memorizedToggle = useCallback(toggle, [toggle]);

    const toastsRef = useRef([])

    useEffect(() => {
        toastsRef.current = toasts
    }, [toasts])

    const updateToatsMoment = (toastsCurrent) => {
        if (!toastsCurrent) return
        const updatedData = toastsCurrent.map(toast => ({
            ...toast,
            moment: moment(toast.createdAt).fromNow()
        }))
        setToasts(updatedData)
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateToatsMoment(toastsRef.current)
        }, 60000) // every 60 seconds

        return () => clearInterval(intervalId)
    }, [])

    return (
        <ToastContainer
            className={className}
            position={position}
        >
            {toasts.map((toast) => {
                return (
                    <MessageToast key={toast.id} toast={toast} toggle={memorizedToggle} />
                )
            })}
        </ToastContainer>
    )
}

export default MessagesToast