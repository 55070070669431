import { useEffect, useState } from "react"
import { useIntl, FormattedMessage } from 'react-intl'
import { useMap, LayerGroup, GeoJSON, Popup, Tooltip } from "react-leaflet"
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'

import styles from "./missionsButton.module.css"
import L from "leaflet"

import _ from 'lodash'

import moment from 'moment'

import MissionsService from 'services/MissionsService'

import MissionModal from "components/modals/MissionModal"
import { markerIcon } from "helpers/Markers"

const MissionsButton = ({ groupId, missionId, userId, searchValue, callBack, mapSliderValue, refresh, onError }) => {
    const intl = useIntl()

    const map = useMap()

    const [missions, setMissions] = useState(false)

    const [modalTrigger, setModalTrigger] = useState(false)
    const [modalType, setModalType] = useState(false)
    const [missionSelected, setMissionSelected] = useState(false)

    const [showMissions, setShowMissions] = useState(false)

    const getMissions = async () => {
        const mapBounds = map.getBounds()
        const options = {
            groupId: groupId,
            missionId: missionId,
            userId: userId,
            search: searchValue,
            lonbl: mapBounds._southWest.lng,
            latbl: mapBounds._southWest.lat,
            lonur: mapBounds._northEast.lng,
            latur: mapBounds._northEast.lat,
            timestamp: mapSliderValue
        }

        await MissionsService.getGroupMissions(options).then(
            response => {
                setMissions(response.data.missions)
                onError(false)
            },
            error => {
                onError(error)
            })

    }

    const handleFeatureClick = (geoJ) => {
        if (geoJ.layer.feature.geometry.type !== 'Point') {
            geoJ.layer.bringToBack()
        }
    }

    const handleFeatureMouseOver = (geoJ) => {
        if (geoJ.layer.feature.geometry.type !== "Point") {
            geoJ.layer.setStyle({ color: "black", fillColor: "black", fillOpacity: 0.5 })
        } else {
            if (geoJ.layer.getElement() !== null) {
                geoJ.layer.getElement().querySelector('path').style.fill = 'black'
            }
        }
    }

    const handleFeatureMouseOut = (geoJ) => {
        if (geoJ.layer.feature.geometry.type !== "Point") {
            geoJ.layer.setStyle({ color: 'rgb(0, 123, 180)', fillColor: 'rgb(0, 123, 180)', fillOpacity: 0.3 })
        } else {
            if (geoJ.layer.getElement() !== null) {
                geoJ.layer.getElement().querySelector('path').style.fill = 'rgb(0, 123, 180)'
            }
        }
    }

    const handleEditBtn = (mission) => {
        setModalType('edit')
        setMissionSelected(mission)
        setModalTrigger(true)
    }

    const handleDeleteBtn = (mission) => {
        setModalType('delete')
        setMissionSelected(mission)
        setModalTrigger(true)
    }

    const handleModalCallBack = (update) => {
        setModalTrigger(false)
        if (update) {
            getMissions()
            callBack(true)
        }
    }

    useEffect(() => {
        if (!map) return

        // create custom button
        const customControl = L.Control.extend({
            // button position
            options: {
                position: "topright",
                className: `${styles.missionsButton} leaflet-bar`,
                html: '<i class="bi bi-lightning-fill"></i>',
                style: "width: 34px; height: 34px; left: 0; margin-top: 0; display: flex; cursor: pointer; justify-content: center; font-size: 1rem;",
            },

            // method
            onAdd: function (map) {
                this._map = map
                const button = L.DomUtil.create("div")
                L.DomEvent.disableClickPropagation(button)

                button.title = intl.formatMessage({ id: 'mapMissions', defaultMessage: 'Display missions' })
                button.innerHTML = this.options.html
                button.className = this.options.className
                button.setAttribute("style", this.options.style)

                L.DomEvent.on(button, "click", this._clicked, this)

                /*
                button.onmouseover = function () {
                    this.style.transform = "scale(1.3)"
                }

                button.onmouseout = function () {
                    this.style.transform = "scale(1)"
                }
                */

                return button
            },
            _clicked: function (e) {
                L.DomEvent.stopPropagation(e)

                const missionsBtnActive = document.querySelector(`.${styles.missionsButton}`)
                const missionsBtn = missionsBtnActive.classList.contains(styles.missionsActive)
                // add/remove class from mission button
                missionsBtnActive.classList[missionsBtn ? "remove" : "add"](styles.missionsActive)

                // Click funtion
                ///////////////////////////
                setShowMissions(!missionsBtn)

                return
            }

        })

        // adding new button to map controll
        map.addControl(new customControl())
    }, [map])

    useEffect(() => {
        if (!map) return
        if (!showMissions) return

        getMissions()

        const getMissionsThrottled = _.throttle(() => {
            getMissions()
        }, 1000) // 1000ms 

        const onMoveEnd = () => {
            getMissionsThrottled()
        }

        const interval = setInterval(() => {
            getMissions()
        }, 60000) // 60000ms = 1 minute

        map.on('moveend', onMoveEnd)
        return () => {
            map.off('moveend', onMoveEnd)
            getMissionsThrottled.cancel()
            clearInterval(interval)
        }

    }, [showMissions, missionId, userId, searchValue, mapSliderValue, refresh])

    return (
        map && showMissions && missions && (
            <>
                <LayerGroup
                    attribution="Civilio missions"
                    color="red"
                    key={"lg-missions-" + groupId}
                >
                    {missions && missions.map((mission, index) => {
                        mission.feature.type = "Feature"
                        mission.feature.properties = {
                            id: mission._id,
                            name: mission.name,
                            layer: mission.layerId.name,
                            _featureIdInProperty: "",
                            _featureNameInProperty: "name",
                            _searchLayerName: "Mission",
                            _searchLayerIcon: '<i class="bi bi-lightning-fill"></i>',
                            _searchByName: mission.name
                        }

                        return (
                            <GeoJSON
                                key={"lg-mission-" + groupId + "-fg-feature-" + mission._id}
                                data={mission.feature}
                                weight={2}
                                opacity={1}
                                color={"rgb(0, 123, 180)"}
                                //dashArray={3}
                                fillOpacity={0.3}
                                fillColor={"rgb(0, 123, 180)"}
                                eventHandlers={{
                                    click: (geoJ) => {
                                        handleFeatureClick(geoJ)
                                    },
                                    mouseover: (geoJ) => {
                                        handleFeatureMouseOver(geoJ)
                                    },
                                    mouseout: (geoJ) => {
                                        handleFeatureMouseOut(geoJ)
                                    },
                                    add: (geoJ) => {
                                    },
                                    remove: (geoJ) => {
                                    }
                                }}
                                pointToLayer={function (geoJsonPoint, latlng) {
                                    return L.marker(latlng, { icon: markerIcon("rgb(0, 123, 180)") })
                                }}
                            >
                                <Tooltip direction="top" offset={[0, 0]} opacity={1} sticky={true} >
                                    {mission.name}
                                </Tooltip>
                                <Popup
                                    key={"lg-mission-" + groupId + "-pop-" + mission._id}
                                    maxWidth={500}
                                >

                                    <p className="d-flex justify-content-end">
                                        <span className="me-auto">
                                            <i className="bi bi-lightning-fill"></i> <FormattedMessage id="missionsLayerName" defaultMessage="Missions" />
                                        </span>
                                        {index + 1}/{missions.length}
                                    </p>

                                    <div className="text-primary">
                                        <hr />
                                    </div>

                                    <p className="h6">{mission.name}</p>

                                    <p className="text-end"><small>{moment(mission.createdAt).fromNow()}</small></p>

                                    <p><i className="bi bi-layers-half"></i> {mission.layer.name}</p>

                                    <Table>
                                        <thead>
                                            <tr className="table-secondary">
                                                <th scope="col"><i className="bi bi-hash"></i></th>
                                                <th className='text-end'><i className="bi bi-people-fill"></i> <FormattedMessage id="missionsInspectors" defaultMessage="Inspectors" /></th>
                                                <th className='text-end'><i className="bi bi-clipboard2-fill"></i> <FormattedMessage id="missionsTasks" defaultMessage="Tasks" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">{index + 1}</th>
                                                <td className='text-end'>{mission.users.length}</td>
                                                <td className='text-end'>{mission.features.length}</td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                                        <Button type="button" className="btn btn-sm btn-light btn-outline-primary" onClick={() => handleEditBtn(mission)}><i className="bi bi-pencil-square"></i> <FormattedMessage id="missionsEdit" defaultMessage="Edit mission" /></Button>
                                        <Button type="button" className="btn btn-sm btn-light btn-outline-danger" onClick={() => handleDeleteBtn(mission)}><i className="bi bi-trash"></i> <FormattedMessage id="missionsDelete" defaultMessage="Delete mission?" /></Button>
                                    </div>

                                    <div className="text-primary">
                                        <hr />
                                    </div>

                                </Popup>
                            </GeoJSON>
                        )
                    })}
                </LayerGroup>

                {modalTrigger && (
                    <MissionModal show={modalTrigger} modalType={modalType} mission={missionSelected} callBack={handleModalCallBack} />
                )}
            </>
        )
    )
}

export default MissionsButton
