import React, { useState, useEffect } from "react"
import { FormattedMessage } from 'react-intl'
import { Link } from "react-router-dom"

import EventsAccordion from "./EventsAccordion";
import "./accordion.css"

const Sidebar = ({ state, update, updateFunction }) => {

  const eventReports = false

  const { currentUser, showModeratorBoard, showAdminBoard } = state

  useEffect(() => {
    if (!update) return
  }, [update])

  return (
    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
      <div className="position-sticky pt-3 sidebar-sticky">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link to={"/home"} className="nav-link active">
              <i className="bi bi-map-fill"></i> <FormattedMessage id="navDashboard" defaultMessage="Dashboard" />
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/entities"} className="nav-link">
              <i className="bi bi-briefcase-fill"></i> <FormattedMessage id="navEntities" defaultMessage="Entities" />
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/users"} className="nav-link">
              <i className="bi bi-people-fill"></i> <FormattedMessage id="navUsers" defaultMessage="Users" />
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/layers"} className="nav-link">
              <i className="bi bi-layers-fill"></i> <FormattedMessage id="navMapLayers" defaultMessage="Map layers" />
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/forms"} className="nav-link">
              <i className="bi bi-file-code-fill"></i> <FormattedMessage id="navForms" defaultMessage="Forms" />
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/events"} className="nav-link">
              <i className="bi bi-calendar-event-fill"></i> <FormattedMessage id="navEvents" defaultMessage="Events" />
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/groups"} className="nav-link">
              <i className="bi bi-diagram-3-fill"></i> <FormattedMessage id="navGroups" defaultMessage="Groups" />
            </Link>
          </li>
        </ul>

        <EventsAccordion state={state} update={update} updateFunction={updateFunction} />

        {eventReports &&
          <>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
              <span><i className="bi bi-clipboard-data-fill"></i> Events reports</span>
              <a className="link-secondary" href="#" aria-label="Add a new report">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle align-text-bottom" aria-hidden="true"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
              </a>
            </h6>
            <ul className="nav flex-column mb-2">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-file-text align-text-bottom" aria-hidden="true"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                  Current month
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-file-text align-text-bottom" aria-hidden="true"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                  Last quarter
                </a>
              </li>
            </ul>
          </>
        }

        <div className="text-center">
          &copy; {new Date().getFullYear()} Civilio Information Systems
        </div>

      </div>
    </nav>
  )
}

export default Sidebar;