import React from 'react'
import { Card, Nav } from 'react-bootstrap'

const MessageCard = ({ sender, content, recipients, date, halign }) => {
    const allReaded = recipients.users.every(user => user.readed === true)

    const formatContent = (content) => {
        const regex = /\b(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?\b/g
        const replacedContent = content.replace(
            regex,
            (match) => {
                if (!match.startsWith('http://') && !match.startsWith('https://')) {
                    return `<a href="http://${match}" target="_blank" rel="noopener noreferrer">${match}</a>`
                }
                return `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`
            }
        )
        return replacedContent
    }

    return (
        <Card border="light" style={{ width: 'auto' }} className={halign}>
            <Card.Header>{sender?.socketId ? <span className='text-success'><i className="bi bi-wifi"></i></span> : <span className='text-danger'><i className="bi bi-wifi-off"></i></span>} {sender?.name} <small><Nav.Link href={"mailto:" + sender?.email} className="text-secondary">{sender?.email}</Nav.Link></small></Card.Header>
            <Card.Body>
                <Card.Text dangerouslySetInnerHTML={{ __html: formatContent(content) }} />
            </Card.Body>
            <Card.Footer className='bg-light d-flex justify-content-between'>
                <small>{date}</small>
                {!allReaded ? <i className="bi bi-check text-primary"></i> : <i className="bi bi-check-all text-success"></i>}
            </Card.Footer>
        </Card>
    )
}

export default MessageCard