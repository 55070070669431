import React, { useEffect, useState } from 'react'
import { useMap } from "react-leaflet"
import { GeoJSON, Popup, FeatureGroup } from 'react-leaflet'
import L from "leaflet"

import Button from 'react-bootstrap/Button'

import CenterButton from "components/map/controls/CenterButton"

import { markerIcon } from "helpers/Markers"

const MapFeature = ({ task }) => {

    const map = useMap()

    const [feature, setFeature] = useState(false)
    const [fillColor, setFillColor] = useState(false)
    const [center, setCenter] = useState(false)
    const [zoom, setZoom] = useState(false)

    const handleFeatureMouseOver = (geoJ, featureColor) => {
        //if (!taskGeoLayerRef.current) return

        if (geoJ.layer.feature.geometry.type !== "Point") {
            geoJ.layer.setStyle({ color: "black", fillColor: featureColor, fillOpacity: 0.5 })
        } else {
            geoJ.layer.getElement().querySelector('path').style.fill = featureColor
        }
    }

    const handleFeatureMouseOut = (geoJ, featureColor) => {
        //if (!taskGeoLayerRef.current) return

        if (geoJ.layer.feature.geometry.type !== "Point") {
            geoJ.layer.setStyle({ color: 'black', fillColor: featureColor, fillOpacity: 0.5 })
        } else {
            geoJ.layer.getElement().querySelector('path').style.fill = featureColor
        }
    }

    useEffect(() => {
        if (!map) return

        setFeature(task.feature)
        setFillColor(task.resultProperties.fillcolor)
    }, [map])

    return (
        feature && (
            <>
                <FeatureGroup color="red" >
                    <Popup>
                        <h6><small><i className="bi bi-hash"></i> {task.feature.ref}</small></h6>
                        <h6>{task.feature.name}</h6>
                        {center && zoom &&
                            <p className="text-center">
                                <Button variant="outline-primary" type="button" href={'https://www.google.com/maps/search/?api=1&query=' + center.lat + ',' + center.lng + '&zoom=' + zoom} target={'_featureGoogleMaps'} size="sm">
                                    <i className="bi bi-globe-europe-africa"></i> Open Google Maps
                                </Button>
                            </p>
                        }
                        {task.feature.properties && task.feature.properties['_message_'] &&
                            <p><i className="bi bi-exclamation-triangle-fill"></i> {task.feature.properties['_message_']}</p>
                        }
                        {task.feature.properties && task.feature.properties['_document_'] &&
                            <p className="text-center">
                                <Button variant="outline-primary" type="button" href={task.feature.properties['_document_']} target={'_documentPDF'} size="sm">
                                    <i className="bi bi-file-earmark-richtext"></i> Documents
                                </Button>
                            </p>
                        }
                    </Popup>
                    <GeoJSON
                        data={feature}
                        weight={2}
                        opacity={1}
                        color={"black"}
                        //dashArray="3"
                        fillOpacity={0.8}
                        fillColor={fillColor}
                        eventHandlers={{
                            add: (geoJ) => {
                                const bounds = geoJ.target.getBounds()
                                const center = bounds.getCenter()
                                const zoom = map.getBoundsZoom(bounds)
                                setCenter(center)
                                setZoom(zoom)
                                map.fitBounds(geoJ.target.getBounds())
                            },
                            remove: (geoJ) => {
                            },
                            mouseover: (geoJ) => {
                                handleFeatureMouseOver(geoJ, 'black')
                            },
                            mouseout: (geoJ) => {
                                handleFeatureMouseOut(geoJ, task.resultProperties.fillcolor)
                            }
                        }}
                        pointToLayer={function (geoJsonPoint, latlng) {
                            return L.marker(latlng, { icon: markerIcon(fillColor) })
                        }}
                    />
                </FeatureGroup >
                {center && zoom &&
                    <CenterButton coordinates={center} zoom={zoom} />
                }
            </>
        )
    )

}

export default MapFeature