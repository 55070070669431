import React, { useState, useEffect, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Button, Modal, Form } from 'react-bootstrap'

import MessagesCard from 'components/card/MessagesCard'

const ChatModal = ({ chatRooms, authUserId, chatRoomServices, sendButtonDisabled, callback }) => {
    const intl = useIntl()

    const chatRoom = chatRooms.selected.chatRoom
    const messages = chatRooms.selected.messages
    const messagesUnread = chatRooms.selected.unreaded

    const messagesContainerRef = useRef(null)
    const sendButtonRef = useRef(null)
    const chatRoomsRef = useRef(chatRooms)
    const [newMessageContent, setNewMessageContent] = useState('')


    const [show, setShow] = useState(false)
    const [fullscreen, setFullscreen] = useState(true)

    const handleShow = () => setShow(true)
    const handleClose = () => {
        setShow(false)
        callback(false)
    }

    const handleInputChange = (e) => setNewMessageContent(e.target.value)

    const handleSendNewMessage = (chatRoomId, participants) => {
        if (newMessageContent.trim().length > 0) {
            chatRoomServices.addChatRoomMessage(chatRoomId, participants, newMessageContent)
        }
        setNewMessageContent('')
    }

    useEffect(() => {
        handleShow()
    }, [])

    useEffect(() => {
    }, [sendButtonDisabled])

    useEffect(() => {
        // Scroll hacia abajo cuando cambien los mensajes
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight
        }
    }, [messages])

    return (
        show && (
            <Modal show={show} fullscreen={fullscreen} onHide={handleClose} >
                <Modal.Header closeButton={false} className={'p-2 bg-light'}>
                    <Button size={'sm'} onClick={handleClose} variant='light'><i className="bi bi-arrow-left h4"></i></Button>
                    <Modal.Title className="h6 d-flex justify-content-between align-items-center">
                        <i className="bi bi-chat-fill"></i>&nbsp;{chatRoom.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0' ref={messagesContainerRef}>
                    <MessagesCard messages={messages} rightUserId={authUserId} chatRoomsRef={chatRoomsRef} />
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center align-items-center p-0 bg-light">
                    {chatRoom && chatRoom._id &&
                        <>
                            <div className="d-flex justify-content-center align-items-center p-0 w-100">
                                <Form.Control as="textarea" rows={3} placeholder={intl.formatMessage({ id: 'chatroomWrite', defaultMessage: 'Write your message here...' })} onChange={handleInputChange} value={newMessageContent} />&nbsp;
                                <Button ref={sendButtonRef} variant="secondary" size='lg' onClick={() => { handleSendNewMessage(chatRoom._id, chatRoom.participants) }} disabled={sendButtonDisabled}><i className="bi bi-send"></i> <FormattedMessage id="textSend" defaultMessage="Send" /></Button>
                            </div>
                        </>
                    }
                </Modal.Footer>
            </Modal >
        )
    )
}

export default ChatModal