export const isEmptyField = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                <i className="bi bi-exclamation-triangle-fill"></i> This field is required!
            </div>
        )
    }
}

export const isNoValidEmail = value => {
    // Email Regular Expression
    const reEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

    if (value.match(reEmail) === null) {
        return (
            <div className="alert alert-danger" role="alert">
                <i className="bi bi-exclamation-triangle-fill"></i> Valid Email is required!
            </div>
        )
    }
}


export const colorValidator = (colorString) => {
    if (colorString.indexOf('#') === 0) {
        if (colorString.length === 7) {  // #f0f0f0
            return true
        } else if (colorString.length === 4) {  // #fff
            return true
        }
    }
    return false
}

export const isUrlValid = (url) => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
    return regex.test(url)
}

export const isValidEmail = (value) => {
    // Email Regular Expression
    const regex = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)
    return regex.test(value)
}

export const passwordsMatch = (password1, password2) => {
    if (password1 !== password2) {
        return false
    }
    return true
}
